/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { ImageType } from './ImageType';
import { ImageTypeFromJSON, ImageTypeFromJSONTyped, ImageTypeToJSON, } from './ImageType';
import type { TagCreator } from './TagCreator';
import { TagCreatorFromJSON, TagCreatorFromJSONTyped, TagCreatorToJSON, } from './TagCreator';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface InitialTagCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_InitialTagCreateRequest_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_InitialTagCreateRequest_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface InitialTagCreateRequest {
    /**
     * The name of the tag
     * #type string
     * #memberof InitialTagCreateRequest
        
        
    * @pattern_InitialTagCreateRequest_name
    * @minLength 3
         */
    name?: string;
    /**
     *
     * #type TagCreator
     * #memberof InitialTagCreateRequest
        
        
         */
    creator?: TagCreator;
    /**
     *
     * #type ImageType
     * #memberof InitialTagCreateRequest
        
        
         */
    imgType: ImageType;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof InitialTagCreateRequest
        
        
    * @pattern_InitialTagCreateRequest_runId
         */
    runId?: string;
}
/**
 * Check if a given object implements the InitialTagCreateRequest interface.
 */
export function instanceOfInitialTagCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imgType" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { ImageTypeValidator, ImageTypeStringifyShape, ImageTypeStringifyShapeProperties, } from './ImageType';
import { TagCreatorValidator, TagCreatorStringifyShape, TagCreatorStringifyShapeProperties, } from './TagCreator';
export const InitialTagCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): InitialTagCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is InitialTagCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "creator", "imgType", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is InitialTagCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_InitialTagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                    value: input.imgType
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_InitialTagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "creator", "imgType", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const InitialTagCreateRequestTypiaAssertStringify = (input: InitialTagCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): InitialTagCreateRequest => {
        const __is = (input: any): input is InitialTagCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => (undefined === input.name || "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is InitialTagCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.name || "string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_InitialTagCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_InitialTagCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || "SAMPLER_ACTIVE_LEARNING" === input.creator || "SAMPLER_CORAL" === input.creator || "SAMPLER_CORESET" === input.creator || "SAMPLER_RANDOM" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && ("full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                    value: input.imgType
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_InitialTagCreateRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_InitialTagCreateRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "InitialTagCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: InitialTagCreateRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.name ? "" : `"name":${undefined !== input.name ? $string(input.name) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"imgType":${(() => {
            if ("string" === typeof input.imgType)
                return $string(input.imgType);
            if ("string" === typeof input.imgType)
                return "\"" + input.imgType + "\"";
            $throws({
                expected: "(\"full\" | \"meta\" | \"thumbnail\")",
                value: input.imgType
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const InitialTagCreateRequestValidatorShape = {
    'name': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3),
    'creator': TagCreatorValidator.optional().default(undefined),
    'imgType': ImageTypeValidator.defined(),
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const InitialTagCreateRequestValidator = yup.object().shape(InitialTagCreateRequestValidatorShape);
export const InitialTagCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'creator': TagCreatorStringifyShape,
    'imgType': ImageTypeStringifyShape,
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const InitialTagCreateRequestStringifyShape: ObjectSchema = {
    title: 'InitialTagCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...InitialTagCreateRequestStringifyShapeProperties
    },
    required: [
        'imgType',
    ],
};
export const InitialTagCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(InitialTagCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const InitialTagCreateRequestStringify: (data: InitialTagCreateRequest) => string = InitialTagCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function InitialTagCreateRequestFromJSON(json: any): InitialTagCreateRequest {
    return InitialTagCreateRequestFromJSONTyped(json, false);
}
export function InitialTagCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitialTagCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'creator': !exists(json, 'creator') ? undefined : TagCreatorFromJSON(json['creator']),
        'imgType': ImageTypeFromJSON(json['imgType']),
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
    };
}
export function InitialTagCreateRequestToJSON(value?: InitialTagCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'creator': TagCreatorToJSON(value.creator),
        'imgType': ImageTypeToJSON(value.imgType),
        'runId': value.runId,
    };
}
