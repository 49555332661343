/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceConfigBase } from './DatasourceConfigBase';
import { DatasourceConfigBaseFromJSON, DatasourceConfigBaseFromJSONTyped, DatasourceConfigBaseToJSON, } from './DatasourceConfigBase';
import type { DatasourcePurpose } from './DatasourcePurpose';
import { DatasourcePurposeFromJSON, DatasourcePurposeFromJSONTyped, DatasourcePurposeToJSON, } from './DatasourcePurpose';
/*
causes circular dependendies
// .concat(DatasourceConfigBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigOBS
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_DatasourceConfigOBS_obsEndpoint")("string")(() => {
    const regex = new RegExp(/^https?:\/\/.+$/);
    return (value: string) => !!regex.exec(value);
});
export interface DatasourceConfigOBS extends DatasourceConfigBase {
    /**
     * path includes the bucket name and the path within the bucket where you have stored your information
     * #type string
     * #memberof DatasourceConfigOBS
        
        
         */
    fullPath: string;
    /**
     * The Object Storage Service (OBS) endpoint to use of your S3 compatible cloud storage provider
     * #type string
     * #memberof DatasourceConfigOBS
        
        
    * @pattern_DatasourceConfigOBS_obsEndpoint
    * @minLength 1
         */
    obsEndpoint: string;
    /**
     * The Access Key Id of the credential you are providing Lightly to use
     * #type string
     * #memberof DatasourceConfigOBS
        
        
    * @minLength 1
         */
    obsAccessKeyId: string;
    /**
     * The Secret Access Key of the credential you are providing Lightly to use
     * #type string
     * #memberof DatasourceConfigOBS
        
        
    * @minLength 1
         */
    obsSecretAccessKey: string;
}
/**
 * Check if a given object implements the DatasourceConfigOBS interface.
 */
export function instanceOfDatasourceConfigOBS(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullPath" in value;
    isInstance = isInstance && "obsEndpoint" in value;
    isInstance = isInstance && "obsAccessKeyId" in value;
    isInstance = isInstance && "obsSecretAccessKey" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceConfigBaseValidator, DatasourceConfigBaseStringifyShape, DatasourceConfigBaseStringifyShapeProperties, } from './DatasourceConfigBase';
import { DatasourcePurposeValidator, DatasourcePurposeStringifyShape, DatasourcePurposeStringifyShapeProperties, } from './DatasourcePurpose';
export const DatasourceConfigOBSTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigOBS => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigOBS => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fullPath && ("string" === typeof input.obsEndpoint && 1 <= input.obsEndpoint.length && $is_custom("pattern_DatasourceConfigOBS_obsEndpoint", "string", "", input.obsEndpoint)) && ("string" === typeof input.obsAccessKeyId && 1 <= input.obsAccessKeyId.length) && ("string" === typeof input.obsSecretAccessKey && 1 <= input.obsSecretAccessKey.length) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fullPath", "obsEndpoint", "obsAccessKeyId", "obsSecretAccessKey", "id", "purpose", "type", "thumbSuffix"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigOBS => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && ("string" === typeof input.obsEndpoint && (1 <= input.obsEndpoint.length || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string (@minLength 1)",
                    value: input.obsEndpoint
                })) && ($is_custom("pattern_DatasourceConfigOBS_obsEndpoint", "string", "", input.obsEndpoint) || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string (@pattern_DatasourceConfigOBS_obsEndpoint)",
                    value: input.obsEndpoint
                })) || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string",
                    value: input.obsEndpoint
                })) && ("string" === typeof input.obsAccessKeyId && (1 <= input.obsAccessKeyId.length || $guard(_exceptionable, {
                    path: _path + ".obsAccessKeyId",
                    expected: "string (@minLength 1)",
                    value: input.obsAccessKeyId
                })) || $guard(_exceptionable, {
                    path: _path + ".obsAccessKeyId",
                    expected: "string",
                    value: input.obsAccessKeyId
                })) && ("string" === typeof input.obsSecretAccessKey && (1 <= input.obsSecretAccessKey.length || $guard(_exceptionable, {
                    path: _path + ".obsSecretAccessKey",
                    expected: "string (@minLength 1)",
                    value: input.obsSecretAccessKey
                })) || $guard(_exceptionable, {
                    path: _path + ".obsSecretAccessKey",
                    expected: "string",
                    value: input.obsSecretAccessKey
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_DatasourceConfigBase_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fullPath", "obsEndpoint", "obsAccessKeyId", "obsSecretAccessKey", "id", "purpose", "type", "thumbSuffix"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigOBS",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigOBS",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigOBSTypiaAssertStringify = (input: DatasourceConfigOBS): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigOBS => {
        const __is = (input: any): input is DatasourceConfigOBS => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fullPath && ("string" === typeof input.obsEndpoint && 1 <= input.obsEndpoint.length && $is_custom("pattern_DatasourceConfigOBS_obsEndpoint", "string", "", input.obsEndpoint)) && ("string" === typeof input.obsAccessKeyId && 1 <= input.obsAccessKeyId.length) && ("string" === typeof input.obsSecretAccessKey && 1 <= input.obsSecretAccessKey.length) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigOBS => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && ("string" === typeof input.obsEndpoint && (1 <= input.obsEndpoint.length || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string (@minLength 1)",
                    value: input.obsEndpoint
                })) && ($is_custom("pattern_DatasourceConfigOBS_obsEndpoint", "string", "", input.obsEndpoint) || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string (@pattern_DatasourceConfigOBS_obsEndpoint)",
                    value: input.obsEndpoint
                })) || $guard(_exceptionable, {
                    path: _path + ".obsEndpoint",
                    expected: "string",
                    value: input.obsEndpoint
                })) && ("string" === typeof input.obsAccessKeyId && (1 <= input.obsAccessKeyId.length || $guard(_exceptionable, {
                    path: _path + ".obsAccessKeyId",
                    expected: "string (@minLength 1)",
                    value: input.obsAccessKeyId
                })) || $guard(_exceptionable, {
                    path: _path + ".obsAccessKeyId",
                    expected: "string",
                    value: input.obsAccessKeyId
                })) && ("string" === typeof input.obsSecretAccessKey && (1 <= input.obsSecretAccessKey.length || $guard(_exceptionable, {
                    path: _path + ".obsSecretAccessKey",
                    expected: "string (@minLength 1)",
                    value: input.obsSecretAccessKey
                })) || $guard(_exceptionable, {
                    path: _path + ".obsSecretAccessKey",
                    expected: "string",
                    value: input.obsSecretAccessKey
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_DatasourceConfigBase_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigOBS",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigOBS",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigOBS): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.id ? "" : `"id":${undefined !== input.id ? $string(input.id) : undefined},`}${undefined === input.thumbSuffix ? "" : `"thumbSuffix":${undefined !== input.thumbSuffix ? $string(input.thumbSuffix) : undefined},`}"fullPath":${$string(input.fullPath)},"obsEndpoint":${$string(input.obsEndpoint)},"obsAccessKeyId":${$string(input.obsAccessKeyId)},"obsSecretAccessKey":${$string(input.obsSecretAccessKey)},"purpose":${(() => {
            if ("string" === typeof input.purpose)
                return $string(input.purpose);
            if ("string" === typeof input.purpose)
                return "\"" + input.purpose + "\"";
            $throws({
                expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                value: input.purpose
            });
        })()},"type":${$string(input.type)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigOBSValidatorShape = {
    'fullPath': yup.string().defined().test('is-valid', 'the path needs to be set', (value) => {
        if (!value) {
            return false;
        }
        return true;
    })
        .test('is-valid', "It's not allowed to use '.lightly' in your path as its a reserved word used internally", (value) => {
        if (!value) {
            return true;
        }
        if (value.startsWith('.lightly/') ||
            value.endsWith('/.lightly') ||
            value.indexOf('/.lightly/') !== -1) {
            return false;
        }
        return true;
    })
        .test('is-valid', 'The path should not directly point to the location of the .lightly folder itself but to the .lightly folders parent', (value) => {
        if (!value) {
            return true;
        }
        if (value.endsWith('/.lightly/') || value.endsWith('/.lightly')) {
            return false;
        }
        return true;
    }),
    'obsEndpoint': yup.string().matches(/^https?:\/\/.+$/).min(1).defined(),
    'obsAccessKeyId': yup.string().min(1).defined(),
    'obsSecretAccessKey': yup.string().min(1).defined(),
};
export const DatasourceConfigOBSValidator = yup.object().shape(DatasourceConfigOBSValidatorShape).concat(DatasourceConfigBaseValidator);
export const DatasourceConfigOBSStringifyShapeProperties: ObjectSchema['properties'] = {
    'fullPath': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'obsEndpoint': {
        'type': 'string',
        'pattern': "/^https?:\/\/.+$/".slice(1, -1),
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'obsAccessKeyId': {
        'type': 'string',
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'obsSecretAccessKey': {
        'type': 'string',
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
};
export const DatasourceConfigOBSStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigOBS Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigBaseStringifyShapeProperties,
        ...DatasourceConfigOBSStringifyShapeProperties
    },
    required: [
        ...(DatasourceConfigBaseStringifyShape.required ? DatasourceConfigBaseStringifyShape.required : []),
        'fullPath',
        'obsEndpoint',
        'obsAccessKeyId',
        'obsSecretAccessKey',
    ],
};
export const DatasourceConfigOBSStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigOBSStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigOBSStringify: (data: DatasourceConfigOBS) => string = DatasourceConfigOBSStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigOBSFromJSON(json: any): DatasourceConfigOBS {
    return DatasourceConfigOBSFromJSONTyped(json, false);
}
export function DatasourceConfigOBSFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigOBS {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...DatasourceConfigBaseFromJSONTyped(json, ignoreDiscriminator),
        'fullPath': json['fullPath'],
        'obsEndpoint': json['obsEndpoint'],
        'obsAccessKeyId': json['obsAccessKeyId'],
        'obsSecretAccessKey': json['obsSecretAccessKey'],
    };
}
export function DatasourceConfigOBSToJSON(value?: DatasourceConfigOBS | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DatasourceConfigBaseToJSON(value),
        'fullPath': value.fullPath,
        'obsEndpoint': value.obsEndpoint,
        'obsAccessKeyId': value.obsAccessKeyId,
        'obsSecretAccessKey': value.obsSecretAccessKey,
    };
}
