// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ActiveLearningScoreCreateRequest, ActiveLearningScoreData, ActiveLearningScoreTypesV2Data, ActiveLearningScoreV2Data, ApiErrorResponse, CreateEntityResponse, TagActiveLearningScoresData, } from '../models';
import { ActiveLearningScoreCreateRequestFromJSON, ActiveLearningScoreCreateRequestToJSON, ActiveLearningScoreCreateRequestValidator, ActiveLearningScoreDataFromJSON, ActiveLearningScoreDataToJSON, ActiveLearningScoreDataValidator, ActiveLearningScoreTypesV2DataFromJSON, ActiveLearningScoreTypesV2DataToJSON, ActiveLearningScoreTypesV2DataValidator, ActiveLearningScoreV2DataFromJSON, ActiveLearningScoreV2DataToJSON, ActiveLearningScoreV2DataValidator, ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, TagActiveLearningScoresDataFromJSON, TagActiveLearningScoresDataToJSON, TagActiveLearningScoresDataValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateOrUpdateActiveLearningScoreByTagIdRequest {
    activeLearningScoreCreateRequest: models.ActiveLearningScoreCreateRequest;
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateOrUpdateActiveLearningScoreByTagIdRequestValidatorShape = {
    'activeLearningScoreCreateRequest': models.ActiveLearningScoreCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdateActiveLearningScoreByTagIdRequestValidator = yup.object().shape(CreateOrUpdateActiveLearningScoreByTagIdRequestValidatorShape);
export interface CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequest {
    activeLearningScoreCreateRequest: models.ActiveLearningScoreCreateRequest;
    datasetId: string;
    taskName: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestValidatorShape = {
    'activeLearningScoreCreateRequest': models.ActiveLearningScoreCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestValidator = yup.object().shape(CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestValidatorShape);
export interface GetActiveLearningScoreByScoreIdRequest {
    datasetId: string;
    tagId: string;
    scoreId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetActiveLearningScoreByScoreIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'scoreId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningScoreByScoreIdRequestValidator = yup.object().shape(GetActiveLearningScoreByScoreIdRequestValidatorShape);
export interface GetActiveLearningScoresByTagIdRequest {
    datasetId: string;
    tagId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetActiveLearningScoresByTagIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningScoresByTagIdRequestValidator = yup.object().shape(GetActiveLearningScoresByTagIdRequestValidatorShape);
export interface GetActiveLearningV2ScoreByDatasetAndScoreIdRequest {
    datasetId: string;
    scoreId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'scoreId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestValidator = yup.object().shape(GetActiveLearningV2ScoreByDatasetAndScoreIdRequestValidatorShape);
export interface GetActiveLearningV2ScoresByDatasetIdRequest {
    datasetId: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetActiveLearningV2ScoresByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetActiveLearningV2ScoresByDatasetIdRequestValidator = yup.object().shape(GetActiveLearningV2ScoresByDatasetIdRequestValidatorShape);
export interface CreateOrUpdateActiveLearningScoreByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function CreateOrUpdateActiveLearningScoreByTagIdRequestParamsSanitizer(json: any): CreateOrUpdateActiveLearningScoreByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const CreateOrUpdateActiveLearningScoreByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdateActiveLearningScoreByTagIdRequestParamsValidator = yup.object().shape(CreateOrUpdateActiveLearningScoreByTagIdRequestParamsValidatorShape);
export interface CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsSanitizer(json: any): CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsValidator = yup.object().shape(CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsValidatorShape);
export interface GetActiveLearningScoreByScoreIdRequestParams {
    datasetId: string;
    tagId: string;
    scoreId: string;
}
export function GetActiveLearningScoreByScoreIdRequestParamsSanitizer(json: any): GetActiveLearningScoreByScoreIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
        'scoreId': json['scoreId'],
    };
}
export const GetActiveLearningScoreByScoreIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'scoreId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningScoreByScoreIdRequestParamsValidator = yup.object().shape(GetActiveLearningScoreByScoreIdRequestParamsValidatorShape);
export interface GetActiveLearningScoresByTagIdRequestParams {
    datasetId: string;
    tagId: string;
}
export function GetActiveLearningScoresByTagIdRequestParamsSanitizer(json: any): GetActiveLearningScoresByTagIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'tagId': json['tagId'],
    };
}
export const GetActiveLearningScoresByTagIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'tagId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningScoresByTagIdRequestParamsValidator = yup.object().shape(GetActiveLearningScoresByTagIdRequestParamsValidatorShape);
export interface GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParams {
    datasetId: string;
    scoreId: string;
}
export function GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsSanitizer(json: any): GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'scoreId': json['scoreId'],
    };
}
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'scoreId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsValidator = yup.object().shape(GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsValidatorShape);
export interface GetActiveLearningV2ScoresByDatasetIdRequestParams {
    datasetId: string;
}
export function GetActiveLearningV2ScoresByDatasetIdRequestParamsSanitizer(json: any): GetActiveLearningV2ScoresByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetActiveLearningV2ScoresByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetActiveLearningV2ScoresByDatasetIdRequestParamsValidator = yup.object().shape(GetActiveLearningV2ScoresByDatasetIdRequestParamsValidatorShape);
export interface CreateOrUpdateActiveLearningScoreByTagIdRequestQuery {
}
export function CreateOrUpdateActiveLearningScoreByTagIdRequestQuerySanitizer(json: any): CreateOrUpdateActiveLearningScoreByTagIdRequestQuery {
    return {};
}
export const CreateOrUpdateActiveLearningScoreByTagIdRequestQueryValidatorShape = {};
export const CreateOrUpdateActiveLearningScoreByTagIdRequestQueryValidator = yup.object().shape(CreateOrUpdateActiveLearningScoreByTagIdRequestQueryValidatorShape);
export interface CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQuery {
    taskName: string;
    predictionUUIDTimestamp?: number;
}
export function CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQuerySanitizer(json: any): CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQuery {
    return {
        'taskName': json['taskName'],
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQueryValidatorShape = {
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQueryValidator = yup.object().shape(CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQueryValidatorShape);
export interface GetActiveLearningScoreByScoreIdRequestQuery {
}
export function GetActiveLearningScoreByScoreIdRequestQuerySanitizer(json: any): GetActiveLearningScoreByScoreIdRequestQuery {
    return {};
}
export const GetActiveLearningScoreByScoreIdRequestQueryValidatorShape = {};
export const GetActiveLearningScoreByScoreIdRequestQueryValidator = yup.object().shape(GetActiveLearningScoreByScoreIdRequestQueryValidatorShape);
export interface GetActiveLearningScoresByTagIdRequestQuery {
}
export function GetActiveLearningScoresByTagIdRequestQuerySanitizer(json: any): GetActiveLearningScoresByTagIdRequestQuery {
    return {};
}
export const GetActiveLearningScoresByTagIdRequestQueryValidatorShape = {};
export const GetActiveLearningScoresByTagIdRequestQueryValidator = yup.object().shape(GetActiveLearningScoresByTagIdRequestQueryValidatorShape);
export interface GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQuery {
}
export function GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQuerySanitizer(json: any): GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQuery {
    return {};
}
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQueryValidatorShape = {};
export const GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQueryValidator = yup.object().shape(GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQueryValidatorShape);
export interface GetActiveLearningV2ScoresByDatasetIdRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function GetActiveLearningV2ScoresByDatasetIdRequestQuerySanitizer(json: any): GetActiveLearningV2ScoresByDatasetIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const GetActiveLearningV2ScoresByDatasetIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetActiveLearningV2ScoresByDatasetIdRequestQueryValidator = yup.object().shape(GetActiveLearningV2ScoresByDatasetIdRequestQueryValidatorShape);
export type CreateOrUpdateActiveLearningScoreByTagIdRequestBody = ActiveLearningScoreCreateRequest;
export const CreateOrUpdateActiveLearningScoreByTagIdRequestBodySanitizer = ActiveLearningScoreCreateRequestFromJSON;
export const CreateOrUpdateActiveLearningScoreByTagIdRequestBodyValidator = ActiveLearningScoreCreateRequestValidator;
export const CreateOrUpdateActiveLearningScoreByTagIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores, true && _exceptionable)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["scoreType", "scores"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_ActiveLearningScoreCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["scoreType", "scores"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateOrUpdateActiveLearningScoreByTagIdTypiaAssertStringify = (input: CreateOrUpdateActiveLearningScoreByTagIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
        const __is = (input: any): input is CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningScoreByTagIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_ActiveLearningScoreCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateOrUpdateActiveLearningScoreByTagIdRequestBody): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"scoreType":${$string(input.scoreType)},"scores":${$so1(input.scores)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody = ActiveLearningScoreCreateRequest;
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBodySanitizer = ActiveLearningScoreCreateRequestFromJSON;
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBodyValidator = ActiveLearningScoreCreateRequestValidator;
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores, true && _exceptionable)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["scoreType", "scores"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_ActiveLearningScoreCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["scoreType", "scores"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateOrUpdateActiveLearningV2ScoreByDatasetIdTypiaAssertStringify = (input: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) && ("object" === typeof input.scores && null !== input.scores && $io1(input.scores));
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_ActiveLearningScoreCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_ActiveLearningScoreCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string",
                    value: input.scoreType
                })) && (("object" === typeof input.scores && null !== input.scores || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                })) && $ao1(input.scores, _path + ".scores", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".scores",
                    expected: "ActiveLearningScores",
                    value: input.scores
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ActiveLearningScoreCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"scoreType":${$string(input.scoreType)},"scores":${$so1(input.scores)}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/tags/{tagId}/scores

export const createOrUpdateActiveLearningScoreByTagIdFN: RequestHandler<
    CreateOrUpdateActiveLearningScoreByTagIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateOrUpdateActiveLearningScoreByTagIdRequestBody,
    CreateOrUpdateActiveLearningScoreByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateOrUpdateActiveLearningScoreByTagIdRequestBodySanitizer, CreateOrUpdateActiveLearningScoreByTagIdRequestBodyValidator);
    const {
        
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, CreateOrUpdateActiveLearningScoreByTagIdRequestParamsSanitizer, CreateOrUpdateActiveLearningScoreByTagIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, CreateOrUpdateActiveLearningScoreByTagIdRequestQuerySanitizer, CreateOrUpdateActiveLearningScoreByTagIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/predictions/scores

export const createOrUpdateActiveLearningV2ScoreByDatasetIdFN: RequestHandler<
    CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBody,
    CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBodySanitizer, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
        
        
    } =  await validateAndSanitize(req.params, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsSanitizer, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestParamsValidator);
    const {
        
        
        taskName,
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQuerySanitizer, CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/scores/{scoreId}

export const getActiveLearningScoreByScoreIdFN: RequestHandler<
    GetActiveLearningScoreByScoreIdRequestParams,
    ActiveLearningScoreData | ApiErrorResponse,
    GetActiveLearningScoreByScoreIdRequestBody,
    GetActiveLearningScoreByScoreIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetActiveLearningScoreByScoreIdRequestBodySanitizer, GetActiveLearningScoreByScoreIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
        scoreId,
    } =  await validateAndSanitize(req.params, GetActiveLearningScoreByScoreIdRequestParamsSanitizer, GetActiveLearningScoreByScoreIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, GetActiveLearningScoreByScoreIdRequestQuerySanitizer, GetActiveLearningScoreByScoreIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ActiveLearningScoreDataStringify,
        validateAndRespond({ id, ...rest }, ActiveLearningScoreDataFromJSON, ActiveLearningScoreDataValidator),
    );
}



/v1/datasets/{datasetId}/tags/{tagId}/scores
const TagActiveLearningScoresDataArrayStringify = fastJSONArrayify(TagActiveLearningScoresDataStringifyShape);
export const getActiveLearningScoresByTagIdFN: RequestHandler<
    GetActiveLearningScoresByTagIdRequestParams,
    TagActiveLearningScoresData[] | ApiErrorResponse,
    GetActiveLearningScoresByTagIdRequestBody,
    GetActiveLearningScoresByTagIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetActiveLearningScoresByTagIdRequestBodySanitizer, GetActiveLearningScoresByTagIdRequestBodyValidator);
    const {
        datasetId,
        tagId,
    } =  await validateAndSanitize(req.params, GetActiveLearningScoresByTagIdRequestParamsSanitizer, GetActiveLearningScoresByTagIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetActiveLearningScoresByTagIdRequestQuerySanitizer, GetActiveLearningScoresByTagIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        TagActiveLearningScoresDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, TagActiveLearningScoresDataFromJSON, TagActiveLearningScoresDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/predictions/scores/{scoreId}

export const getActiveLearningV2ScoreByDatasetAndScoreIdFN: RequestHandler<
    GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParams,
    ActiveLearningScoreV2Data | ApiErrorResponse,
    GetActiveLearningV2ScoreByDatasetAndScoreIdRequestBody,
    GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestBodySanitizer, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestBodyValidator);
    const {
        datasetId,
        scoreId,
    } =  await validateAndSanitize(req.params, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsSanitizer, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQuerySanitizer, GetActiveLearningV2ScoreByDatasetAndScoreIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        ActiveLearningScoreV2DataStringify,
        validateAndRespond({ id, ...rest }, ActiveLearningScoreV2DataFromJSON, ActiveLearningScoreV2DataValidator),
    );
}



/v1/datasets/{datasetId}/predictions/scores
const ActiveLearningScoreTypesV2DataArrayStringify = fastJSONArrayify(ActiveLearningScoreTypesV2DataStringifyShape);
export const getActiveLearningV2ScoresByDatasetIdFN: RequestHandler<
    GetActiveLearningV2ScoresByDatasetIdRequestParams,
    ActiveLearningScoreTypesV2Data[] | ApiErrorResponse,
    GetActiveLearningV2ScoresByDatasetIdRequestBody,
    GetActiveLearningV2ScoresByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetActiveLearningV2ScoresByDatasetIdRequestBodySanitizer, GetActiveLearningV2ScoresByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
    } =  await validateAndSanitize(req.params, GetActiveLearningV2ScoresByDatasetIdRequestParamsSanitizer, GetActiveLearningV2ScoresByDatasetIdRequestParamsValidator);
    const {
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, GetActiveLearningV2ScoresByDatasetIdRequestQuerySanitizer, GetActiveLearningV2ScoresByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        ActiveLearningScoreTypesV2DataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, ActiveLearningScoreTypesV2DataFromJSON, ActiveLearningScoreTypesV2DataValidator);
        }),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * ScoresApi - interface
 *
 * @export
 * @interface ScoresApiInterface
 */
export interface ScoresApiInterface {
    /**
     * Create or update active learning score object by tag id
     * @param {ActiveLearningScoreCreateRequest} activeLearningScoreCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    createOrUpdateActiveLearningScoreByTagIdRaw(requestParameters: CreateOrUpdateActiveLearningScoreByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create or update active learning score object by tag id
     */
    createOrUpdateActiveLearningScoreByTagId(requestParameters: CreateOrUpdateActiveLearningScoreByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Create or update active learning score object for a dataset, taskName, predictionUUIDTimestamp
     * @param {ActiveLearningScoreCreateRequest} activeLearningScoreCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} taskName The prediction task name for which one wants to list the predictions
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    createOrUpdateActiveLearningV2ScoreByDatasetIdRaw(requestParameters: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create or update active learning score object for a dataset, taskName, predictionUUIDTimestamp
     */
    createOrUpdateActiveLearningV2ScoreByDatasetId(requestParameters: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Get active learning score object by id
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {string} scoreId ObjectId of the scores
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    getActiveLearningScoreByScoreIdRaw(requestParameters: GetActiveLearningScoreByScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveLearningScoreData>>;
    /**
     * Get active learning score object by id
     */
    getActiveLearningScoreByScoreId(requestParameters: GetActiveLearningScoreByScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveLearningScoreData>;
    /**
     * Get all scoreIds for the given tag
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} tagId ObjectId of the tag
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    getActiveLearningScoresByTagIdRaw(requestParameters: GetActiveLearningScoresByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagActiveLearningScoresData>>>;
    /**
     * Get all scoreIds for the given tag
     */
    getActiveLearningScoresByTagId(requestParameters: GetActiveLearningScoresByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagActiveLearningScoresData>>;
    /**
     * Get active learning scores by scoreId
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} scoreId ObjectId of the scores
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    getActiveLearningV2ScoreByDatasetAndScoreIdRaw(requestParameters: GetActiveLearningV2ScoreByDatasetAndScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveLearningScoreV2Data>>;
    /**
     * Get active learning scores by scoreId
     */
    getActiveLearningV2ScoreByDatasetAndScoreId(requestParameters: GetActiveLearningV2ScoreByDatasetAndScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveLearningScoreV2Data>;
    /**
     * Get all AL score types by datasetId and predictionUUIDTimestamp
     * @param {string} datasetId ObjectId of the dataset
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoresApiInterface
     */
    getActiveLearningV2ScoresByDatasetIdRaw(requestParameters: GetActiveLearningV2ScoresByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActiveLearningScoreTypesV2Data>>>;
    /**
     * Get all AL score types by datasetId and predictionUUIDTimestamp
     */
    getActiveLearningV2ScoresByDatasetId(requestParameters: GetActiveLearningV2ScoresByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActiveLearningScoreTypesV2Data>>;
}
/**
 *
 */
export class ScoresApi extends runtime.BaseAPI implements ScoresApiInterface {
    /**
     * Create or update active learning score object by tag id
     */
    async createOrUpdateActiveLearningScoreByTagIdRaw(requestParameters: CreateOrUpdateActiveLearningScoreByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.activeLearningScoreCreateRequest === null || requestParameters.activeLearningScoreCreateRequest === undefined) {
            throw new runtime.RequiredError('activeLearningScoreCreateRequest', 'Required parameter requestParameters.activeLearningScoreCreateRequest was null or undefined when calling createOrUpdateActiveLearningScoreByTagId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createOrUpdateActiveLearningScoreByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling createOrUpdateActiveLearningScoreByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/scores`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActiveLearningScoreCreateRequestToJSON(requestParameters.activeLearningScoreCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create or update active learning score object by tag id
     */
    async createOrUpdateActiveLearningScoreByTagId(requestParameters: CreateOrUpdateActiveLearningScoreByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createOrUpdateActiveLearningScoreByTagIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Create or update active learning score object for a dataset, taskName, predictionUUIDTimestamp
     */
    async createOrUpdateActiveLearningV2ScoreByDatasetIdRaw(requestParameters: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.activeLearningScoreCreateRequest === null || requestParameters.activeLearningScoreCreateRequest === undefined) {
            throw new runtime.RequiredError('activeLearningScoreCreateRequest', 'Required parameter requestParameters.activeLearningScoreCreateRequest was null or undefined when calling createOrUpdateActiveLearningV2ScoreByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createOrUpdateActiveLearningV2ScoreByDatasetId.');
        }
        if (requestParameters.taskName === null || requestParameters.taskName === undefined) {
            throw new runtime.RequiredError('taskName', 'Required parameter requestParameters.taskName was null or undefined when calling createOrUpdateActiveLearningV2ScoreByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.taskName !== undefined) {
            queryParameters['taskName'] = requestParameters.taskName;
        }
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/scores`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActiveLearningScoreCreateRequestToJSON(requestParameters.activeLearningScoreCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create or update active learning score object for a dataset, taskName, predictionUUIDTimestamp
     */
    async createOrUpdateActiveLearningV2ScoreByDatasetId(requestParameters: CreateOrUpdateActiveLearningV2ScoreByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createOrUpdateActiveLearningV2ScoreByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get active learning score object by id
     */
    async getActiveLearningScoreByScoreIdRaw(requestParameters: GetActiveLearningScoreByScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveLearningScoreData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getActiveLearningScoreByScoreId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling getActiveLearningScoreByScoreId.');
        }
        if (requestParameters.scoreId === null || requestParameters.scoreId === undefined) {
            throw new runtime.RequiredError('scoreId', 'Required parameter requestParameters.scoreId was null or undefined when calling getActiveLearningScoreByScoreId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/scores/{scoreId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))).replace(`{${"scoreId"}}`, encodeURIComponent(String(requestParameters.scoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveLearningScoreDataFromJSON(jsonValue));
    }
    /**
     * Get active learning score object by id
     */
    async getActiveLearningScoreByScoreId(requestParameters: GetActiveLearningScoreByScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveLearningScoreData> {
        const response = await this.getActiveLearningScoreByScoreIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all scoreIds for the given tag
     */
    async getActiveLearningScoresByTagIdRaw(requestParameters: GetActiveLearningScoresByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TagActiveLearningScoresData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getActiveLearningScoresByTagId.');
        }
        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId', 'Required parameter requestParameters.tagId was null or undefined when calling getActiveLearningScoresByTagId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/tags/{tagId}/scores`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagActiveLearningScoresDataFromJSON));
    }
    /**
     * Get all scoreIds for the given tag
     */
    async getActiveLearningScoresByTagId(requestParameters: GetActiveLearningScoresByTagIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TagActiveLearningScoresData>> {
        const response = await this.getActiveLearningScoresByTagIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get active learning scores by scoreId
     */
    async getActiveLearningV2ScoreByDatasetAndScoreIdRaw(requestParameters: GetActiveLearningV2ScoreByDatasetAndScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActiveLearningScoreV2Data>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getActiveLearningV2ScoreByDatasetAndScoreId.');
        }
        if (requestParameters.scoreId === null || requestParameters.scoreId === undefined) {
            throw new runtime.RequiredError('scoreId', 'Required parameter requestParameters.scoreId was null or undefined when calling getActiveLearningV2ScoreByDatasetAndScoreId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/scores/{scoreId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"scoreId"}}`, encodeURIComponent(String(requestParameters.scoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ActiveLearningScoreV2DataFromJSON(jsonValue));
    }
    /**
     * Get active learning scores by scoreId
     */
    async getActiveLearningV2ScoreByDatasetAndScoreId(requestParameters: GetActiveLearningV2ScoreByDatasetAndScoreIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActiveLearningScoreV2Data> {
        const response = await this.getActiveLearningV2ScoreByDatasetAndScoreIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all AL score types by datasetId and predictionUUIDTimestamp
     */
    async getActiveLearningV2ScoresByDatasetIdRaw(requestParameters: GetActiveLearningV2ScoresByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ActiveLearningScoreTypesV2Data>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getActiveLearningV2ScoresByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/scores`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActiveLearningScoreTypesV2DataFromJSON));
    }
    /**
     * Get all AL score types by datasetId and predictionUUIDTimestamp
     */
    async getActiveLearningV2ScoresByDatasetId(requestParameters: GetActiveLearningV2ScoresByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ActiveLearningScoreTypesV2Data>> {
        const response = await this.getActiveLearningV2ScoresByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
