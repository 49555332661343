/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasourceConfigBase } from './DatasourceConfigBase';
import { DatasourceConfigBaseFromJSON, DatasourceConfigBaseFromJSONTyped, DatasourceConfigBaseToJSON, } from './DatasourceConfigBase';
import type { DatasourcePurpose } from './DatasourcePurpose';
import { DatasourcePurposeFromJSON, DatasourcePurposeFromJSONTyped, DatasourcePurposeToJSON, } from './DatasourcePurpose';
/*
causes circular dependendies
// .concat(DatasourceConfigBaseValidator)

*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasourceConfigLOCAL
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_DatasourceConfigLOCAL_webServerLocation")("string")(() => {
    const regex = new RegExp(/^https?:\/\/.+$/);
    return (value: string) => !!regex.exec(value);
});
export interface DatasourceConfigLOCAL extends DatasourceConfigBase {
    /**
     * Relative path from the mount point. Not allowed to start with "/", contain "://" or contain "." or ".." directory parts.
     * #type string
     * #memberof DatasourceConfigLOCAL
        
        
         */
    fullPath: string;
    /**
     * The webserver location where your local webserver is running to use for viewing images in the webapp when using the local datasource workflow.
     * Defaults to http://localhost:3456
     * #type string
     * #memberof DatasourceConfigLOCAL
        
        
    * @pattern_DatasourceConfigLOCAL_webServerLocation
         */
    webServerLocation?: string;
}
/**
 * Check if a given object implements the DatasourceConfigLOCAL interface.
 */
export function instanceOfDatasourceConfigLOCAL(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullPath" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasourceConfigBaseValidator, DatasourceConfigBaseStringifyShape, DatasourceConfigBaseStringifyShapeProperties, } from './DatasourceConfigBase';
import { DatasourcePurposeValidator, DatasourcePurposeStringifyShape, DatasourcePurposeStringifyShapeProperties, } from './DatasourcePurpose';
export const DatasourceConfigLOCALTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasourceConfigLOCAL => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasourceConfigLOCAL => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fullPath && (undefined === input.webServerLocation || "string" === typeof input.webServerLocation && $is_custom("pattern_DatasourceConfigLOCAL_webServerLocation", "string", "", input.webServerLocation)) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fullPath", "webServerLocation", "id", "purpose", "type", "thumbSuffix"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigLOCAL => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && (undefined === input.webServerLocation || "string" === typeof input.webServerLocation && ($is_custom("pattern_DatasourceConfigLOCAL_webServerLocation", "string", "", input.webServerLocation) || $guard(_exceptionable, {
                    path: _path + ".webServerLocation",
                    expected: "string (@pattern_DatasourceConfigLOCAL_webServerLocation)",
                    value: input.webServerLocation
                })) || $guard(_exceptionable, {
                    path: _path + ".webServerLocation",
                    expected: "(string | undefined)",
                    value: input.webServerLocation
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_DatasourceConfigBase_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fullPath", "webServerLocation", "id", "purpose", "type", "thumbSuffix"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigLOCAL",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigLOCAL",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasourceConfigLOCALTypiaAssertStringify = (input: DatasourceConfigLOCAL): string => {
    return ((input: any): string => { const assert = (input: any): DatasourceConfigLOCAL => {
        const __is = (input: any): input is DatasourceConfigLOCAL => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fullPath && (undefined === input.webServerLocation || "string" === typeof input.webServerLocation && $is_custom("pattern_DatasourceConfigLOCAL_webServerLocation", "string", "", input.webServerLocation)) && (undefined === input.id || "string" === typeof input.id && $is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id)) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose) && "string" === typeof input.type && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasourceConfigLOCAL => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fullPath || $guard(_exceptionable, {
                    path: _path + ".fullPath",
                    expected: "string",
                    value: input.fullPath
                })) && (undefined === input.webServerLocation || "string" === typeof input.webServerLocation && ($is_custom("pattern_DatasourceConfigLOCAL_webServerLocation", "string", "", input.webServerLocation) || $guard(_exceptionable, {
                    path: _path + ".webServerLocation",
                    expected: "string (@pattern_DatasourceConfigLOCAL_webServerLocation)",
                    value: input.webServerLocation
                })) || $guard(_exceptionable, {
                    path: _path + ".webServerLocation",
                    expected: "(string | undefined)",
                    value: input.webServerLocation
                })) && (undefined === input.id || "string" === typeof input.id && ($is_custom("pattern_DatasourceConfigBase_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_DatasourceConfigBase_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "(string | undefined)",
                    value: input.id
                })) && ("INPUT_OUTPUT" === input.purpose || "INPUT" === input.purpose || "LIGHTLY" === input.purpose || $guard(_exceptionable, {
                    path: _path + ".purpose",
                    expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                    value: input.purpose
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (undefined === input.thumbSuffix || "string" === typeof input.thumbSuffix || $guard(_exceptionable, {
                    path: _path + ".thumbSuffix",
                    expected: "(string | undefined)",
                    value: input.thumbSuffix
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigLOCAL",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasourceConfigLOCAL",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasourceConfigLOCAL): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.webServerLocation ? "" : `"webServerLocation":${undefined !== input.webServerLocation ? $string(input.webServerLocation) : undefined},`}${undefined === input.id ? "" : `"id":${undefined !== input.id ? $string(input.id) : undefined},`}${undefined === input.thumbSuffix ? "" : `"thumbSuffix":${undefined !== input.thumbSuffix ? $string(input.thumbSuffix) : undefined},`}"fullPath":${$string(input.fullPath)},"purpose":${(() => {
            if ("string" === typeof input.purpose)
                return $string(input.purpose);
            if ("string" === typeof input.purpose)
                return "\"" + input.purpose + "\"";
            $throws({
                expected: "(\"INPUT\" | \"INPUT_OUTPUT\" | \"LIGHTLY\")",
                value: input.purpose
            });
        })()},"type":${$string(input.type)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasourceConfigLOCALValidatorShape = {
    'fullPath': yup.string().defined().test('is-valid', 'Not a valid relative path. Not allowed to start with "/", contain "://" or contain "." or ".." directory parts.', (value) => {
        // empty string is allowed, but not undefined 
        if (!value && value !== '') {
            return false;
        }
        if (value === '.' ||
            value.startsWith('./') ||
            value.indexOf('/./') !== -1 ||
            value.endsWith('/.') ||
            value === '..' ||
            value.startsWith('../') ||
            value.indexOf('/../') !== -1 ||
            value.endsWith('/..') ||
            value.indexOf('://') !== -1 ||
            value.startsWith('/')) {
            return false;
        }
        return true;
    }),
    'webServerLocation': yup.string().matches(/^https?:\/\/.+$/).test('is-valid', "Forbidden trailing slash.", (value) => {
        if (value && value.endsWith('/')) {
            return false;
        }
        return true;
    }),
};
export const DatasourceConfigLOCALValidator = yup.object().shape(DatasourceConfigLOCALValidatorShape).concat(DatasourceConfigBaseValidator);
export const DatasourceConfigLOCALStringifyShapeProperties: ObjectSchema['properties'] = {
    'fullPath': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'webServerLocation': {
        'type': 'string',
        'pattern': "/^https?:\/\/.+$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const DatasourceConfigLOCALStringifyShape: ObjectSchema = {
    title: 'DatasourceConfigLOCAL Stringifier',
    type: 'object',
    properties: {
        ...DatasourceConfigBaseStringifyShapeProperties,
        ...DatasourceConfigLOCALStringifyShapeProperties
    },
    required: [
        ...(DatasourceConfigBaseStringifyShape.required ? DatasourceConfigBaseStringifyShape.required : []),
        'fullPath',
    ],
};
export const DatasourceConfigLOCALStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasourceConfigLOCALStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasourceConfigLOCALStringify: (data: DatasourceConfigLOCAL) => string = DatasourceConfigLOCALStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasourceConfigLOCALFromJSON(json: any): DatasourceConfigLOCAL {
    return DatasourceConfigLOCALFromJSONTyped(json, false);
}
export function DatasourceConfigLOCALFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasourceConfigLOCAL {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
    }
*/
    return {
        ...DatasourceConfigBaseFromJSONTyped(json, ignoreDiscriminator),
        'fullPath': json['fullPath'],
        'webServerLocation': !exists(json, 'webServerLocation') ? undefined : json['webServerLocation'],
    };
}
export function DatasourceConfigLOCALToJSON(value?: DatasourceConfigLOCAL | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...DatasourceConfigBaseToJSON(value),
        'fullPath': value.fullPath,
        'webServerLocation': value.webServerLocation,
    };
}
