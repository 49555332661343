/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SharedAccessType } from './SharedAccessType';
import { SharedAccessTypeFromJSON, SharedAccessTypeFromJSONTyped, SharedAccessTypeToJSON, } from './SharedAccessType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SharedAccessConfigData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_SharedAccessConfigData_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface SharedAccessConfigData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof SharedAccessConfigData
        
        
    * @pattern_SharedAccessConfigData_id
         */
    id: string;
    /**
     * Id of the user who owns the dataset
     * #type string
     * #memberof SharedAccessConfigData
        
        
         */
    owner: string;
    /**
     *
     * #type SharedAccessType
     * #memberof SharedAccessConfigData
        
        
         */
    accessType: SharedAccessType;
    /**
     * List of user mails with access to the dataset
     * #type Array<string>
     * #memberof SharedAccessConfigData
        
        
         */
    users: Array<string>;
    /**
     * List of teams with access to the dataset
     * #type Array<string>
     * #memberof SharedAccessConfigData
        
        
         */
    teams: Array<string>;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof SharedAccessConfigData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof SharedAccessConfigData
        
    * @minimum 0
            
         */
    lastModifiedAt: number;
}
/**
 * Check if a given object implements the SharedAccessConfigData interface.
 */
export function instanceOfSharedAccessConfigData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "accessType" in value;
    isInstance = isInstance && "users" in value;
    isInstance = isInstance && "teams" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "lastModifiedAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SharedAccessTypeValidator, SharedAccessTypeStringifyShape, SharedAccessTypeStringifyShapeProperties, } from './SharedAccessType';
export const SharedAccessConfigDataTypiaAssertEquals = (input: any) => {
    return ((input: any): SharedAccessConfigData => {
        const __is = (input: any, _exceptionable: boolean = true): input is SharedAccessConfigData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_SharedAccessConfigData_id", "string", "", input.id) && "string" === typeof input.owner && "WRITE" === input.accessType && (Array.isArray(input.users) && input.users.every((elem: any, _index1: number) => "string" === typeof elem)) && (Array.isArray(input.teams) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem)) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && 0 <= input.lastModifiedAt) && (7 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "owner", "accessType", "users", "teams", "createdAt", "lastModifiedAt"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SharedAccessConfigData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_SharedAccessConfigData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_SharedAccessConfigData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.owner || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "string",
                    value: input.owner
                })) && ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && ((Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "Array<string>",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "Array<string>",
                    value: input.users
                })) && ((Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "Array<string>",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "Array<string>",
                    value: input.teams
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                })) && (7 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "owner", "accessType", "users", "teams", "createdAt", "lastModifiedAt"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SharedAccessConfigDataTypiaAssertStringify = (input: SharedAccessConfigData): string => {
    return ((input: any): string => { const assert = (input: any): SharedAccessConfigData => {
        const __is = (input: any): input is SharedAccessConfigData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_SharedAccessConfigData_id", "string", "", input.id) && "string" === typeof input.owner && "WRITE" === input.accessType && (Array.isArray(input.users) && input.users.every((elem: any) => "string" === typeof elem)) && (Array.isArray(input.teams) && input.teams.every((elem: any) => "string" === typeof elem)) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && 0 <= input.lastModifiedAt);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SharedAccessConfigData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_SharedAccessConfigData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_SharedAccessConfigData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.owner || $guard(_exceptionable, {
                    path: _path + ".owner",
                    expected: "string",
                    value: input.owner
                })) && ("WRITE" === input.accessType || $guard(_exceptionable, {
                    path: _path + ".accessType",
                    expected: "\"WRITE\"",
                    value: input.accessType
                })) && ((Array.isArray(input.users) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "Array<string>",
                    value: input.users
                })) && input.users.every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".users[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".users",
                    expected: "Array<string>",
                    value: input.users
                })) && ((Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "Array<string>",
                    value: input.teams
                })) && input.teams.every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "Array<string>",
                    value: input.teams
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && ("number" === typeof input.lastModifiedAt && !Number.isNaN(input.lastModifiedAt) && (0 <= input.lastModifiedAt || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number (@minimum 0)",
                    value: input.lastModifiedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".lastModifiedAt",
                    expected: "number",
                    value: input.lastModifiedAt
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SharedAccessConfigData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SharedAccessConfigData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"id":${$string(input.id)},"owner":${$string(input.owner)},"accessType":${(() => {
            if ("string" === typeof input.accessType)
                return $string(input.accessType);
            if ("string" === typeof input.accessType)
                return "\"" + input.accessType + "\"";
            $throws({
                expected: "\"WRITE\"",
                value: input.accessType
            });
        })()},"users":${`[${input.users.map((elem: any) => $string(elem)).join(",")}]`},"teams":${`[${input.teams.map((elem: any) => $string(elem)).join(",")}]`},"createdAt":${input.createdAt},"lastModifiedAt":${input.lastModifiedAt}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SharedAccessConfigDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'owner': yup.string().defined(),
    'accessType': SharedAccessTypeValidator.defined(),
    'users': yup.array().of(yup.string()).defined(),
    'teams': yup.array().of(yup.string()).defined(),
    'createdAt': yup.number().min(0).defined(),
    'lastModifiedAt': yup.number().min(0).defined(),
};
export const SharedAccessConfigDataValidator = yup.object().shape(SharedAccessConfigDataValidatorShape);
export const SharedAccessConfigDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'owner': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'accessType': SharedAccessTypeStringifyShape,
    'users': {
        type: 'array',
        items: {
            type: 'string'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'teams': {
        type: 'array',
        items: {
            type: 'string'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'lastModifiedAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
};
export const SharedAccessConfigDataStringifyShape: ObjectSchema = {
    title: 'SharedAccessConfigData Stringifier',
    type: 'object',
    properties: {
        ...SharedAccessConfigDataStringifyShapeProperties
    },
    required: [
        'id',
        'owner',
        'accessType',
        'users',
        'teams',
        'createdAt',
        'lastModifiedAt',
    ],
};
export const SharedAccessConfigDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SharedAccessConfigDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SharedAccessConfigDataStringify: (data: SharedAccessConfigData) => string = SharedAccessConfigDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SharedAccessConfigDataFromJSON(json: any): SharedAccessConfigData {
    return SharedAccessConfigDataFromJSONTyped(json, false);
}
export function SharedAccessConfigDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedAccessConfigData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'owner': json['owner'],
        'accessType': SharedAccessTypeFromJSON(json['accessType']),
        'users': json['users'],
        'teams': json['teams'],
        'createdAt': json['createdAt'],
        'lastModifiedAt': json['lastModifiedAt'],
    };
}
export function SharedAccessConfigDataToJSON(value?: SharedAccessConfigData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'owner': value.owner,
        'accessType': SharedAccessTypeToJSON(value.accessType),
        'users': value.users,
        'teams': value.teams,
        'createdAt': value.createdAt,
        'lastModifiedAt': value.lastModifiedAt,
    };
}
