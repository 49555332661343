/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SelectionInputPredictionsName } from './SelectionInputPredictionsName';
import { SelectionInputPredictionsNameFromJSON, SelectionInputPredictionsNameFromJSONTyped, SelectionInputPredictionsNameToJSON, } from './SelectionInputPredictionsName';
import type { SelectionInputType } from './SelectionInputType';
import { SelectionInputTypeFromJSON, SelectionInputTypeFromJSONTyped, SelectionInputTypeToJSON, } from './SelectionInputType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SelectionConfigV3EntryInput
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_SelectionConfigV3EntryInput_task")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SelectionConfigV3EntryInput_score")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SelectionConfigV3EntryInput_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SelectionConfigV3EntryInput_tagName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
export interface SelectionConfigV3EntryInput {
    /**
     *
     * #type SelectionInputType
     * #memberof SelectionConfigV3EntryInput
        
        
         */
    type: SelectionInputType;
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof SelectionConfigV3EntryInput
        
        
    * @pattern_SelectionConfigV3EntryInput_task
         */
    task?: string;
    /**
     * Type of active learning score
     * #type string
     * #memberof SelectionConfigV3EntryInput
        
        
    * @pattern_SelectionConfigV3EntryInput_score
    * @minLength 1
         */
    score?: string;
    /**
     *
     * #type string
     * #memberof SelectionConfigV3EntryInput
        
        
    * @minLength 1
         */
    key?: string;
    /**
     *
     * #type SelectionInputPredictionsName
     * #memberof SelectionConfigV3EntryInput
        
        
         */
    name?: SelectionInputPredictionsName;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof SelectionConfigV3EntryInput
        
        
    * @pattern_SelectionConfigV3EntryInput_datasetId
         */
    datasetId?: string;
    /**
     * The name of the tag
     * #type string
     * #memberof SelectionConfigV3EntryInput
        
        
    * @pattern_SelectionConfigV3EntryInput_tagName
    * @minLength 3
         */
    tagName?: string;
    /**
     *
     * #type number
     * #memberof SelectionConfigV3EntryInput
        * @type int
        
         */
    randomSeed?: number;
    /**
     *
     * #type Set<string>
     * #memberof SelectionConfigV3EntryInput
        
        
         */
    categories?: Set<string>;
}
/**
 * Check if a given object implements the SelectionConfigV3EntryInput interface.
 */
export function instanceOfSelectionConfigV3EntryInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SelectionInputPredictionsNameValidator, SelectionInputPredictionsNameStringifyShape, SelectionInputPredictionsNameStringifyShapeProperties, } from './SelectionInputPredictionsName';
import { SelectionInputTypeValidator, SelectionInputTypeStringifyShape, SelectionInputTypeStringifyShapeProperties, } from './SelectionInputType';
export const SelectionConfigV3EntryInputTypiaAssertEquals = (input: any) => {
    return ((input: any): SelectionConfigV3EntryInput => {
        const __is = (input: any, _exceptionable: boolean = true): input is SelectionConfigV3EntryInput => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_SelectionConfigV3EntryInput_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_SelectionConfigV3EntryInput_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_SelectionConfigV3EntryInput_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_SelectionConfigV3EntryInput_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem))()) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryInput => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_SelectionConfigV3EntryInput_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_SelectionConfigV3EntryInput_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_SelectionConfigV3EntryInput_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_SelectionConfigV3EntryInput_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryInput",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryInput",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SelectionConfigV3EntryInputTypiaAssertStringify = (input: SelectionConfigV3EntryInput): string => {
    return ((input: any): string => { const assert = (input: any): SelectionConfigV3EntryInput => {
        const __is = (input: any): input is SelectionConfigV3EntryInput => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_SelectionConfigV3EntryInput_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_SelectionConfigV3EntryInput_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_SelectionConfigV3EntryInput_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_SelectionConfigV3EntryInput_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any) => "string" === typeof elem))());
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SelectionConfigV3EntryInput => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_SelectionConfigV3EntryInput_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_SelectionConfigV3EntryInput_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_SelectionConfigV3EntryInput_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_SelectionConfigV3EntryInput_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_SelectionConfigV3EntryInput_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index1: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryInput",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SelectionConfigV3EntryInput",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SelectionConfigV3EntryInput): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.task ? "" : `"task":${undefined !== input.task ? $string(input.task) : undefined},`}${undefined === input.score ? "" : `"score":${undefined !== input.score ? $string(input.score) : undefined},`}${undefined === input.key ? "" : `"key":${undefined !== input.key ? $string(input.key) : undefined},`}${undefined === input.name ? "" : `"name":${undefined !== input.name ? (() => {
            if ("string" === typeof input.name)
                return $string(input.name);
            if ("string" === typeof input.name)
                return "\"" + input.name + "\"";
            $throws({
                expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                value: input.name
            });
        })() : undefined},`}${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.tagName ? "" : `"tagName":${undefined !== input.tagName ? $string(input.tagName) : undefined},`}${undefined === input.randomSeed ? "" : `"randomSeed":${undefined !== input.randomSeed ? input.randomSeed : undefined},`}${undefined === input.categories ? "" : `"categories":${undefined !== input.categories ? "{}" : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                value: input.type
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SelectionConfigV3EntryInputValidatorShape = {
    'type': SelectionInputTypeValidator.defined(),
    'task': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/),
    'score': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).min(1),
    'key': yup.string().min(1),
    'name': SelectionInputPredictionsNameValidator.optional().default(undefined),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/),
    'tagName': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3),
    'randomSeed': yup.number().integer(),
    'categories': yup.array().of(yup.string()).min(1),
};
export const SelectionConfigV3EntryInputValidator = yup.object().shape(SelectionConfigV3EntryInputValidatorShape);
export const SelectionConfigV3EntryInputStringifyShapeProperties: ObjectSchema['properties'] = {
    'type': SelectionInputTypeStringifyShape,
    'task': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'score': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'key': {
        'type': 'string',
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'name': SelectionInputPredictionsNameStringifyShape,
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'tagName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'randomSeed': {
        'type': 'integer',
    } as unknown as fastJSON.IntegerSchema,
    'categories': {
        type: 'array',
        'minItems': 1,
        items: {
            type: 'string'
        } as unknown as fastJSON.Schema,
    } as unknown as fastJSON.ArraySchema,
};
export const SelectionConfigV3EntryInputStringifyShape: ObjectSchema = {
    title: 'SelectionConfigV3EntryInput Stringifier',
    type: 'object',
    properties: {
        ...SelectionConfigV3EntryInputStringifyShapeProperties
    },
    required: [
        'type',
    ],
};
export const SelectionConfigV3EntryInputStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SelectionConfigV3EntryInputStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SelectionConfigV3EntryInputStringify: (data: SelectionConfigV3EntryInput) => string = SelectionConfigV3EntryInputStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SelectionConfigV3EntryInputFromJSON(json: any): SelectionConfigV3EntryInput {
    return SelectionConfigV3EntryInputFromJSONTyped(json, false);
}
export function SelectionConfigV3EntryInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectionConfigV3EntryInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'type': SelectionInputTypeFromJSON(json['type']),
        'task': !exists(json, 'task') ? undefined : json['task'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'name': !exists(json, 'name') ? undefined : SelectionInputPredictionsNameFromJSON(json['name']),
        'datasetId': !exists(json, 'datasetId') ? undefined : json['datasetId'],
        'tagName': !exists(json, 'tagName') ? undefined : json['tagName'],
        'randomSeed': !exists(json, 'randomSeed') ? undefined : json['randomSeed'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
    };
}
export function SelectionConfigV3EntryInputToJSON(value?: SelectionConfigV3EntryInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': SelectionInputTypeToJSON(value.type),
        'task': value.task,
        'score': value.score,
        'key': value.key,
        'name': SelectionInputPredictionsNameToJSON(value.name),
        'datasetId': value.datasetId,
        'tagName': value.tagName,
        'randomSeed': value.randomSeed,
        'categories': value.categories === undefined ? undefined : Array.from(value.categories as Set<any>),
    };
}
