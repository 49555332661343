/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TeamBasicData } from './TeamBasicData';
import { TeamBasicDataFromJSON, TeamBasicDataFromJSONTyped, TeamBasicDataToJSON, } from './TeamBasicData';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface ProfileBasicData
 *
 */
// create custom validators for the regexes
export interface ProfileBasicData {
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    id: string;
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    nickname?: string;
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    name?: string;
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    givenName?: string;
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    familyName?: string;
    /**
     *
     * #type string
     * #memberof ProfileBasicData
        
        
         */
    email?: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof ProfileBasicData
        
    * @minimum 0
            
         */
    createdAt: number;
    /**
     *
     * #type Array<TeamBasicData>
     * #memberof ProfileBasicData
        
        
         */
    teams?: Array<TeamBasicData>;
}
/**
 * Check if a given object implements the ProfileBasicData interface.
 */
export function instanceOfProfileBasicData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TeamBasicDataValidator, TeamBasicDataStringifyShape, TeamBasicDataStringifyShapeProperties, } from './TeamBasicData';
export const ProfileBasicDataTypiaAssertEquals = (input: any) => {
    return ((input: any): ProfileBasicData => {
        const __is = (input: any, _exceptionable: boolean = true): input is ProfileBasicData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && (undefined === input.nickname || "string" === typeof input.nickname) && (undefined === input.name || "string" === typeof input.name) && (undefined === input.givenName || "string" === typeof input.givenName) && (undefined === input.familyName || "string" === typeof input.familyName) && (undefined === input.email || "string" === typeof input.email) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "nickname", "name", "givenName", "familyName", "email", "createdAt", "teams"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_TeamBasicData_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name", "role"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ProfileBasicData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && (undefined === input.nickname || "string" === typeof input.nickname || $guard(_exceptionable, {
                    path: _path + ".nickname",
                    expected: "(string | undefined)",
                    value: input.nickname
                })) && (undefined === input.name || "string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.givenName || "string" === typeof input.givenName || $guard(_exceptionable, {
                    path: _path + ".givenName",
                    expected: "(string | undefined)",
                    value: input.givenName
                })) && (undefined === input.familyName || "string" === typeof input.familyName || $guard(_exceptionable, {
                    path: _path + ".familyName",
                    expected: "(string | undefined)",
                    value: input.familyName
                })) && (undefined === input.email || "string" === typeof input.email || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "(string | undefined)",
                    value: input.email
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) && $ao1(elem, _path + ".teams[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "nickname", "name", "givenName", "familyName", "email", "createdAt", "teams"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_TeamBasicData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_TeamBasicData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name", "role"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ProfileBasicData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ProfileBasicData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const ProfileBasicDataTypiaAssertStringify = (input: ProfileBasicData): string => {
    return ((input: any): string => { const assert = (input: any): ProfileBasicData => {
        const __is = (input: any): input is ProfileBasicData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && (undefined === input.nickname || "string" === typeof input.nickname) && (undefined === input.name || "string" === typeof input.name) && (undefined === input.givenName || "string" === typeof input.givenName) && (undefined === input.familyName || "string" === typeof input.familyName) && (undefined === input.email || "string" === typeof input.email) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt) && (undefined === input.teams || Array.isArray(input.teams) && input.teams.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem)));
            const $io1 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_TeamBasicData_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is ProfileBasicData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && (undefined === input.nickname || "string" === typeof input.nickname || $guard(_exceptionable, {
                    path: _path + ".nickname",
                    expected: "(string | undefined)",
                    value: input.nickname
                })) && (undefined === input.name || "string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(string | undefined)",
                    value: input.name
                })) && (undefined === input.givenName || "string" === typeof input.givenName || $guard(_exceptionable, {
                    path: _path + ".givenName",
                    expected: "(string | undefined)",
                    value: input.givenName
                })) && (undefined === input.familyName || "string" === typeof input.familyName || $guard(_exceptionable, {
                    path: _path + ".familyName",
                    expected: "(string | undefined)",
                    value: input.familyName
                })) && (undefined === input.email || "string" === typeof input.email || $guard(_exceptionable, {
                    path: _path + ".email",
                    expected: "(string | undefined)",
                    value: input.email
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (undefined === input.teams || (Array.isArray(input.teams) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                })) && input.teams.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) && $ao1(elem, _path + ".teams[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".teams[" + _index1 + "]",
                    expected: "TeamBasicData",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".teams",
                    expected: "(Array<TeamBasicData> | undefined)",
                    value: input.teams
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_TeamBasicData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_TeamBasicData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role || $guard(_exceptionable, {
                    path: _path + ".role",
                    expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                    value: input.role
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "ProfileBasicData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "ProfileBasicData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: ProfileBasicData): string => {
        const $io1 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_TeamBasicData_id", "string", "", input.id) && "string" === typeof input.name && ("OWNER" === input.role || "ADMIN" === input.role || "MEMBER" === input.role || "SERVICEACCOUNT" === input.role);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.nickname ? "" : `"nickname":${undefined !== input.nickname ? $string(input.nickname) : undefined},`}${undefined === input.name ? "" : `"name":${undefined !== input.name ? $string(input.name) : undefined},`}${undefined === input.givenName ? "" : `"givenName":${undefined !== input.givenName ? $string(input.givenName) : undefined},`}${undefined === input.familyName ? "" : `"familyName":${undefined !== input.familyName ? $string(input.familyName) : undefined},`}${undefined === input.email ? "" : `"email":${undefined !== input.email ? $string(input.email) : undefined},`}${undefined === input.teams ? "" : `"teams":${undefined !== input.teams ? `[${input.teams.map((elem: any) => $so1(elem)).join(",")}]` : undefined},`}"id":${$string(input.id)},"createdAt":${input.createdAt}}`;
        const $so1 = (input: any): any => `{"id":${$string(input.id)},"name":${$string(input.name)},"role":${(() => {
            if ("string" === typeof input.role)
                return $string(input.role);
            if ("string" === typeof input.role)
                return "\"" + input.role + "\"";
            $throws({
                expected: "(\"ADMIN\" | \"MEMBER\" | \"OWNER\" | \"SERVICEACCOUNT\")",
                value: input.role
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const ProfileBasicDataValidatorShape = {
    'id': yup.string().defined(),
    'nickname': yup.string(),
    'name': yup.string(),
    'givenName': yup.string(),
    'familyName': yup.string(),
    'email': yup.string(),
    'createdAt': yup.number().min(0).defined(),
    'teams': yup.array().of(TeamBasicDataValidator),
};
export const ProfileBasicDataValidator = yup.object().shape(ProfileBasicDataValidatorShape);
export const ProfileBasicDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'nickname': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'name': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'givenName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'familyName': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'email': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'teams': {
        type: 'array',
        items: {
            TeamBasicDataStringifyShape
        }
    },
};
export const ProfileBasicDataStringifyShape: ObjectSchema = {
    title: 'ProfileBasicData Stringifier',
    type: 'object',
    properties: {
        ...ProfileBasicDataStringifyShapeProperties
    },
    required: [
        'id',
        'createdAt',
    ],
};
export const ProfileBasicDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(ProfileBasicDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const ProfileBasicDataStringify: (data: ProfileBasicData) => string = ProfileBasicDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function ProfileBasicDataFromJSON(json: any): ProfileBasicData {
    return ProfileBasicDataFromJSONTyped(json, false);
}
export function ProfileBasicDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileBasicData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'createdAt': json['createdAt'],
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any> || []).map(TeamBasicDataFromJSON)),
    };
}
export function ProfileBasicDataToJSON(value?: ProfileBasicData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'nickname': value.nickname,
        'name': value.name,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'email': value.email,
        'createdAt': value.createdAt,
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any> || []).map(TeamBasicDataToJSON)),
    };
}
