/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { DatasetCreator } from './DatasetCreator';
import { DatasetCreatorFromJSON, DatasetCreatorFromJSONTyped, DatasetCreatorToJSON, } from './DatasetCreator';
import type { DatasetType } from './DatasetType';
import { DatasetTypeFromJSON, DatasetTypeFromJSONTyped, DatasetTypeToJSON, } from './DatasetType';
import type { ImageType } from './ImageType';
import { ImageTypeFromJSON, ImageTypeFromJSONTyped, ImageTypeToJSON, } from './ImageType';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DatasetCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_DatasetCreateRequest_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_DatasetCreateRequest_parentDatasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DatasetCreateRequest {
    /**
     *
     * #type string
     * #memberof DatasetCreateRequest
        
        
    * @pattern_DatasetCreateRequest_name
    * @minLength 3
         */
    name: string;
    /**
     *
     * #type DatasetType
     * #memberof DatasetCreateRequest
        
        
         */
    type?: DatasetType;
    /**
     *
     * #type ImageType
     * #memberof DatasetCreateRequest
        
        
         */
    imgType?: ImageType;
    /**
     *
     * #type DatasetCreator
     * #memberof DatasetCreateRequest
        
        
         */
    creator?: DatasetCreator;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DatasetCreateRequest
        
        
    * @pattern_DatasetCreateRequest_parentDatasetId
         */
    parentDatasetId?: string;
}
/**
 * Check if a given object implements the DatasetCreateRequest interface.
 */
export function instanceOfDatasetCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { DatasetCreatorValidator, DatasetCreatorStringifyShape, DatasetCreatorStringifyShapeProperties, } from './DatasetCreator';
import { DatasetTypeValidator, DatasetTypeStringifyShape, DatasetTypeStringifyShapeProperties, } from './DatasetType';
import { ImageTypeValidator, ImageTypeStringifyShape, ImageTypeStringifyShapeProperties, } from './ImageType';
export const DatasetCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DatasetCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DatasetCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_DatasetCreateRequest_name", "string", "", input.name) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && $is_custom("pattern_DatasetCreateRequest_parentDatasetId", "string", "", input.parentDatasetId)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "type", "imgType", "creator", "parentDatasetId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasetCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_DatasetCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_DatasetCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                    value: input.type
                })) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                    value: input.imgType
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && ($is_custom("pattern_DatasetCreateRequest_parentDatasetId", "string", "", input.parentDatasetId) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "string (@pattern_DatasetCreateRequest_parentDatasetId)",
                    value: input.parentDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "(string | undefined)",
                    value: input.parentDatasetId
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "type", "imgType", "creator", "parentDatasetId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DatasetCreateRequestTypiaAssertStringify = (input: DatasetCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): DatasetCreateRequest => {
        const __is = (input: any): input is DatasetCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.name && 3 <= input.name.length && $is_custom("pattern_DatasetCreateRequest_name", "string", "", input.name) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && $is_custom("pattern_DatasetCreateRequest_parentDatasetId", "string", "", input.parentDatasetId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DatasetCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && (3 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 3)",
                    value: input.name
                })) && ($is_custom("pattern_DatasetCreateRequest_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_DatasetCreateRequest_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.type || "Crops" === input.type || "Images" === input.type || "Videos" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                    value: input.type
                })) && (undefined === input.imgType || "full" === input.imgType || "thumbnail" === input.imgType || "meta" === input.imgType || $guard(_exceptionable, {
                    path: _path + ".imgType",
                    expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                    value: input.imgType
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (undefined === input.parentDatasetId || "string" === typeof input.parentDatasetId && ($is_custom("pattern_DatasetCreateRequest_parentDatasetId", "string", "", input.parentDatasetId) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "string (@pattern_DatasetCreateRequest_parentDatasetId)",
                    value: input.parentDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentDatasetId",
                    expected: "(string | undefined)",
                    value: input.parentDatasetId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DatasetCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DatasetCreateRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.type ? "" : `"type":${undefined !== input.type ? (() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"Crops\" | \"Images\" | \"Videos\" | undefined)",
                value: input.type
            });
        })() : undefined},`}${undefined === input.imgType ? "" : `"imgType":${undefined !== input.imgType ? (() => {
            if ("string" === typeof input.imgType)
                return $string(input.imgType);
            if ("string" === typeof input.imgType)
                return "\"" + input.imgType + "\"";
            $throws({
                expected: "(\"full\" | \"meta\" | \"thumbnail\" | undefined)",
                value: input.imgType
            });
        })() : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}${undefined === input.parentDatasetId ? "" : `"parentDatasetId":${undefined !== input.parentDatasetId ? $string(input.parentDatasetId) : undefined},`}"name":${$string(input.name)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DatasetCreateRequestValidatorShape = {
    'name': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/).min(3).defined(),
    'type': DatasetTypeValidator.optional().default(undefined),
    'imgType': ImageTypeValidator.optional().default(undefined),
    'creator': DatasetCreatorValidator.optional().default(undefined),
    'parentDatasetId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const DatasetCreateRequestValidator = yup.object().shape(DatasetCreateRequestValidatorShape);
export const DatasetCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 _-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'type': DatasetTypeStringifyShape,
    'imgType': ImageTypeStringifyShape,
    'creator': DatasetCreatorStringifyShape,
    'parentDatasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const DatasetCreateRequestStringifyShape: ObjectSchema = {
    title: 'DatasetCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...DatasetCreateRequestStringifyShapeProperties
    },
    required: [
        'name',
    ],
};
export const DatasetCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DatasetCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DatasetCreateRequestStringify: (data: DatasetCreateRequest) => string = DatasetCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DatasetCreateRequestFromJSON(json: any): DatasetCreateRequest {
    return DatasetCreateRequestFromJSONTyped(json, false);
}
export function DatasetCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'name': json['name'],
        'type': !exists(json, 'type') ? undefined : DatasetTypeFromJSON(json['type']),
        'imgType': !exists(json, 'imgType') ? undefined : ImageTypeFromJSON(json['imgType']),
        'creator': !exists(json, 'creator') ? undefined : DatasetCreatorFromJSON(json['creator']),
        'parentDatasetId': !exists(json, 'parentDatasetId') ? undefined : json['parentDatasetId'],
    };
}
export function DatasetCreateRequestToJSON(value?: DatasetCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': DatasetTypeToJSON(value.type),
        'imgType': ImageTypeToJSON(value.imgType),
        'creator': DatasetCreatorToJSON(value.creator),
        'parentDatasetId': value.parentDatasetId,
    };
}
