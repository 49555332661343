/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
import type { DockerWorkerConfig } from './DockerWorkerConfig';
import { DockerWorkerConfigFromJSON, DockerWorkerConfigFromJSONTyped, DockerWorkerConfigToJSON, } from './DockerWorkerConfig';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerWorkerConfigCreateRequest
 *
 */
// create custom validators for the regexes
export interface DockerWorkerConfigCreateRequest {
    /**
     *
     * #type DockerWorkerConfig
     * #memberof DockerWorkerConfigCreateRequest
        
        
         */
    config: DockerWorkerConfig;
    /**
     *
     * #type Creator
     * #memberof DockerWorkerConfigCreateRequest
        
        
         */
    creator?: Creator;
}
/**
 * Check if a given object implements the DockerWorkerConfigCreateRequest interface.
 */
export function instanceOfDockerWorkerConfigCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "config" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
import { DockerWorkerConfigValidator, DockerWorkerConfigStringifyShape, DockerWorkerConfigStringifyShapeProperties, } from './DockerWorkerConfig';
export const DockerWorkerConfigCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerWorkerConfigCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerWorkerConfigCreateRequest => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "object" === typeof input.config && null !== input.config && $io1(input.config, true && _exceptionable) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["config", "creator"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "FULL" === input.workerType && (null === input.docker || undefined === input.docker || "object" === typeof input.docker && null !== input.docker && false === Array.isArray(input.docker) && $io2(input.docker, true && _exceptionable)) && (null === input.lightly || undefined === input.lightly || "object" === typeof input.lightly && null !== input.lightly && false === Array.isArray(input.lightly) && $io3(input.lightly, true && _exceptionable)) && (undefined === input.selection || "object" === typeof input.selection && null !== input.selection && $io4(input.selection, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["workerType", "docker", "lightly", "selection"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && Math.floor(input.nSamples) === input.nSamples && (-2147483648 <= input.nSamples && input.nSamples <= 2147483647) && -1 <= input.nSamples) && (undefined === input.proportionSamples || "number" === typeof input.proportionSamples && 0 <= input.proportionSamples && 1 >= input.proportionSamples) && (Array.isArray(input.strategies) && input.strategies.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io5(elem, true && _exceptionable))) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["nSamples", "proportionSamples", "strategies"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "object" === typeof input.input && null !== input.input && $io6(input.input, true && _exceptionable) && ("object" === typeof input.strategy && null !== input.strategy && $io7(input.strategy, true && _exceptionable)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["input", "strategy"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_SelectionConfigEntryInput_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_SelectionConfigEntryInput_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_SelectionConfigEntryInput_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_SelectionConfigEntryInput_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any, _index2: number) => "string" === typeof elem))()) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io8(input.target, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.config && null !== input.config || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "DockerWorkerConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "DockerWorkerConfig",
                    value: input.config
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["config", "creator"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && (null === input.docker || undefined === input.docker || ("object" === typeof input.docker && null !== input.docker && false === Array.isArray(input.docker) || $guard(_exceptionable, {
                    path: _path + ".docker",
                    expected: "(__type | null | undefined)",
                    value: input.docker
                })) && $ao2(input.docker, _path + ".docker", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".docker",
                    expected: "(__type | null | undefined)",
                    value: input.docker
                })) && (null === input.lightly || undefined === input.lightly || ("object" === typeof input.lightly && null !== input.lightly && false === Array.isArray(input.lightly) || $guard(_exceptionable, {
                    path: _path + ".lightly",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.lightly
                })) && $ao3(input.lightly, _path + ".lightly", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".lightly",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.lightly
                })) && (undefined === input.selection || ("object" === typeof input.selection && null !== input.selection || $guard(_exceptionable, {
                    path: _path + ".selection",
                    expected: "(SelectionConfig | undefined)",
                    value: input.selection
                })) && $ao4(input.selection, _path + ".selection", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".selection",
                    expected: "(SelectionConfig | undefined)",
                    value: input.selection
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["workerType", "docker", "lightly", "selection"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && (Math.floor(input.nSamples) === input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@type int)",
                    value: input.nSamples
                })) && (-2147483648 <= input.nSamples && input.nSamples <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@type int)",
                    value: input.nSamples
                })) && (-1 <= input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@minimum -1)",
                    value: input.nSamples
                })) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.proportionSamples || "number" === typeof input.proportionSamples && (0 <= input.proportionSamples || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "number (@minimum 0)",
                    value: input.proportionSamples
                })) && (1 >= input.proportionSamples || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "number (@maximum 1)",
                    value: input.proportionSamples
                })) || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "(number | undefined)",
                    value: input.proportionSamples
                })) && ((Array.isArray(input.strategies) || $guard(_exceptionable, {
                    path: _path + ".strategies",
                    expected: "Array<SelectionConfigEntry>",
                    value: input.strategies
                })) && input.strategies.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".strategies[" + _index1 + "]",
                    expected: "SelectionConfigEntry",
                    value: elem
                })) && $ao5(elem, _path + ".strategies[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategies[" + _index1 + "]",
                    expected: "SelectionConfigEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".strategies",
                    expected: "Array<SelectionConfigEntry>",
                    value: input.strategies
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["nSamples", "proportionSamples", "strategies"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.input && null !== input.input || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigEntryInput",
                    value: input.input
                })) && $ao6(input.input, _path + ".input", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigEntryInput",
                    value: input.input
                })) && (("object" === typeof input.strategy && null !== input.strategy || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigEntryStrategy",
                    value: input.strategy
                })) && $ao7(input.strategy, _path + ".strategy", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigEntryStrategy",
                    value: input.strategy
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["input", "strategy"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_SelectionConfigEntryInput_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_SelectionConfigEntryInput_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_SelectionConfigEntryInput_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_SelectionConfigEntryInput_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_SelectionConfigEntryInput_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_SelectionConfigEntryInput_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_SelectionConfigEntryInput_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_SelectionConfigEntryInput_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "task", "score", "key", "name", "datasetId", "tagName", "randomSeed", "categories"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao8(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "stopping_condition_minimum_distance", "threshold", "operation", "target"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => 0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerWorkerConfigCreateRequestTypiaAssertStringify = (input: DockerWorkerConfigCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): DockerWorkerConfigCreateRequest => {
        const __is = (input: any): input is DockerWorkerConfigCreateRequest => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "object" === typeof input.config && null !== input.config && $io1(input.config) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            const $io1 = (input: any): boolean => "FULL" === input.workerType && (null === input.docker || undefined === input.docker || "object" === typeof input.docker && null !== input.docker && false === Array.isArray(input.docker) && $io2(input.docker)) && (null === input.lightly || undefined === input.lightly || "object" === typeof input.lightly && null !== input.lightly && false === Array.isArray(input.lightly) && $io3(input.lightly)) && (undefined === input.selection || "object" === typeof input.selection && null !== input.selection && $io4(input.selection));
            const $io2 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io4 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) && Math.floor(input.nSamples) === input.nSamples && (-2147483648 <= input.nSamples && input.nSamples <= 2147483647) && -1 <= input.nSamples) && (undefined === input.proportionSamples || "number" === typeof input.proportionSamples && 0 <= input.proportionSamples && 1 >= input.proportionSamples) && (Array.isArray(input.strategies) && input.strategies.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem)));
            const $io5 = (input: any): boolean => "object" === typeof input.input && null !== input.input && $io6(input.input) && ("object" === typeof input.strategy && null !== input.strategy && $io7(input.strategy));
            const $io6 = (input: any): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_SelectionConfigEntryInput_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_SelectionConfigEntryInput_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_SelectionConfigEntryInput_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_SelectionConfigEntryInput_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any) => "string" === typeof elem))());
            const $io7 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance)) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold)) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io8(input.target));
            const $io8 = (input: any): boolean => true;
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerWorkerConfigCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.config && null !== input.config || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "DockerWorkerConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "DockerWorkerConfig",
                    value: input.config
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("FULL" === input.workerType || $guard(_exceptionable, {
                    path: _path + ".workerType",
                    expected: "\"FULL\"",
                    value: input.workerType
                })) && (null === input.docker || undefined === input.docker || ("object" === typeof input.docker && null !== input.docker && false === Array.isArray(input.docker) || $guard(_exceptionable, {
                    path: _path + ".docker",
                    expected: "(__type | null | undefined)",
                    value: input.docker
                })) && $ao2(input.docker, _path + ".docker", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".docker",
                    expected: "(__type | null | undefined)",
                    value: input.docker
                })) && (null === input.lightly || undefined === input.lightly || ("object" === typeof input.lightly && null !== input.lightly && false === Array.isArray(input.lightly) || $guard(_exceptionable, {
                    path: _path + ".lightly",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.lightly
                })) && $ao3(input.lightly, _path + ".lightly", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".lightly",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.lightly
                })) && (undefined === input.selection || ("object" === typeof input.selection && null !== input.selection || $guard(_exceptionable, {
                    path: _path + ".selection",
                    expected: "(SelectionConfig | undefined)",
                    value: input.selection
                })) && $ao4(input.selection, _path + ".selection", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".selection",
                    expected: "(SelectionConfig | undefined)",
                    value: input.selection
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) && (Math.floor(input.nSamples) === input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@type int)",
                    value: input.nSamples
                })) && (-2147483648 <= input.nSamples && input.nSamples <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@type int)",
                    value: input.nSamples
                })) && (-1 <= input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "number (@minimum -1)",
                    value: input.nSamples
                })) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.proportionSamples || "number" === typeof input.proportionSamples && (0 <= input.proportionSamples || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "number (@minimum 0)",
                    value: input.proportionSamples
                })) && (1 >= input.proportionSamples || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "number (@maximum 1)",
                    value: input.proportionSamples
                })) || $guard(_exceptionable, {
                    path: _path + ".proportionSamples",
                    expected: "(number | undefined)",
                    value: input.proportionSamples
                })) && ((Array.isArray(input.strategies) || $guard(_exceptionable, {
                    path: _path + ".strategies",
                    expected: "Array<SelectionConfigEntry>",
                    value: input.strategies
                })) && input.strategies.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".strategies[" + _index1 + "]",
                    expected: "SelectionConfigEntry",
                    value: elem
                })) && $ao5(elem, _path + ".strategies[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategies[" + _index1 + "]",
                    expected: "SelectionConfigEntry",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".strategies",
                    expected: "Array<SelectionConfigEntry>",
                    value: input.strategies
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (("object" === typeof input.input && null !== input.input || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigEntryInput",
                    value: input.input
                })) && $ao6(input.input, _path + ".input", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".input",
                    expected: "SelectionConfigEntryInput",
                    value: input.input
                })) && (("object" === typeof input.strategy && null !== input.strategy || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigEntryStrategy",
                    value: input.strategy
                })) && $ao7(input.strategy, _path + ".strategy", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".strategy",
                    expected: "SelectionConfigEntryStrategy",
                    value: input.strategy
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                    value: input.type
                })) && (undefined === input.task || "string" === typeof input.task && ($is_custom("pattern_SelectionConfigEntryInput_task", "string", "", input.task) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "string (@pattern_SelectionConfigEntryInput_task)",
                    value: input.task
                })) || $guard(_exceptionable, {
                    path: _path + ".task",
                    expected: "(string | undefined)",
                    value: input.task
                })) && (undefined === input.score || "string" === typeof input.score && (1 <= input.score.length || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@minLength 1)",
                    value: input.score
                })) && ($is_custom("pattern_SelectionConfigEntryInput_score", "string", "", input.score) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "string (@pattern_SelectionConfigEntryInput_score)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "(string | undefined)",
                    value: input.score
                })) && (undefined === input.key || "string" === typeof input.key && (1 <= input.key.length || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "string (@minLength 1)",
                    value: input.key
                })) || $guard(_exceptionable, {
                    path: _path + ".key",
                    expected: "(string | undefined)",
                    value: input.key
                })) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                    value: input.name
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_SelectionConfigEntryInput_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_SelectionConfigEntryInput_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && (undefined === input.tagName || "string" === typeof input.tagName && (3 <= input.tagName.length || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@minLength 3)",
                    value: input.tagName
                })) && ($is_custom("pattern_SelectionConfigEntryInput_tagName", "string", "", input.tagName) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "string (@pattern_SelectionConfigEntryInput_tagName)",
                    value: input.tagName
                })) || $guard(_exceptionable, {
                    path: _path + ".tagName",
                    expected: "(string | undefined)",
                    value: input.tagName
                })) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && !Number.isNaN(input.randomSeed) && (Math.floor(input.randomSeed) === input.randomSeed || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "number (@type int)",
                    value: input.randomSeed
                })) || $guard(_exceptionable, {
                    path: _path + ".randomSeed",
                    expected: "(number | undefined)",
                    value: input.randomSeed
                })) && (undefined === input.categories || (input.categories instanceof Set || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                })) && (() => [...input.categories].every((elem: any, _index2: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "string",
                    value: elem
                })))() || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "(Set<string> | undefined)",
                    value: input.categories
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                    value: input.type
                })) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance && !Number.isNaN(input.stopping_condition_minimum_distance) || $guard(_exceptionable, {
                    path: _path + ".stopping_condition_minimum_distance",
                    expected: "(number | undefined)",
                    value: input.stopping_condition_minimum_distance
                })) && (undefined === input.threshold || "number" === typeof input.threshold && !Number.isNaN(input.threshold) || $guard(_exceptionable, {
                    path: _path + ".threshold",
                    expected: "(number | undefined)",
                    value: input.threshold
                })) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation || $guard(_exceptionable, {
                    path: _path + ".operation",
                    expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                    value: input.operation
                })) && (undefined === input.target || ("object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                })) && $ao8(input.target, _path + ".target", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".target",
                    expected: "(object | undefined)",
                    value: input.target
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => true;
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerWorkerConfigCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerWorkerConfigCreateRequest): string => {
        const $io1 = (input: any): boolean => "FULL" === input.workerType && (null === input.docker || undefined === input.docker || "object" === typeof input.docker && null !== input.docker && false === Array.isArray(input.docker) && $io2(input.docker)) && (null === input.lightly || undefined === input.lightly || "object" === typeof input.lightly && null !== input.lightly && false === Array.isArray(input.lightly) && $io3(input.lightly)) && (undefined === input.selection || "object" === typeof input.selection && null !== input.selection && $io4(input.selection));
        const $io2 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io4 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && Math.floor(input.nSamples) === input.nSamples && (-2147483648 <= input.nSamples && input.nSamples <= 2147483647) && -1 <= input.nSamples) && (undefined === input.proportionSamples || "number" === typeof input.proportionSamples && 0 <= input.proportionSamples && 1 >= input.proportionSamples) && (Array.isArray(input.strategies) && input.strategies.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem)));
        const $io5 = (input: any): boolean => "object" === typeof input.input && null !== input.input && $io6(input.input) && ("object" === typeof input.strategy && null !== input.strategy && $io7(input.strategy));
        const $io6 = (input: any): boolean => ("EMBEDDINGS" === input.type || "SCORES" === input.type || "METADATA" === input.type || "PREDICTIONS" === input.type || "RANDOM" === input.type) && (undefined === input.task || "string" === typeof input.task && $is_custom("pattern_SelectionConfigEntryInput_task", "string", "", input.task)) && (undefined === input.score || "string" === typeof input.score && 1 <= input.score.length && $is_custom("pattern_SelectionConfigEntryInput_score", "string", "", input.score)) && (undefined === input.key || "string" === typeof input.key && 1 <= input.key.length) && (undefined === input.name || "CLASS_DISTRIBUTION" === input.name || "CATEGORY_COUNT" === input.name) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_SelectionConfigEntryInput_datasetId", "string", "", input.datasetId)) && (undefined === input.tagName || "string" === typeof input.tagName && 3 <= input.tagName.length && $is_custom("pattern_SelectionConfigEntryInput_tagName", "string", "", input.tagName)) && (undefined === input.randomSeed || "number" === typeof input.randomSeed && Math.floor(input.randomSeed) === input.randomSeed && (-2147483648 <= input.randomSeed && input.randomSeed <= 2147483647)) && (undefined === input.categories || input.categories instanceof Set && (() => [...input.categories].every((elem: any) => "string" === typeof elem))());
        const $io7 = (input: any): boolean => ("DIVERSITY" === input.type || "WEIGHTS" === input.type || "THRESHOLD" === input.type || "BALANCE" === input.type || "SIMILARITY" === input.type) && (undefined === input.stopping_condition_minimum_distance || "number" === typeof input.stopping_condition_minimum_distance) && (undefined === input.threshold || "number" === typeof input.threshold) && (undefined === input.operation || "SMALLER" === input.operation || "SMALLER_EQUAL" === input.operation || "BIGGER" === input.operation || "BIGGER_EQUAL" === input.operation) && (undefined === input.target || "object" === typeof input.target && null !== input.target && false === Array.isArray(input.target) && $io8(input.target));
        const $io8 = (input: any): boolean => true;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"config":${$so1(input.config)}}`;
        const $so1 = (input: any): any => `{${undefined === input.docker ? "" : `"docker":${undefined !== input.docker ? null !== input.docker ? $so2(input.docker) : "null" : undefined},`}${undefined === input.lightly ? "" : `"lightly":${undefined !== input.lightly ? null !== input.lightly ? $so3(input.lightly) : "null" : undefined},`}${undefined === input.selection ? "" : `"selection":${undefined !== input.selection ? $so4(input.selection) : undefined},`}"workerType":${(() => {
            if ("string" === typeof input.workerType)
                return $string(input.workerType);
            if ("string" === typeof input.workerType)
                return "\"" + input.workerType + "\"";
            $throws({
                expected: "\"FULL\"",
                value: input.workerType
            });
        })()}}`;
        const $so2 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so3 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so4 = (input: any): any => `{${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined},`}${undefined === input.proportionSamples ? "" : `"proportionSamples":${undefined !== input.proportionSamples ? input.proportionSamples : undefined},`}"strategies":${`[${input.strategies.map((elem: any) => $so5(elem)).join(",")}]`}}`;
        const $so5 = (input: any): any => `{"input":${$so6(input.input)},"strategy":${$so7(input.strategy)}}`;
        const $so6 = (input: any): any => `{${undefined === input.task ? "" : `"task":${undefined !== input.task ? $string(input.task) : undefined},`}${undefined === input.score ? "" : `"score":${undefined !== input.score ? $string(input.score) : undefined},`}${undefined === input.key ? "" : `"key":${undefined !== input.key ? $string(input.key) : undefined},`}${undefined === input.name ? "" : `"name":${undefined !== input.name ? (() => {
            if ("string" === typeof input.name)
                return $string(input.name);
            if ("string" === typeof input.name)
                return "\"" + input.name + "\"";
            $throws({
                expected: "(\"CATEGORY_COUNT\" | \"CLASS_DISTRIBUTION\" | undefined)",
                value: input.name
            });
        })() : undefined},`}${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.tagName ? "" : `"tagName":${undefined !== input.tagName ? $string(input.tagName) : undefined},`}${undefined === input.randomSeed ? "" : `"randomSeed":${undefined !== input.randomSeed ? input.randomSeed : undefined},`}${undefined === input.categories ? "" : `"categories":${undefined !== input.categories ? "{}" : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"EMBEDDINGS\" | \"METADATA\" | \"PREDICTIONS\" | \"RANDOM\" | \"SCORES\")",
                value: input.type
            });
        })()}}`;
        const $so7 = (input: any): any => `{${undefined === input.stopping_condition_minimum_distance ? "" : `"stopping_condition_minimum_distance":${undefined !== input.stopping_condition_minimum_distance ? input.stopping_condition_minimum_distance : undefined},`}${undefined === input.threshold ? "" : `"threshold":${undefined !== input.threshold ? input.threshold : undefined},`}${undefined === input.operation ? "" : `"operation":${undefined !== input.operation ? (() => {
            if ("string" === typeof input.operation)
                return $string(input.operation);
            if ("string" === typeof input.operation)
                return "\"" + input.operation + "\"";
            $throws({
                expected: "(\"BIGGER\" | \"BIGGER_EQUAL\" | \"SMALLER\" | \"SMALLER_EQUAL\" | undefined)",
                value: input.operation
            });
        })() : undefined},`}${undefined === input.target ? "" : `"target":${undefined !== input.target ? "{}" : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "(\"BALANCE\" | \"DIVERSITY\" | \"SIMILARITY\" | \"THRESHOLD\" | \"WEIGHTS\")",
                value: input.type
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerWorkerConfigCreateRequestValidatorShape = {
    'config': DockerWorkerConfigValidator.defined(),
    'creator': CreatorValidator.optional().default(undefined),
};
export const DockerWorkerConfigCreateRequestValidator = yup.object().shape(DockerWorkerConfigCreateRequestValidatorShape);
export const DockerWorkerConfigCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'config': DockerWorkerConfigStringifyShape,
    'creator': CreatorStringifyShape,
};
export const DockerWorkerConfigCreateRequestStringifyShape: ObjectSchema = {
    title: 'DockerWorkerConfigCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...DockerWorkerConfigCreateRequestStringifyShapeProperties
    },
    required: [
        'config',
    ],
};
export const DockerWorkerConfigCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerWorkerConfigCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerWorkerConfigCreateRequestStringify: (data: DockerWorkerConfigCreateRequest) => string = DockerWorkerConfigCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerWorkerConfigCreateRequestFromJSON(json: any): DockerWorkerConfigCreateRequest {
    return DockerWorkerConfigCreateRequestFromJSONTyped(json, false);
}
export function DockerWorkerConfigCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerWorkerConfigCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'config': DockerWorkerConfigFromJSON(json['config']),
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
    };
}
export function DockerWorkerConfigCreateRequestToJSON(value?: DockerWorkerConfigCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'config': DockerWorkerConfigToJSON(value.config),
        'creator': CreatorToJSON(value.creator),
    };
}
