/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { Creator } from './Creator';
import { CreatorFromJSON, CreatorFromJSONTyped, CreatorToJSON, } from './Creator';
import type { DockerRunScheduledPriority } from './DockerRunScheduledPriority';
import { DockerRunScheduledPriorityFromJSON, DockerRunScheduledPriorityFromJSONTyped, DockerRunScheduledPriorityToJSON, } from './DockerRunScheduledPriority';
import type { DockerWorkerLabels } from './DockerWorkerLabels';
import { DockerWorkerLabelsFromJSON, DockerWorkerLabelsFromJSONTyped, DockerWorkerLabelsToJSON, } from './DockerWorkerLabels';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface DockerRunScheduledCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_DockerRunScheduledCreateRequest_configId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface DockerRunScheduledCreateRequest {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof DockerRunScheduledCreateRequest
        
        
    * @pattern_DockerRunScheduledCreateRequest_configId
         */
    configId: string;
    /**
     *
     * #type DockerRunScheduledPriority
     * #memberof DockerRunScheduledCreateRequest
        
        
         */
    priority: DockerRunScheduledPriority;
    /**
     *
     * #type DockerWorkerLabels
     * #memberof DockerRunScheduledCreateRequest
        
        
         */
    runsOn?: DockerWorkerLabels;
    /**
     *
     * #type Creator
     * #memberof DockerRunScheduledCreateRequest
        
        
         */
    creator?: Creator;
}
/**
 * Check if a given object implements the DockerRunScheduledCreateRequest interface.
 */
export function instanceOfDockerRunScheduledCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "configId" in value;
    isInstance = isInstance && "priority" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { CreatorValidator, CreatorStringifyShape, CreatorStringifyShapeProperties, } from './Creator';
import { DockerRunScheduledPriorityValidator, DockerRunScheduledPriorityStringifyShape, DockerRunScheduledPriorityStringifyShapeProperties, } from './DockerRunScheduledPriority';
import { DockerWorkerLabelsValidator, DockerWorkerLabelsStringifyShape, DockerWorkerLabelsStringifyShapeProperties, } from './DockerWorkerLabels';
export const DockerRunScheduledCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): DockerRunScheduledCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is DockerRunScheduledCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.configId && $is_custom("pattern_DockerRunScheduledCreateRequest_configId", "string", "", input.configId) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority) && (undefined === input.runsOn || "object" === typeof input.runsOn && null !== input.runsOn && $io1(input.runsOn, true && _exceptionable)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["configId", "priority", "runsOn", "creator"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return false;
            });
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunScheduledCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.configId && ($is_custom("pattern_DockerRunScheduledCreateRequest_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_DockerRunScheduledCreateRequest_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string",
                    value: input.configId
                })) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                    value: input.priority
                })) && (undefined === input.runsOn || ("object" === typeof input.runsOn && null !== input.runsOn || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.runsOn
                })) && $ao1(input.runsOn, _path + ".runsOn", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.runsOn
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["configId", "priority", "runsOn", "creator"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const DockerRunScheduledCreateRequestTypiaAssertStringify = (input: DockerRunScheduledCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): DockerRunScheduledCreateRequest => {
        const __is = (input: any): input is DockerRunScheduledCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "string" === typeof input.configId && $is_custom("pattern_DockerRunScheduledCreateRequest_configId", "string", "", input.configId) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority) && (undefined === input.runsOn || "object" === typeof input.runsOn && null !== input.runsOn && $io1(input.runsOn)) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator);
            const $io1 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "string" === typeof value;
                return true;
            });
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is DockerRunScheduledCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.configId && ($is_custom("pattern_DockerRunScheduledCreateRequest_configId", "string", "", input.configId) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string (@pattern_DockerRunScheduledCreateRequest_configId)",
                    value: input.configId
                })) || $guard(_exceptionable, {
                    path: _path + ".configId",
                    expected: "string",
                    value: input.configId
                })) && ("LOW" === input.priority || "MID" === input.priority || "HIGH" === input.priority || "CRITICAL" === input.priority || $guard(_exceptionable, {
                    path: _path + ".priority",
                    expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                    value: input.priority
                })) && (undefined === input.runsOn || ("object" === typeof input.runsOn && null !== input.runsOn || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.runsOn
                })) && $ao1(input.runsOn, _path + ".runsOn", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".runsOn",
                    expected: "(DockerWorkerLabels | undefined)",
                    value: input.runsOn
                })) && (undefined === input.creator || "UNKNOWN" === input.creator || "USER_WEBAPP" === input.creator || "USER_PIP" === input.creator || "USER_PIP_LIGHTLY_MAGIC" === input.creator || "USER_WORKER" === input.creator || $guard(_exceptionable, {
                    path: _path + ".creator",
                    expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                    value: input.creator
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "string" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "string",
                            value: value
                        });
                    return true;
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "DockerRunScheduledCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: DockerRunScheduledCreateRequest): string => {
        const $io1 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "string" === typeof value;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.runsOn ? "" : `"runsOn":${undefined !== input.runsOn ? $so1(input.runsOn) : undefined},`}${undefined === input.creator ? "" : `"creator":${undefined !== input.creator ? (() => {
            if ("string" === typeof input.creator)
                return $string(input.creator);
            if ("string" === typeof input.creator)
                return "\"" + input.creator + "\"";
            $throws({
                expected: "(\"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\" | undefined)",
                value: input.creator
            });
        })() : undefined},`}"configId":${$string(input.configId)},"priority":${(() => {
            if ("string" === typeof input.priority)
                return $string(input.priority);
            if ("string" === typeof input.priority)
                return "\"" + input.priority + "\"";
            $throws({
                expected: "(\"CRITICAL\" | \"HIGH\" | \"LOW\" | \"MID\")",
                value: input.priority
            });
        })()}}`;
        const $so1 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$string(value)}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const DockerRunScheduledCreateRequestValidatorShape = {
    'configId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'priority': DockerRunScheduledPriorityValidator.defined(),
    'runsOn': DockerWorkerLabelsValidator.optional().default(undefined),
    'creator': CreatorValidator.optional().default(undefined),
};
export const DockerRunScheduledCreateRequestValidator = yup.object().shape(DockerRunScheduledCreateRequestValidatorShape);
export const DockerRunScheduledCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'configId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'priority': DockerRunScheduledPriorityStringifyShape,
    'runsOn': DockerWorkerLabelsStringifyShape,
    'creator': CreatorStringifyShape,
};
export const DockerRunScheduledCreateRequestStringifyShape: ObjectSchema = {
    title: 'DockerRunScheduledCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...DockerRunScheduledCreateRequestStringifyShapeProperties
    },
    required: [
        'configId',
        'priority',
    ],
};
export const DockerRunScheduledCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(DockerRunScheduledCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const DockerRunScheduledCreateRequestStringify: (data: DockerRunScheduledCreateRequest) => string = DockerRunScheduledCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function DockerRunScheduledCreateRequestFromJSON(json: any): DockerRunScheduledCreateRequest {
    return DockerRunScheduledCreateRequestFromJSONTyped(json, false);
}
export function DockerRunScheduledCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DockerRunScheduledCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'configId': json['configId'],
        'priority': DockerRunScheduledPriorityFromJSON(json['priority']),
        'runsOn': !exists(json, 'runsOn') ? undefined : DockerWorkerLabelsFromJSON(json['runsOn']),
        'creator': !exists(json, 'creator') ? undefined : CreatorFromJSON(json['creator']),
    };
}
export function DockerRunScheduledCreateRequestToJSON(value?: DockerRunScheduledCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'configId': value.configId,
        'priority': DockerRunScheduledPriorityToJSON(value.priority),
        'runsOn': DockerWorkerLabelsToJSON(value.runsOn),
        'creator': CreatorToJSON(value.creator),
    };
}
