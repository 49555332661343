// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, CreateSampleWithWriteUrlsResponse, SampleCreateRequest, SampleData, SampleDataModes, SamplePartialMode, SampleSortBy, SampleUpdateRequest, SampleWriteUrls, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, CreateSampleWithWriteUrlsResponseFromJSON, CreateSampleWithWriteUrlsResponseToJSON, CreateSampleWithWriteUrlsResponseValidator, SampleCreateRequestFromJSON, SampleCreateRequestToJSON, SampleCreateRequestValidator, SampleDataFromJSON, SampleDataToJSON, SampleDataValidator, SampleDataModesFromJSON, SampleDataModesToJSON, SampleDataModesValidator, SamplePartialModeFromJSON, SamplePartialModeToJSON, SamplePartialModeValidator, SampleSortByFromJSON, SampleSortByToJSON, SampleSortByValidator, SampleUpdateRequestFromJSON, SampleUpdateRequestToJSON, SampleUpdateRequestValidator, SampleWriteUrlsFromJSON, SampleWriteUrlsToJSON, SampleWriteUrlsValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/**
  * @export
  * @enum {string}
  */
export enum GetSampleImageReadUrlByIdTypeEnum {
    full = 'full',
    thumbnail = 'thumbnail'
}
/* LIGHTLY CUSTOM START ->  apiMustache */
export const GetSampleImageReadUrlByIdTypeEnumValidatorShape = [
    'full',
    'thumbnail'
];
export const GetSampleImageReadUrlByIdTypeEnumValidator = yup.mixed().oneOf(GetSampleImageReadUrlByIdTypeEnumValidatorShape);
/* LIGHTLY CUSTOM END ->  apiMustache */
/**
  * @export
  * @enum {string}
  */
export enum GetSampleImageResourceRedirectByIdTypeEnum {
    full = 'full',
    thumbnail = 'thumbnail'
}
/* LIGHTLY CUSTOM START ->  apiMustache */
export const GetSampleImageResourceRedirectByIdTypeEnumValidatorShape = [
    'full',
    'thumbnail'
];
export const GetSampleImageResourceRedirectByIdTypeEnumValidator = yup.mixed().oneOf(GetSampleImageResourceRedirectByIdTypeEnumValidatorShape);
/* LIGHTLY CUSTOM END ->  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateSampleByDatasetIdRequest {
    sampleCreateRequest: models.SampleCreateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateSampleByDatasetIdRequestValidatorShape = {
    'sampleCreateRequest': models.SampleCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateSampleByDatasetIdRequestValidator = yup.object().shape(CreateSampleByDatasetIdRequestValidatorShape);
export interface CreateSampleWithWriteUrlsByDatasetIdRequest {
    sampleCreateRequest: models.SampleCreateRequest;
    datasetId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateSampleWithWriteUrlsByDatasetIdRequestValidatorShape = {
    'sampleCreateRequest': models.SampleCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateSampleWithWriteUrlsByDatasetIdRequestValidator = yup.object().shape(CreateSampleWithWriteUrlsByDatasetIdRequestValidatorShape);
export interface GetSampleByIdRequest {
    datasetId: string;
    sampleId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleByIdRequestValidator = yup.object().shape(GetSampleByIdRequestValidatorShape);
export interface GetSampleImageReadUrlByIdRequest {
    datasetId: string;
    sampleId: string;
    type?: GetSampleImageReadUrlByIdTypeEnum;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleImageReadUrlByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'type': yup.string().oneOf(GetSampleImageReadUrlByIdTypeEnumValidatorShape),
};
export const GetSampleImageReadUrlByIdRequestValidator = yup.object().shape(GetSampleImageReadUrlByIdRequestValidatorShape);
export interface GetSampleImageResourceRedirectByIdRequest {
    datasetId: string;
    sampleId: string;
    type: GetSampleImageResourceRedirectByIdTypeEnum;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleImageResourceRedirectByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'type': yup.string().oneOf(GetSampleImageResourceRedirectByIdTypeEnumValidatorShape).defined(),
};
export const GetSampleImageResourceRedirectByIdRequestValidator = yup.object().shape(GetSampleImageResourceRedirectByIdRequestValidatorShape);
export interface GetSampleImageWriteUrlByIdRequest {
    datasetId: string;
    sampleId: string;
    isThumbnail: boolean;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleImageWriteUrlByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'isThumbnail': yup.boolean().defined(),
};
export const GetSampleImageWriteUrlByIdRequestValidator = yup.object().shape(GetSampleImageWriteUrlByIdRequestValidatorShape);
export interface GetSampleImageWriteUrlsByIdRequest {
    datasetId: string;
    sampleId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSampleImageWriteUrlsByIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleImageWriteUrlsByIdRequestValidator = yup.object().shape(GetSampleImageWriteUrlsByIdRequestValidatorShape);
export interface GetSamplesByDatasetIdRequest {
    datasetId: string;
    fileName?: string;
    sortBy?: models.SampleSortBy;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSamplesByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'fileName': yup.string(),
    'sortBy': models.SampleSortByValidator.optional().default(undefined),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSamplesByDatasetIdRequestValidator = yup.object().shape(GetSamplesByDatasetIdRequestValidatorShape);
export interface GetSamplesPartialByDatasetIdRequest {
    datasetId: string;
    mode?: models.SamplePartialMode;
    fileName?: string;
    pageSize?: number;
    pageOffset?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetSamplesPartialByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'mode': models.SamplePartialModeValidator.optional().default(undefined),
    'fileName': yup.string(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSamplesPartialByDatasetIdRequestValidator = yup.object().shape(GetSamplesPartialByDatasetIdRequestValidatorShape);
export interface UpdateSampleByIdRequest {
    sampleUpdateRequest: models.SampleUpdateRequest;
    datasetId: string;
    sampleId: string;
    enableDatasetUpdate?: boolean;
}
/* LIGHTLY CUSTOM START -> apis */
export const UpdateSampleByIdRequestValidatorShape = {
    'sampleUpdateRequest': models.SampleUpdateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'enableDatasetUpdate': yup.boolean(),
};
export const UpdateSampleByIdRequestValidator = yup.object().shape(UpdateSampleByIdRequestValidatorShape);
export interface CreateSampleByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateSampleByDatasetIdRequestParamsSanitizer(json: any): CreateSampleByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateSampleByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateSampleByDatasetIdRequestParamsValidator = yup.object().shape(CreateSampleByDatasetIdRequestParamsValidatorShape);
export interface CreateSampleWithWriteUrlsByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateSampleWithWriteUrlsByDatasetIdRequestParamsSanitizer(json: any): CreateSampleWithWriteUrlsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateSampleWithWriteUrlsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateSampleWithWriteUrlsByDatasetIdRequestParamsValidator = yup.object().shape(CreateSampleWithWriteUrlsByDatasetIdRequestParamsValidatorShape);
export interface GetSampleByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetSampleByIdRequestParamsSanitizer(json: any): GetSampleByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetSampleByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleByIdRequestParamsValidator = yup.object().shape(GetSampleByIdRequestParamsValidatorShape);
export interface GetSampleImageReadUrlByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetSampleImageReadUrlByIdRequestParamsSanitizer(json: any): GetSampleImageReadUrlByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetSampleImageReadUrlByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleImageReadUrlByIdRequestParamsValidator = yup.object().shape(GetSampleImageReadUrlByIdRequestParamsValidatorShape);
export interface GetSampleImageResourceRedirectByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetSampleImageResourceRedirectByIdRequestParamsSanitizer(json: any): GetSampleImageResourceRedirectByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetSampleImageResourceRedirectByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleImageResourceRedirectByIdRequestParamsValidator = yup.object().shape(GetSampleImageResourceRedirectByIdRequestParamsValidatorShape);
export interface GetSampleImageWriteUrlByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetSampleImageWriteUrlByIdRequestParamsSanitizer(json: any): GetSampleImageWriteUrlByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetSampleImageWriteUrlByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleImageWriteUrlByIdRequestParamsValidator = yup.object().shape(GetSampleImageWriteUrlByIdRequestParamsValidatorShape);
export interface GetSampleImageWriteUrlsByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetSampleImageWriteUrlsByIdRequestParamsSanitizer(json: any): GetSampleImageWriteUrlsByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetSampleImageWriteUrlsByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSampleImageWriteUrlsByIdRequestParamsValidator = yup.object().shape(GetSampleImageWriteUrlsByIdRequestParamsValidatorShape);
export interface GetSamplesByDatasetIdRequestParams {
    datasetId: string;
}
export function GetSamplesByDatasetIdRequestParamsSanitizer(json: any): GetSamplesByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetSamplesByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSamplesByDatasetIdRequestParamsValidator = yup.object().shape(GetSamplesByDatasetIdRequestParamsValidatorShape);
export interface GetSamplesPartialByDatasetIdRequestParams {
    datasetId: string;
}
export function GetSamplesPartialByDatasetIdRequestParamsSanitizer(json: any): GetSamplesPartialByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetSamplesPartialByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetSamplesPartialByDatasetIdRequestParamsValidator = yup.object().shape(GetSamplesPartialByDatasetIdRequestParamsValidatorShape);
export interface UpdateSampleByIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function UpdateSampleByIdRequestParamsSanitizer(json: any): UpdateSampleByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const UpdateSampleByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const UpdateSampleByIdRequestParamsValidator = yup.object().shape(UpdateSampleByIdRequestParamsValidatorShape);
export interface CreateSampleByDatasetIdRequestQuery {
}
export function CreateSampleByDatasetIdRequestQuerySanitizer(json: any): CreateSampleByDatasetIdRequestQuery {
    return {};
}
export const CreateSampleByDatasetIdRequestQueryValidatorShape = {};
export const CreateSampleByDatasetIdRequestQueryValidator = yup.object().shape(CreateSampleByDatasetIdRequestQueryValidatorShape);
export interface CreateSampleWithWriteUrlsByDatasetIdRequestQuery {
}
export function CreateSampleWithWriteUrlsByDatasetIdRequestQuerySanitizer(json: any): CreateSampleWithWriteUrlsByDatasetIdRequestQuery {
    return {};
}
export const CreateSampleWithWriteUrlsByDatasetIdRequestQueryValidatorShape = {};
export const CreateSampleWithWriteUrlsByDatasetIdRequestQueryValidator = yup.object().shape(CreateSampleWithWriteUrlsByDatasetIdRequestQueryValidatorShape);
export interface GetSampleByIdRequestQuery {
}
export function GetSampleByIdRequestQuerySanitizer(json: any): GetSampleByIdRequestQuery {
    return {};
}
export const GetSampleByIdRequestQueryValidatorShape = {};
export const GetSampleByIdRequestQueryValidator = yup.object().shape(GetSampleByIdRequestQueryValidatorShape);
export interface GetSampleImageReadUrlByIdRequestQuery {
    type?: GetSampleImageReadUrlByIdTypeEnum;
}
export function GetSampleImageReadUrlByIdRequestQuerySanitizer(json: any): GetSampleImageReadUrlByIdRequestQuery {
    return {
        'type': json['type'],
    };
}
export const GetSampleImageReadUrlByIdRequestQueryValidatorShape = {
    'type': yup.string().oneOf(GetSampleImageReadUrlByIdTypeEnumValidatorShape),
};
export const GetSampleImageReadUrlByIdRequestQueryValidator = yup.object().shape(GetSampleImageReadUrlByIdRequestQueryValidatorShape);
export interface GetSampleImageResourceRedirectByIdRequestQuery {
    type: GetSampleImageResourceRedirectByIdTypeEnum;
}
export function GetSampleImageResourceRedirectByIdRequestQuerySanitizer(json: any): GetSampleImageResourceRedirectByIdRequestQuery {
    return {
        'type': json['type'],
    };
}
export const GetSampleImageResourceRedirectByIdRequestQueryValidatorShape = {
    'type': yup.string().oneOf(GetSampleImageResourceRedirectByIdTypeEnumValidatorShape).defined(),
};
export const GetSampleImageResourceRedirectByIdRequestQueryValidator = yup.object().shape(GetSampleImageResourceRedirectByIdRequestQueryValidatorShape);
export interface GetSampleImageWriteUrlByIdRequestQuery {
    isThumbnail: boolean;
}
export function GetSampleImageWriteUrlByIdRequestQuerySanitizer(json: any): GetSampleImageWriteUrlByIdRequestQuery {
    return {
        'isThumbnail': json['isThumbnail'],
    };
}
export const GetSampleImageWriteUrlByIdRequestQueryValidatorShape = {
    'isThumbnail': yup.boolean().defined(),
};
export const GetSampleImageWriteUrlByIdRequestQueryValidator = yup.object().shape(GetSampleImageWriteUrlByIdRequestQueryValidatorShape);
export interface GetSampleImageWriteUrlsByIdRequestQuery {
}
export function GetSampleImageWriteUrlsByIdRequestQuerySanitizer(json: any): GetSampleImageWriteUrlsByIdRequestQuery {
    return {};
}
export const GetSampleImageWriteUrlsByIdRequestQueryValidatorShape = {};
export const GetSampleImageWriteUrlsByIdRequestQueryValidator = yup.object().shape(GetSampleImageWriteUrlsByIdRequestQueryValidatorShape);
export interface GetSamplesByDatasetIdRequestQuery {
    fileName?: string;
    sortBy?: models.SampleSortBy;
    pageSize?: number;
    pageOffset?: number;
}
export function GetSamplesByDatasetIdRequestQuerySanitizer(json: any): GetSamplesByDatasetIdRequestQuery {
    return {
        'fileName': json['fileName'],
        'sortBy': json['sortBy'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetSamplesByDatasetIdRequestQueryValidatorShape = {
    'fileName': yup.string(),
    'sortBy': models.SampleSortByValidator.optional().default(undefined),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSamplesByDatasetIdRequestQueryValidator = yup.object().shape(GetSamplesByDatasetIdRequestQueryValidatorShape);
export interface GetSamplesPartialByDatasetIdRequestQuery {
    mode?: models.SamplePartialMode;
    fileName?: string;
    pageSize?: number;
    pageOffset?: number;
}
export function GetSamplesPartialByDatasetIdRequestQuerySanitizer(json: any): GetSamplesPartialByDatasetIdRequestQuery {
    return {
        'mode': json['mode'],
        'fileName': json['fileName'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
    };
}
export const GetSamplesPartialByDatasetIdRequestQueryValidatorShape = {
    'mode': models.SamplePartialModeValidator.optional().default(undefined),
    'fileName': yup.string(),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
};
export const GetSamplesPartialByDatasetIdRequestQueryValidator = yup.object().shape(GetSamplesPartialByDatasetIdRequestQueryValidatorShape);
export interface UpdateSampleByIdRequestQuery {
    enableDatasetUpdate?: boolean;
}
export function UpdateSampleByIdRequestQuerySanitizer(json: any): UpdateSampleByIdRequestQuery {
    return {
        'enableDatasetUpdate': json['enableDatasetUpdate'],
    };
}
export const UpdateSampleByIdRequestQueryValidatorShape = {
    'enableDatasetUpdate': yup.boolean(),
};
export const UpdateSampleByIdRequestQueryValidator = yup.object().shape(UpdateSampleByIdRequestQueryValidatorShape);
export type CreateSampleByDatasetIdRequestBody = SampleCreateRequest;
export const CreateSampleByDatasetIdRequestBodySanitizer = SampleCreateRequestFromJSON;
export const CreateSampleByDatasetIdRequestBodyValidator = SampleCreateRequestValidator;
export const CreateSampleByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateSampleByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateSampleByDatasetIdRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif, true && _exceptionable)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData, true && _exceptionable)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData, true && _exceptionable)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData, true && _exceptionable)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom, true && _exceptionable)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic, true && _exceptionable)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any, _index3: number) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateSampleByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateSampleByDatasetIdTypiaAssertStringify = (input: CreateSampleByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateSampleByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateSampleByDatasetIdRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData));
            const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem)));
            const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex)) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp));
            const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateSampleByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateSampleByDatasetIdRequestBody): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem));
        const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp);
        const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.thumbName ? "" : `"thumbName":${undefined !== input.thumbName ? $string(input.thumbName) : undefined},`}${undefined === input.exif ? "" : `"exif":${undefined !== input.exif ? $so1(input.exif) : undefined},`}${undefined === input.metaData ? "" : `"metaData":${undefined !== input.metaData ? $so2(input.metaData) : undefined},`}${undefined === input.customMetaData ? "" : `"customMetaData":${undefined !== input.customMetaData ? null !== input.customMetaData ? $so5(input.customMetaData) : "null" : undefined},`}${undefined === input.videoFrameData ? "" : `"videoFrameData":${undefined !== input.videoFrameData ? null !== input.videoFrameData ? $so6(input.videoFrameData) : "null" : undefined},`}${undefined === input.cropData ? "" : `"cropData":${undefined !== input.cropData ? null !== input.cropData ? `{"parentId":${$string((input.cropData as any).parentId)},"predictionUUIDTimestamp":${(input.cropData as any).predictionUUIDTimestamp},"predictionIndex":${(input.cropData as any).predictionIndex},"predictionTaskName":${$string((input.cropData as any).predictionTaskName)},"predictionTaskCategoryId":${(input.cropData as any).predictionTaskCategoryId},"predictionTaskScore":${(input.cropData as any).predictionTaskScore}}` : "null" : undefined},`}"fileName":${$string(input.fileName)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.custom ? "" : `"custom":${undefined !== input.custom ? null !== input.custom ? $so3(input.custom) : "null" : undefined},`}${undefined === input.dynamic ? "" : `"dynamic":${undefined !== input.dynamic ? null !== input.dynamic ? $so4(input.dynamic) : "null" : undefined},`}${undefined === input.sharpness ? "" : `"sharpness":${undefined !== input.sharpness ? input.sharpness : undefined},`}${undefined === input.luminance ? "" : `"luminance":${undefined !== input.luminance ? input.luminance : undefined},`}${undefined === input.sizeInBytes ? "" : `"sizeInBytes":${undefined !== input.sizeInBytes ? input.sizeInBytes : undefined},`}${undefined === input.snr ? "" : `"snr":${undefined !== input.snr ? input.snr : undefined},`}${undefined === input.uniformRowRatio ? "" : `"uniformRowRatio":${undefined !== input.uniformRowRatio ? input.uniformRowRatio : undefined},`}${undefined === input.mean ? "" : `"mean":${undefined !== input.mean ? `[${input.mean.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.shape ? "" : `"shape":${undefined !== input.shape ? `[${input.shape.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.std ? "" : `"std":${undefined !== input.std ? `[${input.std.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfSquares ? "" : `"sumOfSquares":${undefined !== input.sumOfSquares ? `[${input.sumOfSquares.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfValues ? "" : `"sumOfValues":${undefined !== input.sumOfValues ? `[${input.sumOfValues.map((elem: any) => elem).join(",")}]` : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so4 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so5 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so6 = (input: any): any => `{${$tail(`${undefined === input.sourceVideo ? "" : `"sourceVideo":${undefined !== input.sourceVideo ? $string(input.sourceVideo) : undefined},`}${undefined === input.sourceVideoFrameIndex ? "" : `"sourceVideoFrameIndex":${undefined !== input.sourceVideoFrameIndex ? input.sourceVideoFrameIndex : undefined},`}${undefined === input.sourceVideoFrameTimestamp ? "" : `"sourceVideoFrameTimestamp":${undefined !== input.sourceVideoFrameTimestamp ? input.sourceVideoFrameTimestamp : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type CreateSampleWithWriteUrlsByDatasetIdRequestBody = SampleCreateRequest;
export const CreateSampleWithWriteUrlsByDatasetIdRequestBodySanitizer = SampleCreateRequestFromJSON;
export const CreateSampleWithWriteUrlsByDatasetIdRequestBodyValidator = SampleCreateRequestValidator;
export const CreateSampleWithWriteUrlsByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif, true && _exceptionable)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData, true && _exceptionable)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData, true && _exceptionable)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData, true && _exceptionable)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData, true && _exceptionable)) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom, true && _exceptionable)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic, true && _exceptionable)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any, _index3: number) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fileName", "thumbName", "exif", "metaData", "customMetaData", "videoFrameData", "cropData"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["sourceVideo", "sourceVideoFrameIndex", "sourceVideoFrameTimestamp"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateSampleWithWriteUrlsByDatasetIdTypiaAssertStringify = (input: CreateSampleWithWriteUrlsByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.fileName && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData)) && (null === input.videoFrameData || undefined === input.videoFrameData || "object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) && $io6(input.videoFrameData)) && (null === input.cropData || undefined === input.cropData || "object" === typeof input.cropData && null !== input.cropData && $io7(input.cropData));
            const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem)));
            const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex)) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp));
            const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateSampleWithWriteUrlsByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "string",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (null === input.videoFrameData || undefined === input.videoFrameData || ("object" === typeof input.videoFrameData && null !== input.videoFrameData && false === Array.isArray(input.videoFrameData) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && $ao6(input.videoFrameData, _path + ".videoFrameData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".videoFrameData",
                    expected: "(VideoFrameData | null | undefined)",
                    value: input.videoFrameData
                })) && (null === input.cropData || undefined === input.cropData || ("object" === typeof input.cropData && null !== input.cropData || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                })) && $ao7(input.cropData, _path + ".cropData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".cropData",
                    expected: "(CropData | null | undefined)",
                    value: input.cropData
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo || $guard(_exceptionable, {
                    path: _path + ".sourceVideo",
                    expected: "(string | undefined)",
                    value: input.sourceVideo
                })) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex && !Number.isNaN(input.sourceVideoFrameIndex) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameIndex",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameIndex
                })) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp && !Number.isNaN(input.sourceVideoFrameTimestamp) || $guard(_exceptionable, {
                    path: _path + ".sourceVideoFrameTimestamp",
                    expected: "(number | undefined)",
                    value: input.sourceVideoFrameTimestamp
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateSampleWithWriteUrlsByDatasetIdRequestBody): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem));
        const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io6 = (input: any): boolean => (undefined === input.sourceVideo || "string" === typeof input.sourceVideo) && (undefined === input.sourceVideoFrameIndex || "number" === typeof input.sourceVideoFrameIndex) && (undefined === input.sourceVideoFrameTimestamp || "number" === typeof input.sourceVideoFrameTimestamp);
        const $io7 = (input: any): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore);
        const $string = (typia.createAssertStringify as any).string;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.thumbName ? "" : `"thumbName":${undefined !== input.thumbName ? $string(input.thumbName) : undefined},`}${undefined === input.exif ? "" : `"exif":${undefined !== input.exif ? $so1(input.exif) : undefined},`}${undefined === input.metaData ? "" : `"metaData":${undefined !== input.metaData ? $so2(input.metaData) : undefined},`}${undefined === input.customMetaData ? "" : `"customMetaData":${undefined !== input.customMetaData ? null !== input.customMetaData ? $so5(input.customMetaData) : "null" : undefined},`}${undefined === input.videoFrameData ? "" : `"videoFrameData":${undefined !== input.videoFrameData ? null !== input.videoFrameData ? $so6(input.videoFrameData) : "null" : undefined},`}${undefined === input.cropData ? "" : `"cropData":${undefined !== input.cropData ? null !== input.cropData ? `{"parentId":${$string((input.cropData as any).parentId)},"predictionUUIDTimestamp":${(input.cropData as any).predictionUUIDTimestamp},"predictionIndex":${(input.cropData as any).predictionIndex},"predictionTaskName":${$string((input.cropData as any).predictionTaskName)},"predictionTaskCategoryId":${(input.cropData as any).predictionTaskCategoryId},"predictionTaskScore":${(input.cropData as any).predictionTaskScore}}` : "null" : undefined},`}"fileName":${$string(input.fileName)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.custom ? "" : `"custom":${undefined !== input.custom ? null !== input.custom ? $so3(input.custom) : "null" : undefined},`}${undefined === input.dynamic ? "" : `"dynamic":${undefined !== input.dynamic ? null !== input.dynamic ? $so4(input.dynamic) : "null" : undefined},`}${undefined === input.sharpness ? "" : `"sharpness":${undefined !== input.sharpness ? input.sharpness : undefined},`}${undefined === input.luminance ? "" : `"luminance":${undefined !== input.luminance ? input.luminance : undefined},`}${undefined === input.sizeInBytes ? "" : `"sizeInBytes":${undefined !== input.sizeInBytes ? input.sizeInBytes : undefined},`}${undefined === input.snr ? "" : `"snr":${undefined !== input.snr ? input.snr : undefined},`}${undefined === input.uniformRowRatio ? "" : `"uniformRowRatio":${undefined !== input.uniformRowRatio ? input.uniformRowRatio : undefined},`}${undefined === input.mean ? "" : `"mean":${undefined !== input.mean ? `[${input.mean.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.shape ? "" : `"shape":${undefined !== input.shape ? `[${input.shape.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.std ? "" : `"std":${undefined !== input.std ? `[${input.std.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfSquares ? "" : `"sumOfSquares":${undefined !== input.sumOfSquares ? `[${input.sumOfSquares.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfValues ? "" : `"sumOfValues":${undefined !== input.sumOfValues ? `[${input.sumOfValues.map((elem: any) => elem).join(",")}]` : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so4 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so5 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so6 = (input: any): any => `{${$tail(`${undefined === input.sourceVideo ? "" : `"sourceVideo":${undefined !== input.sourceVideo ? $string(input.sourceVideo) : undefined},`}${undefined === input.sourceVideoFrameIndex ? "" : `"sourceVideoFrameIndex":${undefined !== input.sourceVideoFrameIndex ? input.sourceVideoFrameIndex : undefined},`}${undefined === input.sourceVideoFrameTimestamp ? "" : `"sourceVideoFrameTimestamp":${undefined !== input.sourceVideoFrameTimestamp ? input.sourceVideoFrameTimestamp : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type UpdateSampleByIdRequestBody = SampleUpdateRequest;
export const UpdateSampleByIdRequestBodySanitizer = SampleUpdateRequestFromJSON;
export const UpdateSampleByIdRequestBodyValidator = SampleUpdateRequestValidator;
export const UpdateSampleByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): UpdateSampleByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is UpdateSampleByIdRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.fileName || "string" === typeof input.fileName) && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif, true && _exceptionable)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData, true && _exceptionable)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["fileName", "thumbName", "exif", "metaData", "customMetaData"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom, true && _exceptionable)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic, true && _exceptionable)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any, _index3: number) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return false;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateSampleByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.fileName || "string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "(string | undefined)",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["fileName", "thumbName", "exif", "metaData", "customMetaData"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["custom", "dynamic", "sharpness", "luminance", "sizeInBytes", "snr", "uniformRowRatio", "mean", "shape", "std", "sumOfSquares", "sumOfValues"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SampleUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const UpdateSampleByIdTypiaAssertStringify = (input: UpdateSampleByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): UpdateSampleByIdRequestBody => {
        const __is = (input: any): input is UpdateSampleByIdRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => (undefined === input.fileName || "string" === typeof input.fileName) && (undefined === input.thumbName || "string" === typeof input.thumbName) && (undefined === input.exif || "object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) && $io1(input.exif)) && (undefined === input.metaData || "object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) && $io2(input.metaData)) && (null === input.customMetaData || undefined === input.customMetaData || "object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) && $io5(input.customMetaData));
            const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem)));
            const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/(.*)/).test(key))
                    return true;
                return true;
            });
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is UpdateSampleByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.fileName || "string" === typeof input.fileName || $guard(_exceptionable, {
                    path: _path + ".fileName",
                    expected: "(string | undefined)",
                    value: input.fileName
                })) && (undefined === input.thumbName || "string" === typeof input.thumbName || $guard(_exceptionable, {
                    path: _path + ".thumbName",
                    expected: "(string | undefined)",
                    value: input.thumbName
                })) && (undefined === input.exif || ("object" === typeof input.exif && null !== input.exif && false === Array.isArray(input.exif) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && $ao1(input.exif, _path + ".exif", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".exif",
                    expected: "(__type | undefined)",
                    value: input.exif
                })) && (undefined === input.metaData || ("object" === typeof input.metaData && null !== input.metaData && false === Array.isArray(input.metaData) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && $ao2(input.metaData, _path + ".metaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".metaData",
                    expected: "(SampleMetaData | undefined)",
                    value: input.metaData
                })) && (null === input.customMetaData || undefined === input.customMetaData || ("object" === typeof input.customMetaData && null !== input.customMetaData && false === Array.isArray(input.customMetaData) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                })) && $ao5(input.customMetaData, _path + ".customMetaData", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".customMetaData",
                    expected: "(CustomSampleMetaData | null | undefined)",
                    value: input.customMetaData
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (null === input.custom || undefined === input.custom || ("object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && $ao3(input.custom, _path + ".custom", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".custom",
                    expected: "(__type.o1 | null | undefined)",
                    value: input.custom
                })) && (null === input.dynamic || undefined === input.dynamic || ("object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && $ao4(input.dynamic, _path + ".dynamic", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".dynamic",
                    expected: "(__type.o2 | null | undefined)",
                    value: input.dynamic
                })) && (undefined === input.sharpness || "number" === typeof input.sharpness && !Number.isNaN(input.sharpness) && (0 <= input.sharpness || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "number (@minimum 0)",
                    value: input.sharpness
                })) || $guard(_exceptionable, {
                    path: _path + ".sharpness",
                    expected: "(number | undefined)",
                    value: input.sharpness
                })) && (undefined === input.luminance || "number" === typeof input.luminance && (0 <= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@minimum 0)",
                    value: input.luminance
                })) && (100 >= input.luminance || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "number (@maximum 100)",
                    value: input.luminance
                })) || $guard(_exceptionable, {
                    path: _path + ".luminance",
                    expected: "(number | undefined)",
                    value: input.luminance
                })) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && !Number.isNaN(input.sizeInBytes) && (Math.floor(input.sizeInBytes) === input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@type int)",
                    value: input.sizeInBytes
                })) && (0 <= input.sizeInBytes || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "number (@minimum 0)",
                    value: input.sizeInBytes
                })) || $guard(_exceptionable, {
                    path: _path + ".sizeInBytes",
                    expected: "(number | undefined)",
                    value: input.sizeInBytes
                })) && (undefined === input.snr || "number" === typeof input.snr && !Number.isNaN(input.snr) && (0 <= input.snr || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "number (@minimum 0)",
                    value: input.snr
                })) || $guard(_exceptionable, {
                    path: _path + ".snr",
                    expected: "(number | undefined)",
                    value: input.snr
                })) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && (0 <= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@minimum 0)",
                    value: input.uniformRowRatio
                })) && (1 >= input.uniformRowRatio || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "number (@maximum 1)",
                    value: input.uniformRowRatio
                })) || $guard(_exceptionable, {
                    path: _path + ".uniformRowRatio",
                    expected: "(number | undefined)",
                    value: input.uniformRowRatio
                })) && (undefined === input.mean || (Array.isArray(input.mean) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && input.mean.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".mean[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".mean",
                    expected: "(Array<number> | undefined)",
                    value: input.mean
                })) && (undefined === input.shape || (Array.isArray(input.shape) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && input.shape.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".shape[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".shape",
                    expected: "(Array<number> | undefined)",
                    value: input.shape
                })) && (undefined === input.std || (Array.isArray(input.std) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && input.std.every((elem: any, _index3: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".std[" + _index3 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".std",
                    expected: "(Array<number> | undefined)",
                    value: input.std
                })) && (undefined === input.sumOfSquares || (Array.isArray(input.sumOfSquares) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && input.sumOfSquares.every((elem: any, _index4: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares[" + _index4 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfSquares",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfSquares
                })) && (undefined === input.sumOfValues || (Array.isArray(input.sumOfValues) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                })) && input.sumOfValues.every((elem: any, _index5: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues[" + _index5 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".sumOfValues",
                    expected: "(Array<number> | undefined)",
                    value: input.sumOfValues
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => false === _exceptionable || Object.keys(input).every((key: any) => {
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/(.*)/).test(key))
                        return true;
                    return true;
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "SampleUpdateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SampleUpdateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: UpdateSampleByIdRequestBody): string => {
        const $io1 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io2 = (input: any): boolean => (null === input.custom || undefined === input.custom || "object" === typeof input.custom && null !== input.custom && false === Array.isArray(input.custom) && $io3(input.custom)) && (null === input.dynamic || undefined === input.dynamic || "object" === typeof input.dynamic && null !== input.dynamic && false === Array.isArray(input.dynamic) && $io4(input.dynamic)) && (undefined === input.sharpness || "number" === typeof input.sharpness && 0 <= input.sharpness) && (undefined === input.luminance || "number" === typeof input.luminance && 0 <= input.luminance && 100 >= input.luminance) && (undefined === input.sizeInBytes || "number" === typeof input.sizeInBytes && Math.floor(input.sizeInBytes) === input.sizeInBytes && (-2147483648 <= input.sizeInBytes && input.sizeInBytes <= 2147483647) && 0 <= input.sizeInBytes) && (undefined === input.snr || "number" === typeof input.snr && 0 <= input.snr) && (undefined === input.uniformRowRatio || "number" === typeof input.uniformRowRatio && 0 <= input.uniformRowRatio && 1 >= input.uniformRowRatio) && (undefined === input.mean || Array.isArray(input.mean) && input.mean.every((elem: any) => "number" === typeof elem)) && (undefined === input.shape || Array.isArray(input.shape) && input.shape.every((elem: any) => "number" === typeof elem)) && (undefined === input.std || Array.isArray(input.std) && input.std.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfSquares || Array.isArray(input.sumOfSquares) && input.sumOfSquares.every((elem: any) => "number" === typeof elem)) && (undefined === input.sumOfValues || Array.isArray(input.sumOfValues) && input.sumOfValues.every((elem: any) => "number" === typeof elem));
        const $io3 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io4 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $io5 = (input: any): boolean => Object.keys(input).every((key: any) => {
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/(.*)/).test(key))
                return true;
            return true;
        });
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $join = (typia.createAssertStringify as any).join;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.fileName ? "" : `"fileName":${undefined !== input.fileName ? $string(input.fileName) : undefined},`}${undefined === input.thumbName ? "" : `"thumbName":${undefined !== input.thumbName ? $string(input.thumbName) : undefined},`}${undefined === input.exif ? "" : `"exif":${undefined !== input.exif ? $so1(input.exif) : undefined},`}${undefined === input.metaData ? "" : `"metaData":${undefined !== input.metaData ? $so2(input.metaData) : undefined},`}${undefined === input.customMetaData ? "" : `"customMetaData":${undefined !== input.customMetaData ? null !== input.customMetaData ? $so5(input.customMetaData) : "null" : undefined}`}`)}}`;
        const $so1 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.custom ? "" : `"custom":${undefined !== input.custom ? null !== input.custom ? $so3(input.custom) : "null" : undefined},`}${undefined === input.dynamic ? "" : `"dynamic":${undefined !== input.dynamic ? null !== input.dynamic ? $so4(input.dynamic) : "null" : undefined},`}${undefined === input.sharpness ? "" : `"sharpness":${undefined !== input.sharpness ? input.sharpness : undefined},`}${undefined === input.luminance ? "" : `"luminance":${undefined !== input.luminance ? input.luminance : undefined},`}${undefined === input.sizeInBytes ? "" : `"sizeInBytes":${undefined !== input.sizeInBytes ? input.sizeInBytes : undefined},`}${undefined === input.snr ? "" : `"snr":${undefined !== input.snr ? input.snr : undefined},`}${undefined === input.uniformRowRatio ? "" : `"uniformRowRatio":${undefined !== input.uniformRowRatio ? input.uniformRowRatio : undefined},`}${undefined === input.mean ? "" : `"mean":${undefined !== input.mean ? `[${input.mean.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.shape ? "" : `"shape":${undefined !== input.shape ? `[${input.shape.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.std ? "" : `"std":${undefined !== input.std ? `[${input.std.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfSquares ? "" : `"sumOfSquares":${undefined !== input.sumOfSquares ? `[${input.sumOfSquares.map((elem: any) => elem).join(",")}]` : undefined},`}${undefined === input.sumOfValues ? "" : `"sumOfValues":${undefined !== input.sumOfValues ? `[${input.sumOfValues.map((elem: any) => elem).join(",")}]` : undefined}`}`)}}`;
        const $so3 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so4 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        const $so5 = (input: any): any => `{${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; return `${JSON.stringify(key)}:${undefined !== value ? JSON.stringify(value) : undefined}`; }).filter((str: any) => "" !== str).join(",")}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/samples

export const createSampleByDatasetIdFN: RequestHandler<
    CreateSampleByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateSampleByDatasetIdRequestBody,
    CreateSampleByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateSampleByDatasetIdRequestBodySanitizer, CreateSampleByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateSampleByDatasetIdRequestParamsSanitizer, CreateSampleByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateSampleByDatasetIdRequestQuerySanitizer, CreateSampleByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/samples/withWriteUrls

export const createSampleWithWriteUrlsByDatasetIdFN: RequestHandler<
    CreateSampleWithWriteUrlsByDatasetIdRequestParams,
    CreateSampleWithWriteUrlsResponse | ApiErrorResponse,
    CreateSampleWithWriteUrlsByDatasetIdRequestBody,
    CreateSampleWithWriteUrlsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateSampleWithWriteUrlsByDatasetIdRequestBodySanitizer, CreateSampleWithWriteUrlsByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
    } =  await validateAndSanitize(req.params, CreateSampleWithWriteUrlsByDatasetIdRequestParamsSanitizer, CreateSampleWithWriteUrlsByDatasetIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, CreateSampleWithWriteUrlsByDatasetIdRequestQuerySanitizer, CreateSampleWithWriteUrlsByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateSampleWithWriteUrlsResponseStringify,
        validateAndRespond({ id, ...rest }, CreateSampleWithWriteUrlsResponseFromJSON, CreateSampleWithWriteUrlsResponseValidator),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}

export const getSampleByIdFN: RequestHandler<
    GetSampleByIdRequestParams,
    SampleData | ApiErrorResponse,
    GetSampleByIdRequestBody,
    GetSampleByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleByIdRequestBodySanitizer, GetSampleByIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
    } =  await validateAndSanitize(req.params, GetSampleByIdRequestParamsSanitizer, GetSampleByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetSampleByIdRequestQuerySanitizer, GetSampleByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        SampleDataStringify,
        validateAndRespond({ id, ...rest }, SampleDataFromJSON, SampleDataValidator),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}/readurl

export const getSampleImageReadUrlByIdFN: RequestHandler<
    GetSampleImageReadUrlByIdRequestParams,
    string | ApiErrorResponse,
    GetSampleImageReadUrlByIdRequestBody,
    GetSampleImageReadUrlByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleImageReadUrlByIdRequestBodySanitizer, GetSampleImageReadUrlByIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, GetSampleImageReadUrlByIdRequestParamsSanitizer, GetSampleImageReadUrlByIdRequestParamsValidator);
    const {
        
        
        type,
    } = await validateAndSanitize(req.query, GetSampleImageReadUrlByIdRequestQuerySanitizer, GetSampleImageReadUrlByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}/readurlRedirect

export const getSampleImageResourceRedirectByIdFN: RequestHandler<
    GetSampleImageResourceRedirectByIdRequestParams,
     | ApiErrorResponse,
    GetSampleImageResourceRedirectByIdRequestBody,
    GetSampleImageResourceRedirectByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleImageResourceRedirectByIdRequestBodySanitizer, GetSampleImageResourceRedirectByIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, GetSampleImageResourceRedirectByIdRequestParamsSanitizer, GetSampleImageResourceRedirectByIdRequestParamsValidator);
    const {
        
        
        type,
    } = await validateAndSanitize(req.query, GetSampleImageResourceRedirectByIdRequestQuerySanitizer, GetSampleImageResourceRedirectByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}/writeurl

export const getSampleImageWriteUrlByIdFN: RequestHandler<
    GetSampleImageWriteUrlByIdRequestParams,
    string | ApiErrorResponse,
    GetSampleImageWriteUrlByIdRequestBody,
    GetSampleImageWriteUrlByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleImageWriteUrlByIdRequestBodySanitizer, GetSampleImageWriteUrlByIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, GetSampleImageWriteUrlByIdRequestParamsSanitizer, GetSampleImageWriteUrlByIdRequestParamsValidator);
    const {
        
        
        isThumbnail,
    } = await validateAndSanitize(req.query, GetSampleImageWriteUrlByIdRequestQuerySanitizer, GetSampleImageWriteUrlByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        stringStringify,
        validateAndRespond({ id, ...rest }, stringFromJSON, stringValidator),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}/writeurls

export const getSampleImageWriteUrlsByIdFN: RequestHandler<
    GetSampleImageWriteUrlsByIdRequestParams,
    SampleWriteUrls | ApiErrorResponse,
    GetSampleImageWriteUrlsByIdRequestBody,
    GetSampleImageWriteUrlsByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSampleImageWriteUrlsByIdRequestBodySanitizer, GetSampleImageWriteUrlsByIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
    } =  await validateAndSanitize(req.params, GetSampleImageWriteUrlsByIdRequestParamsSanitizer, GetSampleImageWriteUrlsByIdRequestParamsValidator);
    const {
        
        
    } = await validateAndSanitize(req.query, GetSampleImageWriteUrlsByIdRequestQuerySanitizer, GetSampleImageWriteUrlsByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        SampleWriteUrlsStringify,
        validateAndRespond({ id, ...rest }, SampleWriteUrlsFromJSON, SampleWriteUrlsValidator),
    );
}



/v1/datasets/{datasetId}/samples
const SampleDataArrayStringify = fastJSONArrayify(SampleDataStringifyShape);
export const getSamplesByDatasetIdFN: RequestHandler<
    GetSamplesByDatasetIdRequestParams,
    SampleData[] | ApiErrorResponse,
    GetSamplesByDatasetIdRequestBody,
    GetSamplesByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSamplesByDatasetIdRequestBodySanitizer, GetSamplesByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
        
        
        
    } =  await validateAndSanitize(req.params, GetSamplesByDatasetIdRequestParamsSanitizer, GetSamplesByDatasetIdRequestParamsValidator);
    const {
        
        fileName,
        sortBy,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetSamplesByDatasetIdRequestQuerySanitizer, GetSamplesByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        SampleDataArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, SampleDataFromJSON, SampleDataValidator);
        }),
    );
}



/v1/datasets/{datasetId}/samples/partial
const SampleDataModesArrayStringify = fastJSONArrayify(SampleDataModesStringifyShape);
export const getSamplesPartialByDatasetIdFN: RequestHandler<
    GetSamplesPartialByDatasetIdRequestParams,
    SampleDataModes[] | ApiErrorResponse,
    GetSamplesPartialByDatasetIdRequestBody,
    GetSamplesPartialByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetSamplesPartialByDatasetIdRequestBodySanitizer, GetSamplesPartialByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
        
        
        
    } =  await validateAndSanitize(req.params, GetSamplesPartialByDatasetIdRequestParamsSanitizer, GetSamplesPartialByDatasetIdRequestParamsValidator);
    const {
        
        mode,
        fileName,
        pageSize,
        pageOffset,
    } = await validateAndSanitize(req.query, GetSamplesPartialByDatasetIdRequestQuerySanitizer, GetSamplesPartialByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        SampleDataModesArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, SampleDataModesFromJSON, SampleDataModesValidator);
        }),
    );
}



/v1/datasets/{datasetId}/samples/{sampleId}

export const updateSampleByIdFN: RequestHandler<
    UpdateSampleByIdRequestParams,
     | ApiErrorResponse,
    UpdateSampleByIdRequestBody,
    UpdateSampleByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, UpdateSampleByIdRequestBodySanitizer, UpdateSampleByIdRequestBodyValidator);
    const {
        
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, UpdateSampleByIdRequestParamsSanitizer, UpdateSampleByIdRequestParamsValidator);
    const {
        
        
        
        enableDatasetUpdate,
    } = await validateAndSanitize(req.query, UpdateSampleByIdRequestQuerySanitizer, UpdateSampleByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        Stringify,
        validateAndRespond({ id, ...rest }, FromJSON, Validator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * SamplesApi - interface
 *
 * @export
 * @interface SamplesApiInterface
 */
export interface SamplesApiInterface {
    /**
     * Create a new sample in a dataset
     * @param {SampleCreateRequest} sampleCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    createSampleByDatasetIdRaw(requestParameters: CreateSampleByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create a new sample in a dataset
     */
    createSampleByDatasetId(requestParameters: CreateSampleByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Create a sample and immediately receive write URLs (full image and thumbnail) to upload images
     * @param {SampleCreateRequest} sampleCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    createSampleWithWriteUrlsByDatasetIdRaw(requestParameters: CreateSampleWithWriteUrlsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSampleWithWriteUrlsResponse>>;
    /**
     * Create a sample and immediately receive write URLs (full image and thumbnail) to upload images
     */
    createSampleWithWriteUrlsByDatasetId(requestParameters: CreateSampleWithWriteUrlsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSampleWithWriteUrlsResponse>;
    /**
     * Get a specific sample of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSampleByIdRaw(requestParameters: GetSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SampleData>>;
    /**
     * Get a specific sample of a dataset
     */
    getSampleById(requestParameters: GetSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SampleData>;
    /**
     * Get the image path of a specific sample of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {'full' | 'thumbnail'} [type] if we want to get the full image or just the thumbnail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSampleImageReadUrlByIdRaw(requestParameters: GetSampleImageReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Get the image path of a specific sample of a dataset
     */
    getSampleImageReadUrlById(requestParameters: GetSampleImageReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * This endpoint enables anyone given the correct credentials to access the actual image directly. By creating a readURL for the resource and redirecting to that URL, the client can use this endpoint to always have a way to access the resource as there is no expiration
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {'full' | 'thumbnail'} type if we want to get the full image or just the thumbnail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSampleImageResourceRedirectByIdRaw(requestParameters: GetSampleImageResourceRedirectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * This endpoint enables anyone given the correct credentials to access the actual image directly. By creating a readURL for the resource and redirecting to that URL, the client can use this endpoint to always have a way to access the resource as there is no expiration
     */
    getSampleImageResourceRedirectById(requestParameters: GetSampleImageResourceRedirectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
    /**
     * Get the signed url to upload an image to for a specific sample of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {boolean} isThumbnail Whether or not the image to upload is a thumbnail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSampleImageWriteUrlByIdRaw(requestParameters: GetSampleImageWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;
    /**
     * Get the signed url to upload an image to for a specific sample of a dataset
     */
    getSampleImageWriteUrlById(requestParameters: GetSampleImageWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;
    /**
     * Get all signed write URLs to upload all images (full image and thumbnail) of a specific sample of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSampleImageWriteUrlsByIdRaw(requestParameters: GetSampleImageWriteUrlsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SampleWriteUrls>>;
    /**
     * Get all signed write URLs to upload all images (full image and thumbnail) of a specific sample of a dataset
     */
    getSampleImageWriteUrlsById(requestParameters: GetSampleImageWriteUrlsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SampleWriteUrls>;
    /**
     * Get all samples of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} [fileName] DEPRECATED, use without and filter yourself - Filter the samples by filename
     * @param {SampleSortBy} [sortBy] sort the samples
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSamplesByDatasetIdRaw(requestParameters: GetSamplesByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SampleData>>>;
    /**
     * Get all samples of a dataset
     */
    getSamplesByDatasetId(requestParameters: GetSamplesByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SampleData>>;
    /**
     * Get partial information of all samples of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {SamplePartialMode} [mode]
     * @param {string} [fileName] filter the samples by filename
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    getSamplesPartialByDatasetIdRaw(requestParameters: GetSamplesPartialByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SampleDataModes>>>;
    /**
     * Get partial information of all samples of a dataset
     */
    getSamplesPartialByDatasetId(requestParameters: GetSamplesPartialByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SampleDataModes>>;
    /**
     * update a specific sample of a dataset
     * @param {SampleUpdateRequest} sampleUpdateRequest The updated sample to set
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {boolean} [enableDatasetUpdate] Deprecated, is ignored! If we should also update the dataset with the new sample information (like total size)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplesApiInterface
     */
    updateSampleByIdRaw(requestParameters: UpdateSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;
    /**
     * update a specific sample of a dataset
     */
    updateSampleById(requestParameters: UpdateSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;
}
/**
 *
 */
export class SamplesApi extends runtime.BaseAPI implements SamplesApiInterface {
    /**
     * Create a new sample in a dataset
     */
    async createSampleByDatasetIdRaw(requestParameters: CreateSampleByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.sampleCreateRequest === null || requestParameters.sampleCreateRequest === undefined) {
            throw new runtime.RequiredError('sampleCreateRequest', 'Required parameter requestParameters.sampleCreateRequest was null or undefined when calling createSampleByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createSampleByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SampleCreateRequestToJSON(requestParameters.sampleCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create a new sample in a dataset
     */
    async createSampleByDatasetId(requestParameters: CreateSampleByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createSampleByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Create a sample and immediately receive write URLs (full image and thumbnail) to upload images
     */
    async createSampleWithWriteUrlsByDatasetIdRaw(requestParameters: CreateSampleWithWriteUrlsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateSampleWithWriteUrlsResponse>> {
        if (requestParameters.sampleCreateRequest === null || requestParameters.sampleCreateRequest === undefined) {
            throw new runtime.RequiredError('sampleCreateRequest', 'Required parameter requestParameters.sampleCreateRequest was null or undefined when calling createSampleWithWriteUrlsByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createSampleWithWriteUrlsByDatasetId.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/withWriteUrls`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SampleCreateRequestToJSON(requestParameters.sampleCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateSampleWithWriteUrlsResponseFromJSON(jsonValue));
    }
    /**
     * Create a sample and immediately receive write URLs (full image and thumbnail) to upload images
     */
    async createSampleWithWriteUrlsByDatasetId(requestParameters: CreateSampleWithWriteUrlsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateSampleWithWriteUrlsResponse> {
        const response = await this.createSampleWithWriteUrlsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get a specific sample of a dataset
     */
    async getSampleByIdRaw(requestParameters: GetSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SampleData>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getSampleById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SampleDataFromJSON(jsonValue));
    }
    /**
     * Get a specific sample of a dataset
     */
    async getSampleById(requestParameters: GetSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SampleData> {
        const response = await this.getSampleByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get the image path of a specific sample of a dataset
     */
    async getSampleImageReadUrlByIdRaw(requestParameters: GetSampleImageReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleImageReadUrlById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getSampleImageReadUrlById.');
        }
        const queryParameters: any = {};
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}/readurl`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Get the image path of a specific sample of a dataset
     */
    async getSampleImageReadUrlById(requestParameters: GetSampleImageReadUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSampleImageReadUrlByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * This endpoint enables anyone given the correct credentials to access the actual image directly. By creating a readURL for the resource and redirecting to that URL, the client can use this endpoint to always have a way to access the resource as there is no expiration
     */
    async getSampleImageResourceRedirectByIdRaw(requestParameters: GetSampleImageResourceRedirectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleImageResourceRedirectById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getSampleImageResourceRedirectById.');
        }
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling getSampleImageResourceRedirectById.');
        }
        const queryParameters: any = {};
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["publicToken"] = this.configuration.apiKey("publicToken"); // ApiPublicJWTAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}/readurlRedirect`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * This endpoint enables anyone given the correct credentials to access the actual image directly. By creating a readURL for the resource and redirecting to that URL, the client can use this endpoint to always have a way to access the resource as there is no expiration
     */
    async getSampleImageResourceRedirectById(requestParameters: GetSampleImageResourceRedirectByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getSampleImageResourceRedirectByIdRaw(requestParameters, initOverrides);
    }
    /**
     * Get the signed url to upload an image to for a specific sample of a dataset
     */
    async getSampleImageWriteUrlByIdRaw(requestParameters: GetSampleImageWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleImageWriteUrlById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getSampleImageWriteUrlById.');
        }
        if (requestParameters.isThumbnail === null || requestParameters.isThumbnail === undefined) {
            throw new runtime.RequiredError('isThumbnail', 'Required parameter requestParameters.isThumbnail was null or undefined when calling getSampleImageWriteUrlById.');
        }
        const queryParameters: any = {};
        if (requestParameters.isThumbnail !== undefined) {
            queryParameters['isThumbnail'] = requestParameters.isThumbnail;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}/writeurl`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Get the signed url to upload an image to for a specific sample of a dataset
     */
    async getSampleImageWriteUrlById(requestParameters: GetSampleImageWriteUrlByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getSampleImageWriteUrlByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all signed write URLs to upload all images (full image and thumbnail) of a specific sample of a dataset
     */
    async getSampleImageWriteUrlsByIdRaw(requestParameters: GetSampleImageWriteUrlsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SampleWriteUrls>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSampleImageWriteUrlsById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getSampleImageWriteUrlsById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}/writeurls`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SampleWriteUrlsFromJSON(jsonValue));
    }
    /**
     * Get all signed write URLs to upload all images (full image and thumbnail) of a specific sample of a dataset
     */
    async getSampleImageWriteUrlsById(requestParameters: GetSampleImageWriteUrlsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SampleWriteUrls> {
        const response = await this.getSampleImageWriteUrlsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all samples of a dataset
     */
    async getSamplesByDatasetIdRaw(requestParameters: GetSamplesByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SampleData>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSamplesByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }
        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SampleDataFromJSON));
    }
    /**
     * Get all samples of a dataset
     */
    async getSamplesByDatasetId(requestParameters: GetSamplesByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SampleData>> {
        const response = await this.getSamplesByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get partial information of all samples of a dataset
     */
    async getSamplesPartialByDatasetIdRaw(requestParameters: GetSamplesPartialByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SampleDataModes>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getSamplesPartialByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }
        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/partial`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SampleDataModesFromJSON));
    }
    /**
     * Get partial information of all samples of a dataset
     */
    async getSamplesPartialByDatasetId(requestParameters: GetSamplesPartialByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SampleDataModes>> {
        const response = await this.getSamplesPartialByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * update a specific sample of a dataset
     */
    async updateSampleByIdRaw(requestParameters: UpdateSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sampleUpdateRequest === null || requestParameters.sampleUpdateRequest === undefined) {
            throw new runtime.RequiredError('sampleUpdateRequest', 'Required parameter requestParameters.sampleUpdateRequest was null or undefined when calling updateSampleById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling updateSampleById.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling updateSampleById.');
        }
        const queryParameters: any = {};
        if (requestParameters.enableDatasetUpdate !== undefined) {
            queryParameters['enableDatasetUpdate'] = requestParameters.enableDatasetUpdate;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/samples/{sampleId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SampleUpdateRequestToJSON(requestParameters.sampleUpdateRequest),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * update a specific sample of a dataset
     */
    async updateSampleById(requestParameters: UpdateSampleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSampleByIdRaw(requestParameters, initOverrides);
    }
}
