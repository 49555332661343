// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, CreateEntityResponse, PredictionSingletons, PredictionTaskSchema, PredictionTaskSchemas, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, CreateEntityResponseFromJSON, CreateEntityResponseToJSON, CreateEntityResponseValidator, PredictionSingletonsFromJSON, PredictionSingletonsToJSON, PredictionSingletonsValidator, PredictionTaskSchemaFromJSON, PredictionTaskSchemaToJSON, PredictionTaskSchemaValidator, PredictionTaskSchemasFromJSON, PredictionTaskSchemasToJSON, PredictionTaskSchemasValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface CreateOrUpdatePredictionBySampleIdRequest {
    predictionSingletons: models.PredictionSingletons;
    datasetId: string;
    sampleId: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateOrUpdatePredictionBySampleIdRequestValidatorShape = {
    'predictionSingletons': models.PredictionSingletonsValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdatePredictionBySampleIdRequestValidator = yup.object().shape(CreateOrUpdatePredictionBySampleIdRequestValidatorShape);
export interface CreateOrUpdatePredictionTaskSchemaByDatasetIdRequest {
    predictionTaskSchema: models.PredictionTaskSchema;
    datasetId: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestValidatorShape = {
    'predictionTaskSchema': models.PredictionTaskSchemaValidator,
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestValidator = yup.object().shape(CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestValidatorShape);
export interface GetPredictionTaskSchemaByTaskNameRequest {
    datasetId: string;
    taskName: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetPredictionTaskSchemaByTaskNameRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionTaskSchemaByTaskNameRequestValidator = yup.object().shape(GetPredictionTaskSchemaByTaskNameRequestValidatorShape);
export interface GetPredictionTaskSchemasByDatasetIdRequest {
    datasetId: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetPredictionTaskSchemasByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionTaskSchemasByDatasetIdRequestValidator = yup.object().shape(GetPredictionTaskSchemasByDatasetIdRequestValidatorShape);
export interface GetPredictionsByDatasetIdRequest {
    datasetId: string;
    predictionUUIDTimestamp?: number;
    pageSize?: number;
    pageOffset?: number;
    lean?: boolean;
    taskName?: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetPredictionsByDatasetIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
    'lean': yup.boolean(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/),
};
export const GetPredictionsByDatasetIdRequestValidator = yup.object().shape(GetPredictionsByDatasetIdRequestValidatorShape);
export interface GetPredictionsBySampleIdRequest {
    datasetId: string;
    sampleId: string;
    predictionUUIDTimestamp?: number;
}
/* LIGHTLY CUSTOM START -> apis */
export const GetPredictionsBySampleIdRequestValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionsBySampleIdRequestValidator = yup.object().shape(GetPredictionsBySampleIdRequestValidatorShape);
export interface CreateOrUpdatePredictionBySampleIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function CreateOrUpdatePredictionBySampleIdRequestParamsSanitizer(json: any): CreateOrUpdatePredictionBySampleIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const CreateOrUpdatePredictionBySampleIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdatePredictionBySampleIdRequestParamsValidator = yup.object().shape(CreateOrUpdatePredictionBySampleIdRequestParamsValidatorShape);
export interface CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParams {
    datasetId: string;
}
export function CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsSanitizer(json: any): CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsValidator = yup.object().shape(CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsValidatorShape);
export interface GetPredictionTaskSchemaByTaskNameRequestParams {
    datasetId: string;
    taskName: string;
}
export function GetPredictionTaskSchemaByTaskNameRequestParamsSanitizer(json: any): GetPredictionTaskSchemaByTaskNameRequestParams {
    return {
        'datasetId': json['datasetId'],
        'taskName': json['taskName'],
    };
}
export const GetPredictionTaskSchemaByTaskNameRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
};
export const GetPredictionTaskSchemaByTaskNameRequestParamsValidator = yup.object().shape(GetPredictionTaskSchemaByTaskNameRequestParamsValidatorShape);
export interface GetPredictionTaskSchemasByDatasetIdRequestParams {
    datasetId: string;
}
export function GetPredictionTaskSchemasByDatasetIdRequestParamsSanitizer(json: any): GetPredictionTaskSchemasByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetPredictionTaskSchemasByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetPredictionTaskSchemasByDatasetIdRequestParamsValidator = yup.object().shape(GetPredictionTaskSchemasByDatasetIdRequestParamsValidatorShape);
export interface GetPredictionsByDatasetIdRequestParams {
    datasetId: string;
}
export function GetPredictionsByDatasetIdRequestParamsSanitizer(json: any): GetPredictionsByDatasetIdRequestParams {
    return {
        'datasetId': json['datasetId'],
    };
}
export const GetPredictionsByDatasetIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetPredictionsByDatasetIdRequestParamsValidator = yup.object().shape(GetPredictionsByDatasetIdRequestParamsValidatorShape);
export interface GetPredictionsBySampleIdRequestParams {
    datasetId: string;
    sampleId: string;
}
export function GetPredictionsBySampleIdRequestParamsSanitizer(json: any): GetPredictionsBySampleIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'sampleId': json['sampleId'],
    };
}
export const GetPredictionsBySampleIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'sampleId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const GetPredictionsBySampleIdRequestParamsValidator = yup.object().shape(GetPredictionsBySampleIdRequestParamsValidatorShape);
export interface CreateOrUpdatePredictionBySampleIdRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function CreateOrUpdatePredictionBySampleIdRequestQuerySanitizer(json: any): CreateOrUpdatePredictionBySampleIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const CreateOrUpdatePredictionBySampleIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdatePredictionBySampleIdRequestQueryValidator = yup.object().shape(CreateOrUpdatePredictionBySampleIdRequestQueryValidatorShape);
export interface CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQuerySanitizer(json: any): CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQueryValidator = yup.object().shape(CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQueryValidatorShape);
export interface GetPredictionTaskSchemaByTaskNameRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function GetPredictionTaskSchemaByTaskNameRequestQuerySanitizer(json: any): GetPredictionTaskSchemaByTaskNameRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const GetPredictionTaskSchemaByTaskNameRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionTaskSchemaByTaskNameRequestQueryValidator = yup.object().shape(GetPredictionTaskSchemaByTaskNameRequestQueryValidatorShape);
export interface GetPredictionTaskSchemasByDatasetIdRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function GetPredictionTaskSchemasByDatasetIdRequestQuerySanitizer(json: any): GetPredictionTaskSchemasByDatasetIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const GetPredictionTaskSchemasByDatasetIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionTaskSchemasByDatasetIdRequestQueryValidator = yup.object().shape(GetPredictionTaskSchemasByDatasetIdRequestQueryValidatorShape);
export interface GetPredictionsByDatasetIdRequestQuery {
    predictionUUIDTimestamp?: number;
    pageSize?: number;
    pageOffset?: number;
    lean?: boolean;
    taskName?: string;
}
export function GetPredictionsByDatasetIdRequestQuerySanitizer(json: any): GetPredictionsByDatasetIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
        'pageSize': json['pageSize'],
        'pageOffset': json['pageOffset'],
        'lean': json['lean'],
        'taskName': json['taskName'],
    };
}
export const GetPredictionsByDatasetIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
    'pageSize': yup.number().min(1),
    'pageOffset': yup.number().min(0),
    'lean': yup.boolean(),
    'taskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/),
};
export const GetPredictionsByDatasetIdRequestQueryValidator = yup.object().shape(GetPredictionsByDatasetIdRequestQueryValidatorShape);
export interface GetPredictionsBySampleIdRequestQuery {
    predictionUUIDTimestamp?: number;
}
export function GetPredictionsBySampleIdRequestQuerySanitizer(json: any): GetPredictionsBySampleIdRequestQuery {
    return {
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
    };
}
export const GetPredictionsBySampleIdRequestQueryValidatorShape = {
    'predictionUUIDTimestamp': yup.number().min(0),
};
export const GetPredictionsBySampleIdRequestQueryValidator = yup.object().shape(GetPredictionsBySampleIdRequestQueryValidatorShape);
export type CreateOrUpdatePredictionBySampleIdRequestBody = PredictionSingletons;
export const CreateOrUpdatePredictionBySampleIdRequestBodySanitizer = PredictionSingletonsFromJSON;
export const CreateOrUpdatePredictionBySampleIdRequestBodyValidator = PredictionSingletonsValidator;
export const CreateOrUpdatePredictionBySampleIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateOrUpdatePredictionBySampleIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateOrUpdatePredictionBySampleIdRequestBody => {
            const $join = (typia.createAssertEquals as any).join;
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $iu0(value, true && _exceptionable);
                return false;
            });
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (4 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io10 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io11 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io12 = (input: any, _exceptionable: boolean = true): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io13 = (input: any, _exceptionable: boolean = true): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation, true && _exceptionable)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities, true && _exceptionable)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io1(input, true && _exceptionable);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input, true && _exceptionable);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io6(input, true && _exceptionable);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input, true && _exceptionable);
                if ("PredictionSingletonClassification" === input.type)
                    return $io8(input, true && _exceptionable);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io9(input, true && _exceptionable);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io10(input, true && _exceptionable);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io11(input, true && _exceptionable);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io13(input, true && _exceptionable);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io12(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdatePredictionBySampleIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $join = (typia.createAssertEquals as any).join;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        })) && $au0(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (4 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "segmentation", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "keypoints", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "bbox", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao13 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "segmentation", "probabilities", "taskName", "cropDatasetId", "cropSampleId", "categoryId", "score"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao13(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateOrUpdatePredictionBySampleIdTypiaAssertStringify = (input: CreateOrUpdatePredictionBySampleIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateOrUpdatePredictionBySampleIdRequestBody => {
        const __is = (input: any): input is CreateOrUpdatePredictionBySampleIdRequestBody => {
            const $join = (typia.createAssertStringify as any).join;
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "object" === typeof value && null !== value && $iu0(value);
                return true;
            });
            const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io2 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io4 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io5 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io6 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io8 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io9 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io10 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem && !Number.isNaN(elem))) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io11 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io12 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $io13 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
            const $iu0 = (input: any): any => (() => {
                if ("CLASSIFICATION" === input.type)
                    return $io1(input);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io3(input);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io6(input);
                if ("OBJECT_DETECTION" === input.type)
                    return $io7(input);
                if ("PredictionSingletonClassification" === input.type)
                    return $io8(input);
                if ("PredictionSingletonInstanceSegmentation" === input.type)
                    return $io9(input);
                if ("PredictionSingletonKeypointDetection" === input.type)
                    return $io10(input);
                if ("PredictionSingletonObjectDetection" === input.type)
                    return $io11(input);
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io13(input);
                if ("PredictionSingletonSemanticSegmentation" === input.type)
                    return $io12(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdatePredictionBySampleIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $join = (typia.createAssertStringify as any).join;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return ("object" === typeof value && null !== value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        })) && $au0(value, _path + $join(key), true && _exceptionable) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation)",
                            value: value
                        });
                    return true;
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index1: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index1 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonClassification" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonClassification\"",
                    value: input.type
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonInstanceSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonInstanceSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao10 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonKeypointDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonKeypointDetection\"",
                    value: input.type
                })) && ((Array.isArray(input.keypoints) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && input.keypoints.every((elem: any, _index2: number) => "number" === typeof elem && !Number.isNaN(elem) || $guard(_exceptionable, {
                    path: _path + ".keypoints[" + _index2 + "]",
                    expected: "number",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypoints",
                    expected: "Array<number>",
                    value: input.keypoints
                })) && (undefined === input.bbox || ("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "(BoundingBox | undefined)",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao11 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonObjectDetection" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonObjectDetection\"",
                    value: input.type
                })) && (("object" === typeof input.bbox && null !== input.bbox || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && $ao5(input.bbox, _path + ".bbox", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".bbox",
                    expected: "BoundingBox",
                    value: input.bbox
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao12 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionSingletonSemanticSegmentation" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionSingletonSemanticSegmentation\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $ao13 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && (("object" === typeof input.segmentation && null !== input.segmentation || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && $ao4(input.segmentation, _path + ".segmentation", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".segmentation",
                    expected: "Segmentation",
                    value: input.segmentation
                })) && (undefined === input.probabilities || ("object" === typeof input.probabilities && null !== input.probabilities || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && $ao2(input.probabilities, _path + ".probabilities", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".probabilities",
                    expected: "(Probabilities | undefined)",
                    value: input.probabilities
                })) && ("string" === typeof input.taskName && ($is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string (@pattern_PredictionSingletonBase_taskName)",
                    value: input.taskName
                })) || $guard(_exceptionable, {
                    path: _path + ".taskName",
                    expected: "string",
                    value: input.taskName
                })) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && ($is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "string (@pattern_PredictionSingletonBase_cropDatasetId)",
                    value: input.cropDatasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropDatasetId",
                    expected: "(string | undefined)",
                    value: input.cropDatasetId
                })) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && ($is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "string (@pattern_PredictionSingletonBase_cropSampleId)",
                    value: input.cropSampleId
                })) || $guard(_exceptionable, {
                    path: _path + ".cropSampleId",
                    expected: "(string | undefined)",
                    value: input.cropSampleId
                })) && ("number" === typeof input.categoryId && !Number.isNaN(input.categoryId) && (0 <= input.categoryId || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number (@minimum 0)",
                    value: input.categoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".categoryId",
                    expected: "number",
                    value: input.categoryId
                })) && ("number" === typeof input.score && (0 <= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@minimum 0)",
                    value: input.score
                })) && (1 >= input.score || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number (@maximum 1)",
                    value: input.score
                })) || $guard(_exceptionable, {
                    path: _path + ".score",
                    expected: "number",
                    value: input.score
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("CLASSIFICATION" === input.type)
                        return $ao1(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("PredictionSingletonClassification" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionSingletonInstanceSegmentation" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionSingletonKeypointDetection" === input.type)
                        return $ao10(input, _path, true && _exceptionable);
                    if ("PredictionSingletonObjectDetection" === input.type)
                        return $ao11(input, _path, true && _exceptionable);
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao13(input, _path, true && _exceptionable);
                    if ("PredictionSingletonSemanticSegmentation" === input.type)
                        return $ao12(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionSingletons",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateOrUpdatePredictionBySampleIdRequestBody): string => {
        const $io1 = (input: any): boolean => "CLASSIFICATION" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io2 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io3 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io4 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io5 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io6 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io7 = (input: any): boolean => "OBJECT_DETECTION" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io8 = (input: any): boolean => "PredictionSingletonClassification" === input.type && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io9 = (input: any): boolean => "PredictionSingletonInstanceSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io10 = (input: any): boolean => "PredictionSingletonKeypointDetection" === input.type && (Array.isArray(input.keypoints) && input.keypoints.every((elem: any) => "number" === typeof elem)) && (undefined === input.bbox || "object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io11 = (input: any): boolean => "PredictionSingletonObjectDetection" === input.type && ("object" === typeof input.bbox && null !== input.bbox && $io5(input.bbox)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io12 = (input: any): boolean => "PredictionSingletonSemanticSegmentation" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $io13 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && ("object" === typeof input.segmentation && null !== input.segmentation && $io4(input.segmentation)) && (undefined === input.probabilities || "object" === typeof input.probabilities && null !== input.probabilities && $io2(input.probabilities)) && ("string" === typeof input.taskName && $is_custom("pattern_PredictionSingletonBase_taskName", "string", "", input.taskName)) && (undefined === input.cropDatasetId || "string" === typeof input.cropDatasetId && $is_custom("pattern_PredictionSingletonBase_cropDatasetId", "string", "", input.cropDatasetId)) && (undefined === input.cropSampleId || "string" === typeof input.cropSampleId && $is_custom("pattern_PredictionSingletonBase_cropSampleId", "string", "", input.cropSampleId)) && ("number" === typeof input.categoryId && 0 <= input.categoryId) && ("number" === typeof input.score && 0 <= input.score && 1 >= input.score);
        const $iu0 = (input: any): any => (() => {
            if ("CLASSIFICATION" === input.type)
                return $io1(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $io3(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $io6(input);
            if ("OBJECT_DETECTION" === input.type)
                return $io7(input);
            if ("PredictionSingletonClassification" === input.type)
                return $io8(input);
            if ("PredictionSingletonInstanceSegmentation" === input.type)
                return $io9(input);
            if ("PredictionSingletonKeypointDetection" === input.type)
                return $io10(input);
            if ("PredictionSingletonObjectDetection" === input.type)
                return $io11(input);
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $io13(input);
            if ("PredictionSingletonSemanticSegmentation" === input.type)
                return $io12(input);
            return false;
        })();
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${$su0(value)}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so1 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CLASSIFICATION\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so2 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so3 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"INSTANCE_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so4 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so5 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so6 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so5(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"KEYPOINT_DETECTION\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so7 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"OBJECT_DETECTION\"",
                value: input.type
            });
        })()},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so8 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonClassification\"",
                value: input.type
            });
        })()},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so9 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonInstanceSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so10 = (input: any): any => `{${undefined === input.bbox ? "" : `"bbox":${undefined !== input.bbox ? $so5(input.bbox) : undefined},`}${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonKeypointDetection\"",
                value: input.type
            });
        })()},"keypoints":${`[${input.keypoints.map((elem: any) => elem).join(",")}]`},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so11 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonObjectDetection\"",
                value: input.type
            });
        })()},"bbox":${$so5(input.bbox)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so12 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionSingletonSemanticSegmentation\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $so13 = (input: any): any => `{${undefined === input.probabilities ? "" : `"probabilities":${undefined !== input.probabilities ? $so2(input.probabilities) : undefined},`}${undefined === input.cropDatasetId ? "" : `"cropDatasetId":${undefined !== input.cropDatasetId ? $string(input.cropDatasetId) : undefined},`}${undefined === input.cropSampleId ? "" : `"cropSampleId":${undefined !== input.cropSampleId ? $string(input.cropSampleId) : undefined},`}"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"SEMANTIC_SEGMENTATION\"",
                value: input.type
            });
        })()},"segmentation":${$so4(input.segmentation)},"taskName":${$string(input.taskName)},"categoryId":${input.categoryId},"score":${input.score}}`;
        const $su0 = (input: any): any => (() => {
            if ("CLASSIFICATION" === input.type)
                return $so1(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $so3(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $so6(input);
            if ("OBJECT_DETECTION" === input.type)
                return $so7(input);
            if ("PredictionSingletonClassification" === input.type)
                return $so8(input);
            if ("PredictionSingletonInstanceSegmentation" === input.type)
                return $so9(input);
            if ("PredictionSingletonKeypointDetection" === input.type)
                return $so10(input);
            if ("PredictionSingletonObjectDetection" === input.type)
                return $so11(input);
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $so13(input);
            if ("PredictionSingletonSemanticSegmentation" === input.type)
                return $so12(input);
            $throws({
                expected: "({ type: \"CLASSIFICATION\"; } & PredictionSingletonClassification | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionSingletonInstanceSegmentation | { type: \"KEYPOINT_DETECTION\"; } & PredictionSingletonKeypointDetection | { type: \"OBJECT_DETECTION\"; } & PredictionSingletonObjectDetection | { type: \"PredictionSingletonClassification\"; } & PredictionSingletonClassification | { type: \"PredictionSingletonInstanceSegmentation\"; } & PredictionSingletonInstanceSegmentation | { type: \"PredictionSingletonKeypointDetection\"; } & PredictionSingletonKeypointDetection | { type: \"PredictionSingletonObjectDetection\"; } & PredictionSingletonObjectDetection | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionSingletonSemanticSegmentation | { type: \"PredictionSingletonSemanticSegmentation\"; } & PredictionSingletonSemanticSegmentation)",
                value: input
            });
        })();
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export type CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody = PredictionTaskSchema;
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBodySanitizer = PredictionTaskSchemaFromJSON;
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBodyValidator = PredictionTaskSchemaValidator;
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdTypiaAssertEquals = (input: any) => {
    return ((input: any): CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $join = (typia.createAssertEquals as any).join;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index1: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index2: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io3 = (input: any, _exceptionable: boolean = true): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index3: number) => "object" === typeof elem && null !== elem && $io4(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io4 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any, _index4: number) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any, _index5: number) => "object" === typeof elem && null !== elem && $io5(elem, true && _exceptionable))) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "name", "keypointNames", "keypointSkeleton"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io5 = (input: any, _exceptionable: boolean = true): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value;
                return false;
            });
            const $io6 = (input: any, _exceptionable: boolean = true): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index6: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io7 = (input: any, _exceptionable: boolean = true): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index7: number) => "object" === typeof elem && null !== elem && $io4(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io8 = (input: any, _exceptionable: boolean = true): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index8: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io9 = (input: any, _exceptionable: boolean = true): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any, _index9: number) => "object" === typeof elem && null !== elem && $io1(elem, true && _exceptionable))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name)) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["type", "categories", "name"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io9(input, true && _exceptionable);
                if ("PredictionTaskSchemaSimple" === input.type)
                    return $io8(input, true && _exceptionable);
                if ("PredictionTaskSchemaKeypoint" === input.type)
                    return $io7(input, true && _exceptionable);
                if ("OBJECT_DETECTION" === input.type)
                    return $io6(input, true && _exceptionable);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io3(input, true && _exceptionable);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io2(input, true && _exceptionable);
                if ("CLASSIFICATION" === input.type)
                    return $io0(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao4(elem, _path + ".categories[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index4: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index4 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index5: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index5 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao5(elem, _path + ".keypointSkeleton[" + _index5 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index5 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "name", "keypointNames", "keypointSkeleton"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index6: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index6 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index6 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index6 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaKeypoint" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaKeypoint\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index7: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao4(elem, _path + ".categories[" + _index7 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaSimple" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaSimple\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index8: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index8 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index9: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index9 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["type", "categories", "name"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaSimple" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaKeypoint" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("CLASSIFICATION" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CreateOrUpdatePredictionTaskSchemaByDatasetIdTypiaAssertStringify = (input: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
        const __is = (input: any): input is CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $join = (typia.createAssertStringify as any).join;
            const $io0 = (input: any): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io1 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
            const $io2 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io3 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io4(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io4 = (input: any): boolean => "number" === typeof input.id && !Number.isNaN(input.id) && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem)));
            const $io5 = (input: any): boolean => "number" === typeof input.length && !Number.isNaN(input.length) && Object.keys(input).every((key: any) => {
                if (["length"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                    return "number" === typeof value && !Number.isNaN(value);
                return true;
            });
            const $io6 = (input: any): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io7 = (input: any): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io4(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io8 = (input: any): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $io9 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
            const $iu0 = (input: any): any => (() => {
                if ("SEMANTIC_SEGMENTATION" === input.type)
                    return $io9(input);
                if ("PredictionTaskSchemaSimple" === input.type)
                    return $io8(input);
                if ("PredictionTaskSchemaKeypoint" === input.type)
                    return $io7(input);
                if ("OBJECT_DETECTION" === input.type)
                    return $io6(input);
                if ("KEYPOINT_DETECTION" === input.type)
                    return $io3(input);
                if ("INSTANCE_SEGMENTATION" === input.type)
                    return $io2(input);
                if ("CLASSIFICATION" === input.type)
                    return $io0(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $join = (typia.createAssertStringify as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("CLASSIFICATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"CLASSIFICATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index1: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index1 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index1 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("INSTANCE_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"INSTANCE_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index2: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index2 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index2 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao3 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("KEYPOINT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"KEYPOINT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index3: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao4(elem, _path + ".categories[" + _index3 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index3 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao4 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.id && !Number.isNaN(input.id) && (0 <= input.id || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number (@minimum 0)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "number",
                    value: input.id
                })) && ("string" === typeof input.name && (1 <= input.name.length || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@minLength 1)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && (undefined === input.keypointNames || (Array.isArray(input.keypointNames) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && input.keypointNames.every((elem: any, _index4: number) => "string" === typeof elem || $guard(_exceptionable, {
                    path: _path + ".keypointNames[" + _index4 + "]",
                    expected: "string",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointNames",
                    expected: "(Array<string> | undefined)",
                    value: input.keypointNames
                })) && (undefined === input.keypointSkeleton || (Array.isArray(input.keypointSkeleton) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                })) && input.keypointSkeleton.every((elem: any, _index5: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index5 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) && $ao5(elem, _path + ".keypointSkeleton[" + _index5 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton[" + _index5 + "]",
                    expected: "KeypointSkeletonConnection",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".keypointSkeleton",
                    expected: "(Array<KeypointSkeletonConnection> | undefined)",
                    value: input.keypointSkeleton
                }));
                const $ao5 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("number" === typeof input.length && !Number.isNaN(input.length) || $guard(_exceptionable, {
                    path: _path + ".length",
                    expected: "number",
                    value: input.length
                })) && (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["length"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                        return "number" === typeof value && !Number.isNaN(value) || $guard(_exceptionable, {
                            path: _path + $join(key),
                            expected: "number",
                            value: value
                        });
                    return true;
                }));
                const $ao6 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("OBJECT_DETECTION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"OBJECT_DETECTION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index6: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index6 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index6 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index6 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao7 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaKeypoint" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaKeypoint\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index7: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) && $ao4(elem, _path + ".categories[" + _index7 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index7 + "]",
                    expected: "PredictionTaskSchemaCategoryKeypoints",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategoryKeypoints>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao8 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("PredictionTaskSchemaSimple" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"PredictionTaskSchemaSimple\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index8: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index8 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index8 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $ao9 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("SEMANTIC_SEGMENTATION" === input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "\"SEMANTIC_SEGMENTATION\"",
                    value: input.type
                })) && ((Array.isArray(input.categories) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && input.categories.every((elem: any, _index9: number) => ("object" === typeof elem && null !== elem || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) && $ao1(elem, _path + ".categories[" + _index9 + "]", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".categories[" + _index9 + "]",
                    expected: "PredictionTaskSchemaCategory",
                    value: elem
                })) || $guard(_exceptionable, {
                    path: _path + ".categories",
                    expected: "Array<PredictionTaskSchemaCategory>",
                    value: input.categories
                })) && ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                }));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if ("SEMANTIC_SEGMENTATION" === input.type)
                        return $ao9(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaSimple" === input.type)
                        return $ao8(input, _path, true && _exceptionable);
                    if ("PredictionTaskSchemaKeypoint" === input.type)
                        return $ao7(input, _path, true && _exceptionable);
                    if ("OBJECT_DETECTION" === input.type)
                        return $ao6(input, _path, true && _exceptionable);
                    if ("KEYPOINT_DETECTION" === input.type)
                        return $ao3(input, _path, true && _exceptionable);
                    if ("INSTANCE_SEGMENTATION" === input.type)
                        return $ao2(input, _path, true && _exceptionable);
                    if ("CLASSIFICATION" === input.type)
                        return $ao0(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "({ type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody): string => {
        const $io0 = (input: any): boolean => "CLASSIFICATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io1 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length);
        const $io2 = (input: any): boolean => "INSTANCE_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io3 = (input: any): boolean => "KEYPOINT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io4(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io4 = (input: any): boolean => "number" === typeof input.id && 0 <= input.id && ("string" === typeof input.name && 1 <= input.name.length) && (undefined === input.keypointNames || Array.isArray(input.keypointNames) && input.keypointNames.every((elem: any) => "string" === typeof elem)) && (undefined === input.keypointSkeleton || Array.isArray(input.keypointSkeleton) && input.keypointSkeleton.every((elem: any) => "object" === typeof elem && null !== elem && $io5(elem)));
        const $io5 = (input: any): boolean => "number" === typeof input.length && Object.keys(input).every((key: any) => {
            if (["length"].some((prop: any) => key === prop))
                return true;
            const value = input[key];
            if (undefined === value)
                return true;
            if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
                return "number" === typeof value;
            return true;
        });
        const $io6 = (input: any): boolean => "OBJECT_DETECTION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io7 = (input: any): boolean => "PredictionTaskSchemaKeypoint" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io4(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io8 = (input: any): boolean => "PredictionTaskSchemaSimple" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $io9 = (input: any): boolean => "SEMANTIC_SEGMENTATION" === input.type && (Array.isArray(input.categories) && input.categories.every((elem: any) => "object" === typeof elem && null !== elem && $io1(elem))) && ("string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name));
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $join = (typia.createAssertStringify as any).join;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"CLASSIFICATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so2 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"INSTANCE_SEGMENTATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so3 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"KEYPOINT_DETECTION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => $so4(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so4 = (input: any): any => `{${undefined === input.keypointNames ? "" : `"keypointNames":${undefined !== input.keypointNames ? `[${input.keypointNames.map((elem: any) => $string(elem)).join(",")}]` : undefined},`}${undefined === input.keypointSkeleton ? "" : `"keypointSkeleton":${undefined !== input.keypointSkeleton ? `[${input.keypointSkeleton.map((elem: any) => $so5(elem)).join(",")}]` : undefined},`}"id":${input.id},"name":${$string(input.name)}}`;
        const $so5 = (input: any): any => `{${$tail(`"length":${input.length},${Object.entries(input).map(([key, value]: [string, any]) => { if (undefined === value)
            return ""; if (["length"].some((regular: any) => regular === key))
            return ""; if (RegExp(/^[+-]?\d+(?:\.\d+)?(?:[eE][+-]?\d+)?$/).test(key))
            return `${JSON.stringify(key)}:${value}`; return ""; }).filter((str: any) => "" !== str).join(",")}`)}}`;
        const $so6 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"OBJECT_DETECTION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so7 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionTaskSchemaKeypoint\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => $so4(elem)).join(",")}]`},"name":${$string(input.name)}}`;
        const $so8 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"PredictionTaskSchemaSimple\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $so9 = (input: any): any => `{"type":${(() => {
            if ("string" === typeof input.type)
                return $string(input.type);
            if ("string" === typeof input.type)
                return "\"" + input.type + "\"";
            $throws({
                expected: "\"SEMANTIC_SEGMENTATION\"",
                value: input.type
            });
        })()},"categories":${`[${input.categories.map((elem: any) => `{"id":${(elem as any).id},"name":${$string((elem as any).name)}}`).join(",")}]`},"name":${$string(input.name)}}`;
        const $su0 = (input: any): any => (() => {
            if ("SEMANTIC_SEGMENTATION" === input.type)
                return $so9(input);
            if ("PredictionTaskSchemaSimple" === input.type)
                return $so8(input);
            if ("PredictionTaskSchemaKeypoint" === input.type)
                return $so7(input);
            if ("OBJECT_DETECTION" === input.type)
                return $so6(input);
            if ("KEYPOINT_DETECTION" === input.type)
                return $so3(input);
            if ("INSTANCE_SEGMENTATION" === input.type)
                return $so2(input);
            if ("CLASSIFICATION" === input.type)
                return $so0(input);
            $throws({
                expected: "({ type: \"SEMANTIC_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaSimple\"; } & PredictionTaskSchemaSimple | { type: \"PredictionTaskSchemaKeypoint\"; } & PredictionTaskSchemaKeypoint | { type: \"OBJECT_DETECTION\"; } & PredictionTaskSchemaSimple | { type: \"KEYPOINT_DETECTION\"; } & PredictionTaskSchemaKeypoint | { type: \"INSTANCE_SEGMENTATION\"; } & PredictionTaskSchemaSimple | { type: \"CLASSIFICATION\"; } & PredictionTaskSchemaSimple)",
                value: input
            });
        })();
        return $su0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/predictions/samples/{sampleId}

export const createOrUpdatePredictionBySampleIdFN: RequestHandler<
    CreateOrUpdatePredictionBySampleIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateOrUpdatePredictionBySampleIdRequestBody,
    CreateOrUpdatePredictionBySampleIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateOrUpdatePredictionBySampleIdRequestBodySanitizer, CreateOrUpdatePredictionBySampleIdRequestBodyValidator);
    const {
        
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, CreateOrUpdatePredictionBySampleIdRequestParamsSanitizer, CreateOrUpdatePredictionBySampleIdRequestParamsValidator);
    const {
        
        
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, CreateOrUpdatePredictionBySampleIdRequestQuerySanitizer, CreateOrUpdatePredictionBySampleIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/predictions/tasks

export const createOrUpdatePredictionTaskSchemaByDatasetIdFN: RequestHandler<
    CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParams,
    CreateEntityResponse | ApiErrorResponse,
    CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBody,
    CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBodySanitizer, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestBodyValidator);
    const {
        
        datasetId,
        
    } =  await validateAndSanitize(req.params, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsSanitizer, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestParamsValidator);
    const {
        
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQuerySanitizer, CreateOrUpdatePredictionTaskSchemaByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        CreateEntityResponseStringify,
        validateAndRespond({ id, ...rest }, CreateEntityResponseFromJSON, CreateEntityResponseValidator),
    );
}



/v1/datasets/{datasetId}/predictions/tasks/{taskName}

export const getPredictionTaskSchemaByTaskNameFN: RequestHandler<
    GetPredictionTaskSchemaByTaskNameRequestParams,
    PredictionTaskSchema | ApiErrorResponse,
    GetPredictionTaskSchemaByTaskNameRequestBody,
    GetPredictionTaskSchemaByTaskNameRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetPredictionTaskSchemaByTaskNameRequestBodySanitizer, GetPredictionTaskSchemaByTaskNameRequestBodyValidator);
    const {
        datasetId,
        taskName,
        
    } =  await validateAndSanitize(req.params, GetPredictionTaskSchemaByTaskNameRequestParamsSanitizer, GetPredictionTaskSchemaByTaskNameRequestParamsValidator);
    const {
        
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, GetPredictionTaskSchemaByTaskNameRequestQuerySanitizer, GetPredictionTaskSchemaByTaskNameRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        PredictionTaskSchemaStringify,
        validateAndRespond({ id, ...rest }, PredictionTaskSchemaFromJSON, PredictionTaskSchemaValidator),
    );
}



/v1/datasets/{datasetId}/predictions/tasks

export const getPredictionTaskSchemasByDatasetIdFN: RequestHandler<
    GetPredictionTaskSchemasByDatasetIdRequestParams,
    PredictionTaskSchemas | ApiErrorResponse,
    GetPredictionTaskSchemasByDatasetIdRequestBody,
    GetPredictionTaskSchemasByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetPredictionTaskSchemasByDatasetIdRequestBodySanitizer, GetPredictionTaskSchemasByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
    } =  await validateAndSanitize(req.params, GetPredictionTaskSchemasByDatasetIdRequestParamsSanitizer, GetPredictionTaskSchemasByDatasetIdRequestParamsValidator);
    const {
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, GetPredictionTaskSchemasByDatasetIdRequestQuerySanitizer, GetPredictionTaskSchemasByDatasetIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        PredictionTaskSchemasStringify,
        validateAndRespond({ id, ...rest }, PredictionTaskSchemasFromJSON, PredictionTaskSchemasValidator),
    );
}



/v1/datasets/{datasetId}/predictions/samples
const PredictionSingletonsArrayStringify = fastJSONArrayify(PredictionSingletonsStringifyShape);
export const getPredictionsByDatasetIdFN: RequestHandler<
    GetPredictionsByDatasetIdRequestParams,
    PredictionSingletons[] | ApiErrorResponse,
    GetPredictionsByDatasetIdRequestBody,
    GetPredictionsByDatasetIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetPredictionsByDatasetIdRequestBodySanitizer, GetPredictionsByDatasetIdRequestBodyValidator);
    const {
        datasetId,
        
        
        
        
        
    } =  await validateAndSanitize(req.params, GetPredictionsByDatasetIdRequestParamsSanitizer, GetPredictionsByDatasetIdRequestParamsValidator);
    const {
        
        predictionUUIDTimestamp,
        pageSize,
        pageOffset,
        lean,
        taskName,
    } = await validateAndSanitize(req.query, GetPredictionsByDatasetIdRequestQuerySanitizer, GetPredictionsByDatasetIdRequestQueryValidator);



    
    const schemas = ....

    return fastJSONResponse(
        res,
        PredictionSingletonsArrayStringify,
        schemas.map((schema) => {
            const { _id: id, ...rest } = schema;
            return validateAndRespond({ id, ...rest }, PredictionSingletonsFromJSON, PredictionSingletonsValidator);
        }),
    );
}



/v1/datasets/{datasetId}/predictions/samples/{sampleId}

export const getPredictionsBySampleIdFN: RequestHandler<
    GetPredictionsBySampleIdRequestParams,
    PredictionSingletons | ApiErrorResponse,
    GetPredictionsBySampleIdRequestBody,
    GetPredictionsBySampleIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, GetPredictionsBySampleIdRequestBodySanitizer, GetPredictionsBySampleIdRequestBodyValidator);
    const {
        datasetId,
        sampleId,
        
    } =  await validateAndSanitize(req.params, GetPredictionsBySampleIdRequestParamsSanitizer, GetPredictionsBySampleIdRequestParamsValidator);
    const {
        
        
        predictionUUIDTimestamp,
    } = await validateAndSanitize(req.query, GetPredictionsBySampleIdRequestQuerySanitizer, GetPredictionsBySampleIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        PredictionSingletonsStringify,
        validateAndRespond({ id, ...rest }, PredictionSingletonsFromJSON, PredictionSingletonsValidator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * PredictionsApi - interface
 *
 * @export
 * @interface PredictionsApiInterface
 */
export interface PredictionsApiInterface {
    /**
     * Create/Update all the prediction singletons per taskName for a sampleId in the order/index of them being discovered
     * @param {PredictionSingletons} predictionSingletons
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    createOrUpdatePredictionBySampleIdRaw(requestParameters: CreateOrUpdatePredictionBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Create/Update all the prediction singletons per taskName for a sampleId in the order/index of them being discovered
     */
    createOrUpdatePredictionBySampleId(requestParameters: CreateOrUpdatePredictionBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Creates/updates a prediction task schema with the task name
     * @param {PredictionTaskSchema} predictionTaskSchema
     * @param {string} datasetId ObjectId of the dataset
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    createOrUpdatePredictionTaskSchemaByDatasetIdRaw(requestParameters: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>>;
    /**
     * Creates/updates a prediction task schema with the task name
     */
    createOrUpdatePredictionTaskSchemaByDatasetId(requestParameters: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse>;
    /**
     * Get a prediction task schemas named taskName for a datasetId
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} taskName The prediction task name for which one wants to list the predictions
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    getPredictionTaskSchemaByTaskNameRaw(requestParameters: GetPredictionTaskSchemaByTaskNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionTaskSchema>>;
    /**
     * Get a prediction task schemas named taskName for a datasetId
     */
    getPredictionTaskSchemaByTaskName(requestParameters: GetPredictionTaskSchemaByTaskNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionTaskSchema>;
    /**
     * Get list of all the prediction task schemas for a datasetId at a specific predictionUUIDTimestamp. If no predictionUUIDTimestamp is set, it defaults to the newest
     * @param {string} datasetId ObjectId of the dataset
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    getPredictionTaskSchemasByDatasetIdRaw(requestParameters: GetPredictionTaskSchemasByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionTaskSchemas>>;
    /**
     * Get list of all the prediction task schemas for a datasetId at a specific predictionUUIDTimestamp. If no predictionUUIDTimestamp is set, it defaults to the newest
     */
    getPredictionTaskSchemasByDatasetId(requestParameters: GetPredictionTaskSchemasByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionTaskSchemas>;
    /**
     * Get all prediction singletons of all samples of a dataset ordered by the sample mapping
     * @param {string} datasetId ObjectId of the dataset
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {number} [pageSize] pagination size/limit of the number of samples to return
     * @param {number} [pageOffset] pagination offset
     * @param {boolean} [lean] if lean is set to true, all prediction singletons are returned without their \&quot;heavy\&quot; part. This is useful for large datasets where the full prediction singletons are not needed. e.g SEGMENTATION does not need to return the RLE
     * @param {string} [taskName] If provided, only gets all prediction singletons of all samples of a dataset that were yielded by a specific prediction task name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    getPredictionsByDatasetIdRaw(requestParameters: GetPredictionsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PredictionSingletons>>>;
    /**
     * Get all prediction singletons of all samples of a dataset ordered by the sample mapping
     */
    getPredictionsByDatasetId(requestParameters: GetPredictionsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PredictionSingletons>>;
    /**
     * Get all prediction singletons of all tasks for a specific sample of a dataset
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} sampleId ObjectId of the sample
     * @param {number} [predictionUUIDTimestamp] Deprecated, currently ignored. The timestamp of when the actual predictions were created. This is used as a peg to version predictions. E.g one could upload predictions on day 1 and then create new predictions with an improved model on day 30. One can then upload the new predictions to the same dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApiInterface
     */
    getPredictionsBySampleIdRaw(requestParameters: GetPredictionsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionSingletons>>;
    /**
     * Get all prediction singletons of all tasks for a specific sample of a dataset
     */
    getPredictionsBySampleId(requestParameters: GetPredictionsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionSingletons>;
}
/**
 *
 */
export class PredictionsApi extends runtime.BaseAPI implements PredictionsApiInterface {
    /**
     * Create/Update all the prediction singletons per taskName for a sampleId in the order/index of them being discovered
     */
    async createOrUpdatePredictionBySampleIdRaw(requestParameters: CreateOrUpdatePredictionBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.predictionSingletons === null || requestParameters.predictionSingletons === undefined) {
            throw new runtime.RequiredError('predictionSingletons', 'Required parameter requestParameters.predictionSingletons was null or undefined when calling createOrUpdatePredictionBySampleId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createOrUpdatePredictionBySampleId.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling createOrUpdatePredictionBySampleId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/samples/{sampleId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PredictionSingletonsToJSON(requestParameters.predictionSingletons),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Create/Update all the prediction singletons per taskName for a sampleId in the order/index of them being discovered
     */
    async createOrUpdatePredictionBySampleId(requestParameters: CreateOrUpdatePredictionBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createOrUpdatePredictionBySampleIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Creates/updates a prediction task schema with the task name
     */
    async createOrUpdatePredictionTaskSchemaByDatasetIdRaw(requestParameters: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEntityResponse>> {
        if (requestParameters.predictionTaskSchema === null || requestParameters.predictionTaskSchema === undefined) {
            throw new runtime.RequiredError('predictionTaskSchema', 'Required parameter requestParameters.predictionTaskSchema was null or undefined when calling createOrUpdatePredictionTaskSchemaByDatasetId.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling createOrUpdatePredictionTaskSchemaByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/tasks`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PredictionTaskSchemaToJSON(requestParameters.predictionTaskSchema),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEntityResponseFromJSON(jsonValue));
    }
    /**
     * Creates/updates a prediction task schema with the task name
     */
    async createOrUpdatePredictionTaskSchemaByDatasetId(requestParameters: CreateOrUpdatePredictionTaskSchemaByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEntityResponse> {
        const response = await this.createOrUpdatePredictionTaskSchemaByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get a prediction task schemas named taskName for a datasetId
     */
    async getPredictionTaskSchemaByTaskNameRaw(requestParameters: GetPredictionTaskSchemaByTaskNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionTaskSchema>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getPredictionTaskSchemaByTaskName.');
        }
        if (requestParameters.taskName === null || requestParameters.taskName === undefined) {
            throw new runtime.RequiredError('taskName', 'Required parameter requestParameters.taskName was null or undefined when calling getPredictionTaskSchemaByTaskName.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/tasks/{taskName}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"taskName"}}`, encodeURIComponent(String(requestParameters.taskName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskSchemaFromJSON(jsonValue));
    }
    /**
     * Get a prediction task schemas named taskName for a datasetId
     */
    async getPredictionTaskSchemaByTaskName(requestParameters: GetPredictionTaskSchemaByTaskNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionTaskSchema> {
        const response = await this.getPredictionTaskSchemaByTaskNameRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get list of all the prediction task schemas for a datasetId at a specific predictionUUIDTimestamp. If no predictionUUIDTimestamp is set, it defaults to the newest
     */
    async getPredictionTaskSchemasByDatasetIdRaw(requestParameters: GetPredictionTaskSchemasByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionTaskSchemas>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getPredictionTaskSchemasByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/tasks`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PredictionTaskSchemasFromJSON(jsonValue));
    }
    /**
     * Get list of all the prediction task schemas for a datasetId at a specific predictionUUIDTimestamp. If no predictionUUIDTimestamp is set, it defaults to the newest
     */
    async getPredictionTaskSchemasByDatasetId(requestParameters: GetPredictionTaskSchemasByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionTaskSchemas> {
        const response = await this.getPredictionTaskSchemasByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all prediction singletons of all samples of a dataset ordered by the sample mapping
     */
    async getPredictionsByDatasetIdRaw(requestParameters: GetPredictionsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PredictionSingletons>>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getPredictionsByDatasetId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }
        if (requestParameters.pageOffset !== undefined) {
            queryParameters['pageOffset'] = requestParameters.pageOffset;
        }
        if (requestParameters.lean !== undefined) {
            queryParameters['lean'] = requestParameters.lean;
        }
        if (requestParameters.taskName !== undefined) {
            queryParameters['taskName'] = requestParameters.taskName;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/samples`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PredictionSingletonsFromJSON));
    }
    /**
     * Get all prediction singletons of all samples of a dataset ordered by the sample mapping
     */
    async getPredictionsByDatasetId(requestParameters: GetPredictionsByDatasetIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PredictionSingletons>> {
        const response = await this.getPredictionsByDatasetIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Get all prediction singletons of all tasks for a specific sample of a dataset
     */
    async getPredictionsBySampleIdRaw(requestParameters: GetPredictionsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PredictionSingletons>> {
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling getPredictionsBySampleId.');
        }
        if (requestParameters.sampleId === null || requestParameters.sampleId === undefined) {
            throw new runtime.RequiredError('sampleId', 'Required parameter requestParameters.sampleId was null or undefined when calling getPredictionsBySampleId.');
        }
        const queryParameters: any = {};
        if (requestParameters.predictionUUIDTimestamp !== undefined) {
            queryParameters['predictionUUIDTimestamp'] = requestParameters.predictionUUIDTimestamp;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/predictions/samples/{sampleId}`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"sampleId"}}`, encodeURIComponent(String(requestParameters.sampleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PredictionSingletonsFromJSON(jsonValue));
    }
    /**
     * Get all prediction singletons of all tasks for a specific sample of a dataset
     */
    async getPredictionsBySampleId(requestParameters: GetPredictionsBySampleIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PredictionSingletons> {
        const response = await this.getPredictionsBySampleIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
