/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { PredictionTaskSchemaKeypoint } from './PredictionTaskSchemaKeypoint';
import { PredictionTaskSchemaKeypointFromJSON, PredictionTaskSchemaKeypointFromJSONTyped, PredictionTaskSchemaKeypointToJSON, } from './PredictionTaskSchemaKeypoint';
import type { PredictionTaskSchemaSimple } from './PredictionTaskSchemaSimple';
import { PredictionTaskSchemaSimpleFromJSON, PredictionTaskSchemaSimpleFromJSONTyped, PredictionTaskSchemaSimpleToJSON, } from './PredictionTaskSchemaSimple';
/*
causes circular dependendies
import {
     PredictionTaskSchemaKeypointFromJSONTyped,
} from './PredictionTaskSchemaKeypoint';
import {
     PredictionTaskSchemaSimpleFromJSONTyped
} from './PredictionTaskSchemaSimple';


*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 * The schema for predictions or labels when doing classification, object detection, keypoint detection or instance segmentation
 * @export
 * @interface PredictionTaskSchemaBase
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_PredictionTaskSchemaBase_name")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface PredictionTaskSchemaBase {
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof PredictionTaskSchemaBase
        
        
    * @pattern_PredictionTaskSchemaBase_name
         */
    name: string;
    /**
     * This is the TaskType. Due to openapi.oneOf fuckery with discriminators, this needs to be a string
     * #type string
     * #memberof PredictionTaskSchemaBase
        
        
         */
    type: string;
}
/**
 * Check if a given object implements the PredictionTaskSchemaBase interface.
 */
export function instanceOfPredictionTaskSchemaBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { PredictionTaskSchemaKeypointValidator, PredictionTaskSchemaKeypointStringifyShape, PredictionTaskSchemaKeypointStringifyShapeProperties, } from './PredictionTaskSchemaKeypoint';
import { PredictionTaskSchemaSimpleValidator, PredictionTaskSchemaSimpleStringifyShape, PredictionTaskSchemaSimpleStringifyShapeProperties, } from './PredictionTaskSchemaSimple';
export const PredictionTaskSchemaBaseTypiaAssertEquals = (input: any) => {
    return ((input: any): PredictionTaskSchemaBase => {
        const __is = (input: any, _exceptionable: boolean = true): input is PredictionTaskSchemaBase => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) && "string" === typeof input.type && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["name", "type"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaBase => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["name", "type"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const PredictionTaskSchemaBaseTypiaAssertStringify = (input: PredictionTaskSchemaBase): string => {
    return ((input: any): string => { const assert = (input: any): PredictionTaskSchemaBase => {
        const __is = (input: any): input is PredictionTaskSchemaBase => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).name && $is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", (input as any).name) && "string" === typeof (input as any).type);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is PredictionTaskSchemaBase => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.name && ($is_custom("pattern_PredictionTaskSchemaBase_name", "string", "", input.name) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string (@pattern_PredictionTaskSchemaBase_name)",
                    value: input.name
                })) || $guard(_exceptionable, {
                    path: _path + ".name",
                    expected: "string",
                    value: input.name
                })) && ("string" === typeof input.type || $guard(_exceptionable, {
                    path: _path + ".type",
                    expected: "string",
                    value: input.type
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaBase",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "PredictionTaskSchemaBase",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: PredictionTaskSchemaBase): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        return `{"name":${$string((input as any).name)},"type":${$string((input as any).type)}}`;
    }; return stringify(assert(input)); })(input);
};
export const PredictionTaskSchemaBaseValidatorShape = {
    'name': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'type': yup.string().defined(),
};
export const PredictionTaskSchemaBaseValidator = yup.object().shape(PredictionTaskSchemaBaseValidatorShape);
export const PredictionTaskSchemaBaseStringifyShapeProperties: ObjectSchema['properties'] = {
    'name': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'type': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
};
export const PredictionTaskSchemaBaseStringifyShape: ObjectSchema = {
    title: 'PredictionTaskSchemaBase Stringifier',
    type: 'object',
    properties: {
        ...PredictionTaskSchemaBaseStringifyShapeProperties
    },
    required: [
        'name',
        'type',
    ],
};
export const PredictionTaskSchemaBaseStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(PredictionTaskSchemaBaseStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const PredictionTaskSchemaBaseStringify: (data: PredictionTaskSchemaBase) => string = PredictionTaskSchemaBaseStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function PredictionTaskSchemaBaseFromJSON(json: any): PredictionTaskSchemaBase {
    return PredictionTaskSchemaBaseFromJSONTyped(json, false);
}
export function PredictionTaskSchemaBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PredictionTaskSchemaBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
    if (!ignoreDiscriminator) {
        if (json['type'] === 'PredictionTaskSchemaKeypoint') {
            return PredictionTaskSchemaKeypointFromJSONTyped(json, true);
        }
        if (json['type'] === 'PredictionTaskSchemaSimple') {
            return PredictionTaskSchemaSimpleFromJSONTyped(json, true);
        }
    }
*/
    return {
        'name': json['name'],
        'type': json['type'],
    };
}
export function PredictionTaskSchemaBaseToJSON(value?: PredictionTaskSchemaBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'type': value.type,
    };
}
