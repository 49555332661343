/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { SamplingConfig } from './SamplingConfig';
import { SamplingConfigFromJSON, SamplingConfigFromJSONTyped, SamplingConfigToJSON, } from './SamplingConfig';
import type { SamplingMethod } from './SamplingMethod';
import { SamplingMethodFromJSON, SamplingMethodFromJSONTyped, SamplingMethodToJSON, } from './SamplingMethod';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface SamplingCreateRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_SamplingCreateRequest_newTagName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SamplingCreateRequest_preselectedTagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SamplingCreateRequest_queryTagId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_SamplingCreateRequest_scoreType")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface SamplingCreateRequest {
    /**
     * The name of the tag
     * #type string
     * #memberof SamplingCreateRequest
        
        
    * @pattern_SamplingCreateRequest_newTagName
    * @minLength 3
         */
    newTagName: string;
    /**
     *
     * #type SamplingMethod
     * #memberof SamplingCreateRequest
        
        
         */
    method: SamplingMethod;
    /**
     *
     * #type SamplingConfig
     * #memberof SamplingCreateRequest
        
        
         */
    config: SamplingConfig;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof SamplingCreateRequest
        
        
    * @pattern_SamplingCreateRequest_preselectedTagId
         */
    preselectedTagId?: string;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof SamplingCreateRequest
        
        
    * @pattern_SamplingCreateRequest_queryTagId
         */
    queryTagId?: string;
    /**
     * Type of active learning score
     * #type string
     * #memberof SamplingCreateRequest
        
        
    * @pattern_SamplingCreateRequest_scoreType
    * @minLength 1
         */
    scoreType?: string;
    /**
     * temporary rowCount until the API/DB is aware how many they are..
     * #type number
     * #memberof SamplingCreateRequest
        
        
         */
    rowCount?: number;
}
/**
 * Check if a given object implements the SamplingCreateRequest interface.
 */
export function instanceOfSamplingCreateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newTagName" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "config" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { SamplingConfigValidator, SamplingConfigStringifyShape, SamplingConfigStringifyShapeProperties, } from './SamplingConfig';
import { SamplingMethodValidator, SamplingMethodStringifyShape, SamplingMethodStringifyShapeProperties, } from './SamplingMethod';
export const SamplingCreateRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): SamplingCreateRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is SamplingCreateRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && ("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) && $io1(input.config, true && _exceptionable)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.scoreType || "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType)) && (undefined === input.rowCount || "number" === typeof input.rowCount) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["newTagName", "method", "config", "preselectedTagId", "queryTagId", "scoreType", "rowCount"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["stoppingCondition"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["nSamples", "minDistance"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SamplingCreateRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_SamplingCreateRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string",
                    value: input.newTagName
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && (("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_SamplingCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_SamplingCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.scoreType || "string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_SamplingCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "(string | undefined)",
                    value: input.scoreType
                })) && (undefined === input.rowCount || "number" === typeof input.rowCount || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "(number | undefined)",
                    value: input.rowCount
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["newTagName", "method", "config", "preselectedTagId", "queryTagId", "scoreType", "rowCount"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["stoppingCondition"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["nSamples", "minDistance"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const SamplingCreateRequestTypiaAssertStringify = (input: SamplingCreateRequest): string => {
    return ((input: any): string => { const assert = (input: any): SamplingCreateRequest => {
        const __is = (input: any): input is SamplingCreateRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && ("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) && $io1(input.config)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.scoreType || "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType)) && (undefined === input.rowCount || "number" === typeof input.rowCount && !Number.isNaN(input.rowCount));
            const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
            const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples)) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is SamplingCreateRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_SamplingCreateRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string",
                    value: input.newTagName
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && (("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_SamplingCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_SamplingCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.scoreType || "string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_SamplingCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "(string | undefined)",
                    value: input.scoreType
                })) && (undefined === input.rowCount || "number" === typeof input.rowCount && !Number.isNaN(input.rowCount) || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "(number | undefined)",
                    value: input.rowCount
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance) || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: SamplingCreateRequest): string => {
        const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
        const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.preselectedTagId ? "" : `"preselectedTagId":${undefined !== input.preselectedTagId ? $string(input.preselectedTagId) : undefined},`}${undefined === input.queryTagId ? "" : `"queryTagId":${undefined !== input.queryTagId ? $string(input.queryTagId) : undefined},`}${undefined === input.scoreType ? "" : `"scoreType":${undefined !== input.scoreType ? $string(input.scoreType) : undefined},`}${undefined === input.rowCount ? "" : `"rowCount":${undefined !== input.rowCount ? input.rowCount : undefined},`}"newTagName":${$string(input.newTagName)},"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                value: input.method
            });
        })()},"config":${$so1(input.config)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.stoppingCondition ? "" : `"stoppingCondition":${undefined !== input.stoppingCondition ? $so2(input.stoppingCondition) : undefined}`}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined},`}${undefined === input.minDistance ? "" : `"minDistance":${undefined !== input.minDistance ? input.minDistance : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const SamplingCreateRequestValidatorShape = {
    'newTagName': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3).defined(),
    'method': SamplingMethodValidator.defined(),
    'config': SamplingConfigValidator.defined(),
    'preselectedTagId': yup.string().matches(/^[a-f0-9]{24}$/),
    'queryTagId': yup.string().matches(/^[a-f0-9]{24}$/),
    'scoreType': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).min(1),
    'rowCount': yup.number(),
};
export const SamplingCreateRequestValidator = yup.object().shape(SamplingCreateRequestValidatorShape);
export const SamplingCreateRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'newTagName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'method': SamplingMethodStringifyShape,
    'config': SamplingConfigStringifyShape,
    'preselectedTagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'queryTagId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'scoreType': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
        'minLength': 1,
    } as unknown as fastJSON.StringSchema,
    'rowCount': {
        'type': 'number',
    } as unknown as fastJSON.NumberSchema,
};
export const SamplingCreateRequestStringifyShape: ObjectSchema = {
    title: 'SamplingCreateRequest Stringifier',
    type: 'object',
    properties: {
        ...SamplingCreateRequestStringifyShapeProperties
    },
    required: [
        'newTagName',
        'method',
        'config',
    ],
};
export const SamplingCreateRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(SamplingCreateRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const SamplingCreateRequestStringify: (data: SamplingCreateRequest) => string = SamplingCreateRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function SamplingCreateRequestFromJSON(json: any): SamplingCreateRequest {
    return SamplingCreateRequestFromJSONTyped(json, false);
}
export function SamplingCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamplingCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'newTagName': json['newTagName'],
        'method': SamplingMethodFromJSON(json['method']),
        'config': SamplingConfigFromJSON(json['config']),
        'preselectedTagId': !exists(json, 'preselectedTagId') ? undefined : json['preselectedTagId'],
        'queryTagId': !exists(json, 'queryTagId') ? undefined : json['queryTagId'],
        'scoreType': !exists(json, 'scoreType') ? undefined : json['scoreType'],
        'rowCount': !exists(json, 'rowCount') ? undefined : json['rowCount'],
    };
}
export function SamplingCreateRequestToJSON(value?: SamplingCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'newTagName': value.newTagName,
        'method': SamplingMethodToJSON(value.method),
        'config': SamplingConfigToJSON(value.config),
        'preselectedTagId': value.preselectedTagId,
        'queryTagId': value.queryTagId,
        'scoreType': value.scoreType,
        'rowCount': value.rowCount,
    };
}
