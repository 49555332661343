// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import type { ApiErrorResponse, AsyncTaskData, SamplingCreateRequest, } from '../models';
import { ApiErrorResponseFromJSON, ApiErrorResponseToJSON, ApiErrorResponseValidator, AsyncTaskDataFromJSON, AsyncTaskDataToJSON, AsyncTaskDataValidator, SamplingCreateRequestFromJSON, SamplingCreateRequestToJSON, SamplingCreateRequestValidator, } from '../models';
import * as models from '../models';
/* LIGHTLY CUSTOM START -> -> moved from bottom to top:  apiMustache */
/* LIGHTLY CUSTOM END -> moved from bottom to top: apiMustache */
export interface TriggerSamplingByIdRequest {
    samplingCreateRequest: models.SamplingCreateRequest;
    datasetId: string;
    embeddingId: string;
}
/* LIGHTLY CUSTOM START -> apis */
export const TriggerSamplingByIdRequestValidatorShape = {
    'samplingCreateRequest': models.SamplingCreateRequestValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const TriggerSamplingByIdRequestValidator = yup.object().shape(TriggerSamplingByIdRequestValidatorShape);
export interface TriggerSamplingByIdRequestParams {
    datasetId: string;
    embeddingId: string;
}
export function TriggerSamplingByIdRequestParamsSanitizer(json: any): TriggerSamplingByIdRequestParams {
    return {
        'datasetId': json['datasetId'],
        'embeddingId': json['embeddingId'],
    };
}
export const TriggerSamplingByIdRequestParamsValidatorShape = {
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'embeddingId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
};
export const TriggerSamplingByIdRequestParamsValidator = yup.object().shape(TriggerSamplingByIdRequestParamsValidatorShape);
export interface TriggerSamplingByIdRequestQuery {
}
export function TriggerSamplingByIdRequestQuerySanitizer(json: any): TriggerSamplingByIdRequestQuery {
    return {};
}
export const TriggerSamplingByIdRequestQueryValidatorShape = {};
export const TriggerSamplingByIdRequestQueryValidator = yup.object().shape(TriggerSamplingByIdRequestQueryValidatorShape);
export type TriggerSamplingByIdRequestBody = SamplingCreateRequest;
export const TriggerSamplingByIdRequestBodySanitizer = SamplingCreateRequestFromJSON;
export const TriggerSamplingByIdRequestBodyValidator = SamplingCreateRequestValidator;
export const TriggerSamplingByIdTypiaAssertEquals = (input: any) => {
    return ((input: any): TriggerSamplingByIdRequestBody => {
        const __is = (input: any, _exceptionable: boolean = true): input is TriggerSamplingByIdRequestBody => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && ("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) && $io1(input.config, true && _exceptionable)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.scoreType || "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType)) && (undefined === input.rowCount || "number" === typeof input.rowCount) && (3 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["newTagName", "method", "config", "preselectedTagId", "queryTagId", "scoreType", "rowCount"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition, true && _exceptionable)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["stoppingCondition"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io2 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["nSamples", "minDistance"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TriggerSamplingByIdRequestBody => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_SamplingCreateRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string",
                    value: input.newTagName
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && (("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_SamplingCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_SamplingCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.scoreType || "string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_SamplingCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "(string | undefined)",
                    value: input.scoreType
                })) && (undefined === input.rowCount || "number" === typeof input.rowCount || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "(number | undefined)",
                    value: input.rowCount
                })) && (3 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["newTagName", "method", "config", "preselectedTagId", "queryTagId", "scoreType", "rowCount"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["stoppingCondition"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["nSamples", "minDistance"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TriggerSamplingByIdTypiaAssertStringify = (input: TriggerSamplingByIdRequestBody): string => {
    return ((input: any): string => { const assert = (input: any): TriggerSamplingByIdRequestBody => {
        const __is = (input: any): input is TriggerSamplingByIdRequestBody => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.newTagName && 3 <= input.newTagName.length && $is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method) && ("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) && $io1(input.config)) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && $is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId)) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && $is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId)) && (undefined === input.scoreType || "string" === typeof input.scoreType && 1 <= input.scoreType.length && $is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType)) && (undefined === input.rowCount || "number" === typeof input.rowCount && !Number.isNaN(input.rowCount));
            const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
            const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples)) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TriggerSamplingByIdRequestBody => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.newTagName && (3 <= input.newTagName.length || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@minLength 3)",
                    value: input.newTagName
                })) && ($is_custom("pattern_SamplingCreateRequest_newTagName", "string", "", input.newTagName) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string (@pattern_SamplingCreateRequest_newTagName)",
                    value: input.newTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".newTagName",
                    expected: "string",
                    value: input.newTagName
                })) && ("ACTIVE_LEARNING" === input.method || "CORAL" === input.method || "CORESET" === input.method || "RANDOM" === input.method || $guard(_exceptionable, {
                    path: _path + ".method",
                    expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                    value: input.method
                })) && (("object" === typeof input.config && null !== input.config && false === Array.isArray(input.config) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && $ao1(input.config, _path + ".config", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".config",
                    expected: "SamplingConfig",
                    value: input.config
                })) && (undefined === input.preselectedTagId || "string" === typeof input.preselectedTagId && ($is_custom("pattern_SamplingCreateRequest_preselectedTagId", "string", "", input.preselectedTagId) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "string (@pattern_SamplingCreateRequest_preselectedTagId)",
                    value: input.preselectedTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".preselectedTagId",
                    expected: "(string | undefined)",
                    value: input.preselectedTagId
                })) && (undefined === input.queryTagId || "string" === typeof input.queryTagId && ($is_custom("pattern_SamplingCreateRequest_queryTagId", "string", "", input.queryTagId) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "string (@pattern_SamplingCreateRequest_queryTagId)",
                    value: input.queryTagId
                })) || $guard(_exceptionable, {
                    path: _path + ".queryTagId",
                    expected: "(string | undefined)",
                    value: input.queryTagId
                })) && (undefined === input.scoreType || "string" === typeof input.scoreType && (1 <= input.scoreType.length || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@minLength 1)",
                    value: input.scoreType
                })) && ($is_custom("pattern_SamplingCreateRequest_scoreType", "string", "", input.scoreType) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "string (@pattern_SamplingCreateRequest_scoreType)",
                    value: input.scoreType
                })) || $guard(_exceptionable, {
                    path: _path + ".scoreType",
                    expected: "(string | undefined)",
                    value: input.scoreType
                })) && (undefined === input.rowCount || "number" === typeof input.rowCount && !Number.isNaN(input.rowCount) || $guard(_exceptionable, {
                    path: _path + ".rowCount",
                    expected: "(number | undefined)",
                    value: input.rowCount
                }));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.stoppingCondition || ("object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                })) && $ao2(input.stoppingCondition, _path + ".stoppingCondition", true && _exceptionable) || $guard(_exceptionable, {
                    path: _path + ".stoppingCondition",
                    expected: "(SamplingConfigStoppingCondition | undefined)",
                    value: input.stoppingCondition
                });
                const $ao2 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples && !Number.isNaN(input.nSamples) || $guard(_exceptionable, {
                    path: _path + ".nSamples",
                    expected: "(number | undefined)",
                    value: input.nSamples
                })) && (undefined === input.minDistance || "number" === typeof input.minDistance && !Number.isNaN(input.minDistance) || $guard(_exceptionable, {
                    path: _path + ".minDistance",
                    expected: "(number | undefined)",
                    value: input.minDistance
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "SamplingCreateRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TriggerSamplingByIdRequestBody): string => {
        const $io1 = (input: any): boolean => undefined === input.stoppingCondition || "object" === typeof input.stoppingCondition && null !== input.stoppingCondition && false === Array.isArray(input.stoppingCondition) && $io2(input.stoppingCondition);
        const $io2 = (input: any): boolean => (undefined === input.nSamples || "number" === typeof input.nSamples) && (undefined === input.minDistance || "number" === typeof input.minDistance);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.preselectedTagId ? "" : `"preselectedTagId":${undefined !== input.preselectedTagId ? $string(input.preselectedTagId) : undefined},`}${undefined === input.queryTagId ? "" : `"queryTagId":${undefined !== input.queryTagId ? $string(input.queryTagId) : undefined},`}${undefined === input.scoreType ? "" : `"scoreType":${undefined !== input.scoreType ? $string(input.scoreType) : undefined},`}${undefined === input.rowCount ? "" : `"rowCount":${undefined !== input.rowCount ? input.rowCount : undefined},`}"newTagName":${$string(input.newTagName)},"method":${(() => {
            if ("string" === typeof input.method)
                return $string(input.method);
            if ("string" === typeof input.method)
                return "\"" + input.method + "\"";
            $throws({
                expected: "(\"ACTIVE_LEARNING\" | \"CORAL\" | \"CORESET\" | \"RANDOM\")",
                value: input.method
            });
        })()},"config":${$so1(input.config)}}`;
        const $so1 = (input: any): any => `{${$tail(`${undefined === input.stoppingCondition ? "" : `"stoppingCondition":${undefined !== input.stoppingCondition ? $so2(input.stoppingCondition) : undefined}`}`)}}`;
        const $so2 = (input: any): any => `{${$tail(`${undefined === input.nSamples ? "" : `"nSamples":${undefined !== input.nSamples ? input.nSamples : undefined},`}${undefined === input.minDistance ? "" : `"minDistance":${undefined !== input.minDistance ? input.minDistance : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
/*
HANDLERS:



/v1/datasets/{datasetId}/embeddings/{embeddingId}/sampling

export const triggerSamplingByIdFN: RequestHandler<
    TriggerSamplingByIdRequestParams,
    AsyncTaskData | ApiErrorResponse,
    TriggerSamplingByIdRequestBody,
    TriggerSamplingByIdRequestQuery
> = async (req, res) => {
    if (!ensureUserOnReq(req)) {
        return
    }

    const {
        
    } = await validateAndSanitizeStrict(req.body, TriggerSamplingByIdRequestBodySanitizer, TriggerSamplingByIdRequestBodyValidator);
    const {
        
        datasetId,
        embeddingId,
    } =  await validateAndSanitize(req.params, TriggerSamplingByIdRequestParamsSanitizer, TriggerSamplingByIdRequestParamsValidator);
    const {
        
        
        
    } = await validateAndSanitize(req.query, TriggerSamplingByIdRequestQuerySanitizer, TriggerSamplingByIdRequestQueryValidator);



    
    const schema = ....

    const { _id: id, ...rest } = schema;
    return fastJSONResponse(
        res,
        AsyncTaskDataStringify,
        validateAndRespond({ id, ...rest }, AsyncTaskDataFromJSON, AsyncTaskDataValidator),
    );
}


*/
/* LIGHTLY CUSTOM END -> api */
/**
 * SamplingsApi - interface
 *
 * @export
 * @interface SamplingsApiInterface
 */
export interface SamplingsApiInterface {
    /**
     * Trigger a sampling on a specific tag of a dataset with specific prior uploaded csv embedding
     * @param {SamplingCreateRequest} samplingCreateRequest
     * @param {string} datasetId ObjectId of the dataset
     * @param {string} embeddingId ObjectId of the embedding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SamplingsApiInterface
     */
    triggerSamplingByIdRaw(requestParameters: TriggerSamplingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncTaskData>>;
    /**
     * Trigger a sampling on a specific tag of a dataset with specific prior uploaded csv embedding
     */
    triggerSamplingById(requestParameters: TriggerSamplingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncTaskData>;
}
/**
 *
 */
export class SamplingsApi extends runtime.BaseAPI implements SamplingsApiInterface {
    /**
     * Trigger a sampling on a specific tag of a dataset with specific prior uploaded csv embedding
     */
    async triggerSamplingByIdRaw(requestParameters: TriggerSamplingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AsyncTaskData>> {
        if (requestParameters.samplingCreateRequest === null || requestParameters.samplingCreateRequest === undefined) {
            throw new runtime.RequiredError('samplingCreateRequest', 'Required parameter requestParameters.samplingCreateRequest was null or undefined when calling triggerSamplingById.');
        }
        if (requestParameters.datasetId === null || requestParameters.datasetId === undefined) {
            throw new runtime.RequiredError('datasetId', 'Required parameter requestParameters.datasetId was null or undefined when calling triggerSamplingById.');
        }
        if (requestParameters.embeddingId === null || requestParameters.embeddingId === undefined) {
            throw new runtime.RequiredError('embeddingId', 'Required parameter requestParameters.embeddingId was null or undefined when calling triggerSamplingById.');
        }
        const queryParameters: any = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("auth0Bearer", []);
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        if (this.configuration && this.configuration.apiKey) {
            queryParameters["token"] = this.configuration.apiKey("token"); // ApiKeyAuth authentication
        }
        const response = await this.request({
            path: `/v1/datasets/{datasetId}/embeddings/{embeddingId}/sampling`.replace(`{${"datasetId"}}`, encodeURIComponent(String(requestParameters.datasetId))).replace(`{${"embeddingId"}}`, encodeURIComponent(String(requestParameters.embeddingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SamplingCreateRequestToJSON(requestParameters.samplingCreateRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AsyncTaskDataFromJSON(jsonValue));
    }
    /**
     * Trigger a sampling on a specific tag of a dataset with specific prior uploaded csv embedding
     */
    async triggerSamplingById(requestParameters: TriggerSamplingByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AsyncTaskData> {
        const response = await this.triggerSamplingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
