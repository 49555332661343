/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelOneOf.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options } from 'fast-json-stringify';
import { CreateEntityResponse, instanceOfCreateEntityResponse, CreateEntityResponseFromJSON, CreateEntityResponseFromJSONTyped, CreateEntityResponseToJSON, } from './CreateEntityResponse';
import { TagBitMaskResponse, instanceOfTagBitMaskResponse, TagBitMaskResponseFromJSON, TagBitMaskResponseFromJSONTyped, TagBitMaskResponseToJSON, } from './TagBitMaskResponse';
/**
 * @type TagArithmeticsResponse
 *
 * @export
 */
export type TagArithmeticsResponse = CreateEntityResponse | TagBitMaskResponse;
/* LIGHTLY CUSTOM START -> modelOneOf */
import { CreateEntityResponseStringifyShape, CreateEntityResponseStringifyShapeProperties, CreateEntityResponseValidator } from './CreateEntityResponse';
import { TagBitMaskResponseStringifyShape, TagBitMaskResponseStringifyShapeProperties, TagBitMaskResponseValidator } from './TagBitMaskResponse';
export const TagArithmeticsResponseTypiaAssertEquals = (input: any) => {
    return ((input: any): TagArithmeticsResponse => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagArithmeticsResponse => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_CreateEntityResponse_id", "string", "", input.id) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $io1 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.bitMaskData && $is_custom("pattern_TagBitMaskResponse_bitMaskData", "string", "", input.bitMaskData) && (1 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["bitMaskData"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            const $iu0 = (input: any, _exceptionable: boolean = true): any => (() => {
                if (undefined !== input.id)
                    return $io0(input, true && _exceptionable);
                if (undefined !== input.bitMaskData)
                    return $io1(input, true && _exceptionable);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagArithmeticsResponse => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_CreateEntityResponse_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_CreateEntityResponse_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.bitMaskData && ($is_custom("pattern_TagBitMaskResponse_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagBitMaskResponse_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                })) && (1 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["bitMaskData"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if (undefined !== input.id)
                        return $ao0(input, _path, true && _exceptionable);
                    if (undefined !== input.bitMaskData)
                        return $ao1(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "(CreateEntityResponse | TagBitMaskResponse)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "(CreateEntityResponse | TagBitMaskResponse)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "(CreateEntityResponse | TagBitMaskResponse)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagArithmeticsResponseTypiaAssertStringify = (input: TagArithmeticsResponse): string => {
    return ((input: any): string => { const assert = (input: any): TagArithmeticsResponse => {
        const __is = (input: any): input is TagArithmeticsResponse => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_CreateEntityResponse_id", "string", "", input.id);
            const $io1 = (input: any): boolean => "string" === typeof input.bitMaskData && $is_custom("pattern_TagBitMaskResponse_bitMaskData", "string", "", input.bitMaskData);
            const $iu0 = (input: any): any => (() => {
                if (undefined !== input.id)
                    return $io0(input);
                if (undefined !== input.bitMaskData)
                    return $io1(input);
                return false;
            })();
            return "object" === typeof input && null !== input && $iu0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagArithmeticsResponse => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.id && ($is_custom("pattern_CreateEntityResponse_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_CreateEntityResponse_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                });
                const $ao1 = (input: any, _path: string, _exceptionable: boolean = true): boolean => "string" === typeof input.bitMaskData && ($is_custom("pattern_TagBitMaskResponse_bitMaskData", "string", "", input.bitMaskData) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string (@pattern_TagBitMaskResponse_bitMaskData)",
                    value: input.bitMaskData
                })) || $guard(_exceptionable, {
                    path: _path + ".bitMaskData",
                    expected: "string",
                    value: input.bitMaskData
                });
                const $au0 = (input: any, _path: string, _exceptionable: boolean = true): any => (() => {
                    if (undefined !== input.id)
                        return $ao0(input, _path, true && _exceptionable);
                    if (undefined !== input.bitMaskData)
                        return $ao1(input, _path, true && _exceptionable);
                    return $guard(_exceptionable, {
                        path: _path,
                        expected: "(CreateEntityResponse | TagBitMaskResponse)",
                        value: input
                    });
                })();
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "(CreateEntityResponse | TagBitMaskResponse)",
                    value: input
                })) && $au0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "(CreateEntityResponse | TagBitMaskResponse)",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagArithmeticsResponse): string => {
        const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_CreateEntityResponse_id", "string", "", input.id);
        const $io1 = (input: any): boolean => "string" === typeof input.bitMaskData && $is_custom("pattern_TagBitMaskResponse_bitMaskData", "string", "", input.bitMaskData);
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{"id":${$string(input.id)}}`;
        const $so1 = (input: any): any => `{"bitMaskData":${$string(input.bitMaskData)}}`;
        const $su0 = (input: any): any => (() => {
            if (undefined !== input.id)
                return $so0(input);
            if (undefined !== input.bitMaskData)
                return $so1(input);
            $throws({
                expected: "(CreateEntityResponse | TagBitMaskResponse)",
                value: input
            });
        })();
        return $su0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagArithmeticsResponseValidatorShape = yup.mixed().test('TagArithmeticsResponseShapeTest', 'TagArithmeticsResponse needs to be one of CreateEntityResponse TagBitMaskResponse ', (data: CreateEntityResponse | TagBitMaskResponse) => {
    return CreateEntityResponseValidator.isValidSync(data) || TagBitMaskResponseValidator.isValidSync(data);
});
export const TagArithmeticsResponseValidator = TagArithmeticsResponseValidatorShape as any;
/* LIGHTLY CUSTOM END -> oneOf */
export function TagArithmeticsResponseFromJSON(json: any): TagArithmeticsResponse {
    return TagArithmeticsResponseFromJSONTyped(json, false);
}
export function TagArithmeticsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagArithmeticsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...CreateEntityResponseFromJSONTyped(json, true), ...TagBitMaskResponseFromJSONTyped(json, true) };
}
export function TagArithmeticsResponseToJSON(value?: TagArithmeticsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    if (instanceOfCreateEntityResponse(value)) {
        return CreateEntityResponseToJSON(value as CreateEntityResponse);
    }
    if (instanceOfTagBitMaskResponse(value)) {
        return TagBitMaskResponseToJSON(value as TagBitMaskResponse);
    }
    return {};
}
