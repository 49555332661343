/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { JobResultType } from './JobResultType';
import { JobResultTypeFromJSON, JobResultTypeFromJSONTyped, JobResultTypeToJSON, } from './JobResultType';
import type { JobState } from './JobState';
import { JobStateFromJSON, JobStateFromJSONTyped, JobStateToJSON, } from './JobState';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface JobsData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_JobsData_id")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_JobsData_datasetId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface JobsData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof JobsData
        
        
    * @pattern_JobsData_id
         */
    id: string;
    /**
     *
     * #type string
     * #memberof JobsData
        
        
         */
    jobId: string;
    /**
     *
     * #type JobResultType
     * #memberof JobsData
        
        
         */
    jobType: JobResultType;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof JobsData
        
        
    * @pattern_JobsData_datasetId
         */
    datasetId?: string;
    /**
     *
     * #type JobState
     * #memberof JobsData
        
        
         */
    status: JobState;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof JobsData
        
    * @minimum 0
            
         */
    finishedAt?: number;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof JobsData
        
    * @minimum 0
            
         */
    createdAt: number;
}
/**
 * Check if a given object implements the JobsData interface.
 */
export function instanceOfJobsData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "jobId" in value;
    isInstance = isInstance && "jobType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { JobResultTypeValidator, JobResultTypeStringifyShape, JobResultTypeStringifyShapeProperties, } from './JobResultType';
import { JobStateValidator, JobStateStringifyShape, JobStateStringifyShapeProperties, } from './JobState';
export const JobsDataTypiaAssertEquals = (input: any) => {
    return ((input: any): JobsData => {
        const __is = (input: any, _exceptionable: boolean = true): input is JobsData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.id && $is_custom("pattern_JobsData_id", "string", "", input.id) && "string" === typeof input.jobId && ("DATASET_PROCESSING" === input.jobType || "IMAGEMETA" === input.jobType || "EMBEDDING" === input.jobType || "EMBEDDINGS2D" === input.jobType || "SAMPLING" === input.jobType) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_JobsData_datasetId", "string", "", input.datasetId)) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && 0 <= input.finishedAt) && ("number" === typeof input.createdAt && 0 <= input.createdAt) && (5 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["id", "jobId", "jobType", "datasetId", "status", "finishedAt", "createdAt"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobsData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_JobsData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_JobsData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.jobId || $guard(_exceptionable, {
                    path: _path + ".jobId",
                    expected: "string",
                    value: input.jobId
                })) && ("DATASET_PROCESSING" === input.jobType || "IMAGEMETA" === input.jobType || "EMBEDDING" === input.jobType || "EMBEDDINGS2D" === input.jobType || "SAMPLING" === input.jobType || $guard(_exceptionable, {
                    path: _path + ".jobType",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.jobType
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_JobsData_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_JobsData_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status || $guard(_exceptionable, {
                    path: _path + ".status",
                    expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                    value: input.status
                })) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && (0 <= input.finishedAt || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "number (@minimum 0)",
                    value: input.finishedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "(number | undefined)",
                    value: input.finishedAt
                })) && ("number" === typeof input.createdAt && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                })) && (5 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["id", "jobId", "jobType", "datasetId", "status", "finishedAt", "createdAt"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobsData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobsData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const JobsDataTypiaAssertStringify = (input: JobsData): string => {
    return ((input: any): string => { const assert = (input: any): JobsData => {
        const __is = (input: any): input is JobsData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.id && $is_custom("pattern_JobsData_id", "string", "", input.id) && "string" === typeof input.jobId && ("DATASET_PROCESSING" === input.jobType || "IMAGEMETA" === input.jobType || "EMBEDDING" === input.jobType || "EMBEDDINGS2D" === input.jobType || "SAMPLING" === input.jobType) && (undefined === input.datasetId || "string" === typeof input.datasetId && $is_custom("pattern_JobsData_datasetId", "string", "", input.datasetId)) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && !Number.isNaN(input.finishedAt) && 0 <= input.finishedAt) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && 0 <= input.createdAt);
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is JobsData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.id && ($is_custom("pattern_JobsData_id", "string", "", input.id) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string (@pattern_JobsData_id)",
                    value: input.id
                })) || $guard(_exceptionable, {
                    path: _path + ".id",
                    expected: "string",
                    value: input.id
                })) && ("string" === typeof input.jobId || $guard(_exceptionable, {
                    path: _path + ".jobId",
                    expected: "string",
                    value: input.jobId
                })) && ("DATASET_PROCESSING" === input.jobType || "IMAGEMETA" === input.jobType || "EMBEDDING" === input.jobType || "EMBEDDINGS2D" === input.jobType || "SAMPLING" === input.jobType || $guard(_exceptionable, {
                    path: _path + ".jobType",
                    expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                    value: input.jobType
                })) && (undefined === input.datasetId || "string" === typeof input.datasetId && ($is_custom("pattern_JobsData_datasetId", "string", "", input.datasetId) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "string (@pattern_JobsData_datasetId)",
                    value: input.datasetId
                })) || $guard(_exceptionable, {
                    path: _path + ".datasetId",
                    expected: "(string | undefined)",
                    value: input.datasetId
                })) && ("UNKNOWN" === input.status || "WAITING" === input.status || "RUNNING" === input.status || "FAILED" === input.status || "FINISHED" === input.status || $guard(_exceptionable, {
                    path: _path + ".status",
                    expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                    value: input.status
                })) && (undefined === input.finishedAt || "number" === typeof input.finishedAt && !Number.isNaN(input.finishedAt) && (0 <= input.finishedAt || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "number (@minimum 0)",
                    value: input.finishedAt
                })) || $guard(_exceptionable, {
                    path: _path + ".finishedAt",
                    expected: "(number | undefined)",
                    value: input.finishedAt
                })) && ("number" === typeof input.createdAt && !Number.isNaN(input.createdAt) && (0 <= input.createdAt || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number (@minimum 0)",
                    value: input.createdAt
                })) || $guard(_exceptionable, {
                    path: _path + ".createdAt",
                    expected: "number",
                    value: input.createdAt
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "JobsData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "JobsData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: JobsData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.datasetId ? "" : `"datasetId":${undefined !== input.datasetId ? $string(input.datasetId) : undefined},`}${undefined === input.finishedAt ? "" : `"finishedAt":${undefined !== input.finishedAt ? input.finishedAt : undefined},`}"id":${$string(input.id)},"jobId":${$string(input.jobId)},"jobType":${(() => {
            if ("string" === typeof input.jobType)
                return $string(input.jobType);
            if ("string" === typeof input.jobType)
                return "\"" + input.jobType + "\"";
            $throws({
                expected: "(\"DATASET_PROCESSING\" | \"EMBEDDING\" | \"EMBEDDINGS2D\" | \"IMAGEMETA\" | \"SAMPLING\")",
                value: input.jobType
            });
        })()},"status":${(() => {
            if ("string" === typeof input.status)
                return $string(input.status);
            if ("string" === typeof input.status)
                return "\"" + input.status + "\"";
            $throws({
                expected: "(\"FAILED\" | \"FINISHED\" | \"RUNNING\" | \"UNKNOWN\" | \"WAITING\")",
                value: input.status
            });
        })()},"createdAt":${input.createdAt}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const JobsDataValidatorShape = {
    'id': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'jobId': yup.string().defined(),
    'jobType': JobResultTypeValidator.defined(),
    'datasetId': yup.string().matches(/^[a-f0-9]{24}$/),
    'status': JobStateValidator.defined(),
    'finishedAt': yup.number().min(0),
    'createdAt': yup.number().min(0).defined(),
};
export const JobsDataValidator = yup.object().shape(JobsDataValidatorShape);
export const JobsDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'id': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'jobId': {
        'type': 'string',
    } as unknown as fastJSON.StringSchema,
    'jobType': JobResultTypeStringifyShape,
    'datasetId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'status': JobStateStringifyShape,
    'finishedAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'createdAt': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
};
export const JobsDataStringifyShape: ObjectSchema = {
    title: 'JobsData Stringifier',
    type: 'object',
    properties: {
        ...JobsDataStringifyShapeProperties
    },
    required: [
        'id',
        'jobId',
        'jobType',
        'status',
        'createdAt',
    ],
};
export const JobsDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(JobsDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const JobsDataStringify: (data: JobsData) => string = JobsDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function JobsDataFromJSON(json: any): JobsData {
    return JobsDataFromJSONTyped(json, false);
}
export function JobsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobsData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'id': json['id'],
        'jobId': json['jobId'],
        'jobType': JobResultTypeFromJSON(json['jobType']),
        'datasetId': !exists(json, 'datasetId') ? undefined : json['datasetId'],
        'status': JobStateFromJSON(json['status']),
        'finishedAt': !exists(json, 'finishedAt') ? undefined : json['finishedAt'],
        'createdAt': json['createdAt'],
    };
}
export function JobsDataToJSON(value?: JobsData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'jobId': value.jobId,
        'jobType': JobResultTypeToJSON(value.jobType),
        'datasetId': value.datasetId,
        'status': JobStateToJSON(value.status),
        'finishedAt': value.finishedAt,
        'createdAt': value.createdAt,
    };
}
