/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface CropData
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_CropData_parentId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_CropData_predictionTaskName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9_+=,.@:\/-]*$/);
    return (value: string) => !!regex.exec(value);
});
export interface CropData {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof CropData
        
        
    * @pattern_CropData_parentId
         */
    parentId: string;
    /**
     * unix timestamp in milliseconds
     * #type number
     * #memberof CropData
        
    * @minimum 0
            
         */
    predictionUUIDTimestamp: number;
    /**
     * the index of this crop within all found prediction singletons of a sampleId (the parentId)
     * #type number
     * #memberof CropData
        * @type int
    * @minimum 0
            
         */
    predictionIndex: number;
    /**
     * Since we sometimes stitch together SelectionInputTask+ActiveLearningScoreType, they need to follow the same specs of ActiveLearningScoreType. However, this can be an empty string due to internal logic (no minLength). Also v2config.filespecs.ts has this pattern for predictionTaskJSONSchema as well.
     * #type string
     * #memberof CropData
        
        
    * @pattern_CropData_predictionTaskName
         */
    predictionTaskName: string;
    /**
     * The id of the category. Needs to be a positive integer but can be any integer (gaps are allowed, does not need to be sequential)
     * #type number
     * #memberof CropData
        
    * @minimum 0
            
         */
    predictionTaskCategoryId: number;
    /**
     * the score for the prediction task which yielded this crop
     * #type number
     * #memberof CropData
        
    * @minimum 0
        * @maximum 1
        
         */
    predictionTaskScore: number;
}
/**
 * Check if a given object implements the CropData interface.
 */
export function instanceOfCropData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentId" in value;
    isInstance = isInstance && "predictionUUIDTimestamp" in value;
    isInstance = isInstance && "predictionIndex" in value;
    isInstance = isInstance && "predictionTaskName" in value;
    isInstance = isInstance && "predictionTaskCategoryId" in value;
    isInstance = isInstance && "predictionTaskScore" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const CropDataTypiaAssertEquals = (input: any) => {
    return ((input: any): CropData => {
        const __is = (input: any, _exceptionable: boolean = true): input is CropData => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.parentId && $is_custom("pattern_CropData_parentId", "string", "", input.parentId) && ("number" === typeof input.predictionUUIDTimestamp && 0 <= input.predictionUUIDTimestamp) && ("number" === typeof input.predictionIndex && Math.floor(input.predictionIndex) === input.predictionIndex && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647) && 0 <= input.predictionIndex) && ("string" === typeof input.predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName)) && ("number" === typeof input.predictionTaskCategoryId && 0 <= input.predictionTaskCategoryId) && ("number" === typeof input.predictionTaskScore && 0 <= input.predictionTaskScore && 1 >= input.predictionTaskScore) && (6 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CropData => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                })) && (6 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["parentId", "predictionUUIDTimestamp", "predictionIndex", "predictionTaskName", "predictionTaskCategoryId", "predictionTaskScore"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CropData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CropData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const CropDataTypiaAssertStringify = (input: CropData): string => {
    return ((input: any): string => { const assert = (input: any): CropData => {
        const __is = (input: any): input is CropData => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            return "object" === typeof input && null !== input && ("string" === typeof (input as any).parentId && $is_custom("pattern_CropData_parentId", "string", "", (input as any).parentId) && ("number" === typeof (input as any).predictionUUIDTimestamp && !Number.isNaN((input as any).predictionUUIDTimestamp) && 0 <= (input as any).predictionUUIDTimestamp) && ("number" === typeof (input as any).predictionIndex && !Number.isNaN((input as any).predictionIndex) && Math.floor((input as any).predictionIndex) === (input as any).predictionIndex && (-2147483648 <= (input as any).predictionIndex && (input as any).predictionIndex <= 2147483647) && 0 <= (input as any).predictionIndex) && ("string" === typeof (input as any).predictionTaskName && $is_custom("pattern_CropData_predictionTaskName", "string", "", (input as any).predictionTaskName)) && ("number" === typeof (input as any).predictionTaskCategoryId && !Number.isNaN((input as any).predictionTaskCategoryId) && 0 <= (input as any).predictionTaskCategoryId) && ("number" === typeof (input as any).predictionTaskScore && 0 <= (input as any).predictionTaskScore && 1 >= (input as any).predictionTaskScore));
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is CropData => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.parentId && ($is_custom("pattern_CropData_parentId", "string", "", input.parentId) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string (@pattern_CropData_parentId)",
                    value: input.parentId
                })) || $guard(_exceptionable, {
                    path: _path + ".parentId",
                    expected: "string",
                    value: input.parentId
                })) && ("number" === typeof input.predictionUUIDTimestamp && !Number.isNaN(input.predictionUUIDTimestamp) && (0 <= input.predictionUUIDTimestamp || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number (@minimum 0)",
                    value: input.predictionUUIDTimestamp
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionUUIDTimestamp",
                    expected: "number",
                    value: input.predictionUUIDTimestamp
                })) && ("number" === typeof input.predictionIndex && !Number.isNaN(input.predictionIndex) && (Math.floor(input.predictionIndex) === input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (-2147483648 <= input.predictionIndex && input.predictionIndex <= 2147483647 || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@type int)",
                    value: input.predictionIndex
                })) && (0 <= input.predictionIndex || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number (@minimum 0)",
                    value: input.predictionIndex
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionIndex",
                    expected: "number",
                    value: input.predictionIndex
                })) && ("string" === typeof input.predictionTaskName && ($is_custom("pattern_CropData_predictionTaskName", "string", "", input.predictionTaskName) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string (@pattern_CropData_predictionTaskName)",
                    value: input.predictionTaskName
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskName",
                    expected: "string",
                    value: input.predictionTaskName
                })) && ("number" === typeof input.predictionTaskCategoryId && !Number.isNaN(input.predictionTaskCategoryId) && (0 <= input.predictionTaskCategoryId || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskCategoryId
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskCategoryId",
                    expected: "number",
                    value: input.predictionTaskCategoryId
                })) && ("number" === typeof input.predictionTaskScore && (0 <= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@minimum 0)",
                    value: input.predictionTaskScore
                })) && (1 >= input.predictionTaskScore || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number (@maximum 1)",
                    value: input.predictionTaskScore
                })) || $guard(_exceptionable, {
                    path: _path + ".predictionTaskScore",
                    expected: "number",
                    value: input.predictionTaskScore
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "CropData",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "CropData",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: CropData): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        return `{"parentId":${$string((input as any).parentId)},"predictionUUIDTimestamp":${(input as any).predictionUUIDTimestamp},"predictionIndex":${(input as any).predictionIndex},"predictionTaskName":${$string((input as any).predictionTaskName)},"predictionTaskCategoryId":${(input as any).predictionTaskCategoryId},"predictionTaskScore":${(input as any).predictionTaskScore}}`;
    }; return stringify(assert(input)); })(input);
};
export const CropDataValidatorShape = {
    'parentId': yup.string().matches(/^[a-f0-9]{24}$/).defined(),
    'predictionUUIDTimestamp': yup.number().min(0).defined(),
    'predictionIndex': yup.number().integer().min(0).defined(),
    'predictionTaskName': yup.string().matches(/^[a-zA-Z0-9_+=,.@:\/-]*$/).defined(),
    'predictionTaskCategoryId': yup.number().min(0).defined(),
    'predictionTaskScore': yup.number().min(0).max(1).defined(),
};
export const CropDataValidator = yup.object().shape(CropDataValidatorShape);
export const CropDataStringifyShapeProperties: ObjectSchema['properties'] = {
    'parentId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'predictionUUIDTimestamp': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'predictionIndex': {
        'type': 'integer',
        'minimum': 0,
    } as unknown as fastJSON.IntegerSchema,
    'predictionTaskName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9_+&#x3D;,.@:\/-]*$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
    'predictionTaskCategoryId': {
        'type': 'number',
        'minimum': 0,
    } as unknown as fastJSON.NumberSchema,
    'predictionTaskScore': {
        'type': 'number',
        'minimum': 0,
        'maximum': 1,
    } as unknown as fastJSON.NumberSchema,
};
export const CropDataStringifyShape: ObjectSchema = {
    title: 'CropData Stringifier',
    type: 'object',
    properties: {
        ...CropDataStringifyShapeProperties
    },
    required: [
        'parentId',
        'predictionUUIDTimestamp',
        'predictionIndex',
        'predictionTaskName',
        'predictionTaskCategoryId',
        'predictionTaskScore',
    ],
};
export const CropDataStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(CropDataStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const CropDataStringify: (data: CropData) => string = CropDataStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function CropDataFromJSON(json: any): CropData {
    return CropDataFromJSONTyped(json, false);
}
export function CropDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CropData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'parentId': json['parentId'],
        'predictionUUIDTimestamp': json['predictionUUIDTimestamp'],
        'predictionIndex': json['predictionIndex'],
        'predictionTaskName': json['predictionTaskName'],
        'predictionTaskCategoryId': json['predictionTaskCategoryId'],
        'predictionTaskScore': json['predictionTaskScore'],
    };
}
export function CropDataToJSON(value?: CropData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'parentId': value.parentId,
        'predictionUUIDTimestamp': value.predictionUUIDTimestamp,
        'predictionIndex': value.predictionIndex,
        'predictionTaskName': value.predictionTaskName,
        'predictionTaskCategoryId': value.predictionTaskCategoryId,
        'predictionTaskScore': value.predictionTaskScore,
    };
}
