/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagChangeDataInitial
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_TagChangeDataInitial_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface TagChangeDataInitial {
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagChangeDataInitial
        
        
    * @pattern_TagChangeDataInitial_runId
         */
    runId?: string;
}
/**
 * Check if a given object implements the TagChangeDataInitial interface.
 */
export function instanceOfTagChangeDataInitial(value: object): boolean {
    let isInstance = true;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
export const TagChangeDataInitialTypiaAssertEquals = (input: any) => {
    return ((input: any): TagChangeDataInitial => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagChangeDataInitial => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId)) && (0 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataInitial => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (0 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataInitial",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataInitial",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagChangeDataInitialTypiaAssertStringify = (input: TagChangeDataInitial): string => {
    return ((input: any): string => { const assert = (input: any): TagChangeDataInitial => {
        const __is = (input: any): input is TagChangeDataInitial => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId);
            return "object" === typeof input && null !== input && false === Array.isArray(input) && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagChangeDataInitial => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagChangeDataInitial_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagChangeDataInitial_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                });
                return ("object" === typeof input && null !== input && false === Array.isArray(input) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataInitial",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagChangeDataInitial",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagChangeDataInitial): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $tail = (typia.createAssertStringify as any).tail;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${$tail(`${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined}`}`)}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagChangeDataInitialValidatorShape = {
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const TagChangeDataInitialValidator = yup.object().shape(TagChangeDataInitialValidatorShape);
export const TagChangeDataInitialStringifyShapeProperties: ObjectSchema['properties'] = {
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const TagChangeDataInitialStringifyShape: ObjectSchema = {
    title: 'TagChangeDataInitial Stringifier',
    type: 'object',
    properties: {
        ...TagChangeDataInitialStringifyShapeProperties
    },
    required: [],
};
export const TagChangeDataInitialStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagChangeDataInitialStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagChangeDataInitialStringify: (data: TagChangeDataInitial) => string = TagChangeDataInitialStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagChangeDataInitialFromJSON(json: any): TagChangeDataInitial {
    return TagChangeDataInitialFromJSONTyped(json, false);
}
export function TagChangeDataInitialFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagChangeDataInitial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
    };
}
export function TagChangeDataInitialToJSON(value?: TagChangeDataInitial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'runId': value.runId,
    };
}
