/* tslint:disable */
/* eslint-disable */
/**
 * Lightly API
 * Lightly.ai enables you to do self-supervised learning in an easy and intuitive way. The lightly.ai OpenAPI spec defines how one can interact with our REST API to unleash the full potential of lightly.ai
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@lightly.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
// synched 02.02.2023
import * as yup from 'yup';
import typia from 'typia';
import * as fastJSON from 'fast-json-stringify';
import { Options, ArraySchema, ObjectSchema } from 'fast-json-stringify';
import { exists, mapValues } from '../runtime';
import type { TagCreator } from './TagCreator';
import { TagCreatorFromJSON, TagCreatorFromJSONTyped, TagCreatorToJSON, } from './TagCreator';
/*
causes circular dependendies
*/
// SRC: https://github.com/OpenAPITools/openapi-generator/blob/master/modules/openapi-generator/src/main/resources/typescript-fetch/modelGenericInterfaces.mustache
// synced: 17.07.2023
/**
 *
 * @export
 * @interface TagUpsizeRequest
 *
 */
// create custom validators for the regexes
typia.customValidators.insert("pattern_TagUpsizeRequest_upsizeTagName")("string")(() => {
    const regex = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/);
    return (value: string) => !!regex.exec(value);
});
typia.customValidators.insert("pattern_TagUpsizeRequest_runId")("string")(() => {
    const regex = new RegExp(/^[a-f0-9]{24}$/);
    return (value: string) => !!regex.exec(value);
});
export interface TagUpsizeRequest {
    /**
     * The name of the tag
     * #type string
     * #memberof TagUpsizeRequest
        
        
    * @pattern_TagUpsizeRequest_upsizeTagName
    * @minLength 3
         */
    upsizeTagName: string;
    /**
     *
     * #type TagCreator
     * #memberof TagUpsizeRequest
        
        
         */
    upsizeTagCreator: TagCreator;
    /**
     * MongoDB ObjectId
     * #type string
     * #memberof TagUpsizeRequest
        
        
    * @pattern_TagUpsizeRequest_runId
         */
    runId?: string;
}
/**
 * Check if a given object implements the TagUpsizeRequest interface.
 */
export function instanceOfTagUpsizeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "upsizeTagName" in value;
    isInstance = isInstance && "upsizeTagCreator" in value;
    return isInstance;
}
/* LIGHTLY CUSTOM START -> modelGeneric */
import { TagCreatorValidator, TagCreatorStringifyShape, TagCreatorStringifyShapeProperties, } from './TagCreator';
export const TagUpsizeRequestTypiaAssertEquals = (input: any) => {
    return ((input: any): TagUpsizeRequest => {
        const __is = (input: any, _exceptionable: boolean = true): input is TagUpsizeRequest => {
            const $is_custom = (typia.createAssertEquals as any).is_custom;
            const $io0 = (input: any, _exceptionable: boolean = true): boolean => "string" === typeof input.upsizeTagName && 3 <= input.upsizeTagName.length && $is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId)) && (2 === Object.keys(input).length || Object.keys(input).every((key: any) => {
                if (["upsizeTagName", "upsizeTagCreator", "runId"].some((prop: any) => key === prop))
                    return true;
                const value = input[key];
                if (undefined === value)
                    return true;
                return false;
            }));
            return "object" === typeof input && null !== input && $io0(input, true);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagUpsizeRequest => {
                const $guard = (typia.createAssertEquals as any).guard;
                const $is_custom = (typia.createAssertEquals as any).is_custom;
                const $join = (typia.createAssertEquals as any).join;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.upsizeTagName && (3 <= input.upsizeTagName.length || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@minLength 3)",
                    value: input.upsizeTagName
                })) && ($is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@pattern_TagUpsizeRequest_upsizeTagName)",
                    value: input.upsizeTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string",
                    value: input.upsizeTagName
                })) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator || $guard(_exceptionable, {
                    path: _path + ".upsizeTagCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                    value: input.upsizeTagCreator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagUpsizeRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                })) && (2 === Object.keys(input).length || (false === _exceptionable || Object.keys(input).every((key: any) => {
                    if (["upsizeTagName", "upsizeTagCreator", "runId"].some((prop: any) => key === prop))
                        return true;
                    const value = input[key];
                    if (undefined === value)
                        return true;
                    return $guard(_exceptionable, {
                        path: _path + $join(key),
                        expected: "undefined",
                        value: value
                    });
                })));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    })(input);
};
export const TagUpsizeRequestTypiaAssertStringify = (input: TagUpsizeRequest): string => {
    return ((input: any): string => { const assert = (input: any): TagUpsizeRequest => {
        const __is = (input: any): input is TagUpsizeRequest => {
            const $is_custom = (typia.createAssertStringify as any).is_custom;
            const $io0 = (input: any): boolean => "string" === typeof input.upsizeTagName && 3 <= input.upsizeTagName.length && $is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator) && (undefined === input.runId || "string" === typeof input.runId && $is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId));
            return "object" === typeof input && null !== input && $io0(input);
        };
        if (false === __is(input))
            ((input: any, _path: string, _exceptionable: boolean = true): input is TagUpsizeRequest => {
                const $guard = (typia.createAssertStringify as any).guard;
                const $is_custom = (typia.createAssertStringify as any).is_custom;
                const $ao0 = (input: any, _path: string, _exceptionable: boolean = true): boolean => ("string" === typeof input.upsizeTagName && (3 <= input.upsizeTagName.length || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@minLength 3)",
                    value: input.upsizeTagName
                })) && ($is_custom("pattern_TagUpsizeRequest_upsizeTagName", "string", "", input.upsizeTagName) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string (@pattern_TagUpsizeRequest_upsizeTagName)",
                    value: input.upsizeTagName
                })) || $guard(_exceptionable, {
                    path: _path + ".upsizeTagName",
                    expected: "string",
                    value: input.upsizeTagName
                })) && ("UNKNOWN" === input.upsizeTagCreator || "USER_WEBAPP" === input.upsizeTagCreator || "USER_PIP" === input.upsizeTagCreator || "USER_PIP_LIGHTLY_MAGIC" === input.upsizeTagCreator || "USER_WORKER" === input.upsizeTagCreator || "SAMPLER_ACTIVE_LEARNING" === input.upsizeTagCreator || "SAMPLER_CORAL" === input.upsizeTagCreator || "SAMPLER_CORESET" === input.upsizeTagCreator || "SAMPLER_RANDOM" === input.upsizeTagCreator || $guard(_exceptionable, {
                    path: _path + ".upsizeTagCreator",
                    expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                    value: input.upsizeTagCreator
                })) && (undefined === input.runId || "string" === typeof input.runId && ($is_custom("pattern_TagUpsizeRequest_runId", "string", "", input.runId) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "string (@pattern_TagUpsizeRequest_runId)",
                    value: input.runId
                })) || $guard(_exceptionable, {
                    path: _path + ".runId",
                    expected: "(string | undefined)",
                    value: input.runId
                }));
                return ("object" === typeof input && null !== input || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                })) && $ao0(input, _path + "", true) || $guard(true, {
                    path: _path + "",
                    expected: "TagUpsizeRequest",
                    value: input
                });
            })(input, "$input", true);
        return input;
    }; const stringify = (input: TagUpsizeRequest): string => {
        const $string = (typia.createAssertStringify as any).string;
        const $throws = (typia.createAssertStringify as any).throws;
        const $is_custom = (typia.createAssertStringify as any).is_custom;
        const $so0 = (input: any): any => `{${undefined === input.runId ? "" : `"runId":${undefined !== input.runId ? $string(input.runId) : undefined},`}"upsizeTagName":${$string(input.upsizeTagName)},"upsizeTagCreator":${(() => {
            if ("string" === typeof input.upsizeTagCreator)
                return $string(input.upsizeTagCreator);
            if ("string" === typeof input.upsizeTagCreator)
                return "\"" + input.upsizeTagCreator + "\"";
            $throws({
                expected: "(\"SAMPLER_ACTIVE_LEARNING\" | \"SAMPLER_CORAL\" | \"SAMPLER_CORESET\" | \"SAMPLER_RANDOM\" | \"UNKNOWN\" | \"USER_PIP\" | \"USER_PIP_LIGHTLY_MAGIC\" | \"USER_WEBAPP\" | \"USER_WORKER\")",
                value: input.upsizeTagCreator
            });
        })()}}`;
        return $so0(input);
    }; return stringify(assert(input)); })(input);
};
export const TagUpsizeRequestValidatorShape = {
    'upsizeTagName': yup.string().matches(/^[a-zA-Z0-9][a-zA-Z0-9 .:;=@_-]+$/).min(3).defined(),
    'upsizeTagCreator': TagCreatorValidator.defined(),
    'runId': yup.string().matches(/^[a-f0-9]{24}$/),
};
export const TagUpsizeRequestValidator = yup.object().shape(TagUpsizeRequestValidatorShape);
export const TagUpsizeRequestStringifyShapeProperties: ObjectSchema['properties'] = {
    'upsizeTagName': {
        'type': 'string',
        'pattern': "/^[a-zA-Z0-9][a-zA-Z0-9 .:;&#x3D;@_-]+$/".slice(1, -1),
        'minLength': 3,
    } as unknown as fastJSON.StringSchema,
    'upsizeTagCreator': TagCreatorStringifyShape,
    'runId': {
        'type': 'string',
        'pattern': "/^[a-f0-9]{24}$/".slice(1, -1),
    } as unknown as fastJSON.StringSchema,
};
export const TagUpsizeRequestStringifyShape: ObjectSchema = {
    title: 'TagUpsizeRequest Stringifier',
    type: 'object',
    properties: {
        ...TagUpsizeRequestStringifyShapeProperties
    },
    required: [
        'upsizeTagName',
        'upsizeTagCreator',
    ],
};
export const TagUpsizeRequestStringifyCompiled = (typeof process === 'object' && String(process) === '[object process]') ?
    fastJSON.build(TagUpsizeRequestStringifyShape, {
        ajv: {
            // since we are already doing this with yup, lets skip
            validateSchema: false,
            ownProperties: true,
            validateFormats: false,
        },
        largeArrayMechanism: 'json-stringify',
        largeArraySize: 1000,
    } as Options)
    :
        () => { throw new Error('fast-json-stringify only works in nodejs'); };
export const TagUpsizeRequestStringify: (data: TagUpsizeRequest) => string = TagUpsizeRequestStringifyCompiled;
/* LIGHTLY CUSTOM END -> Generic */
export function TagUpsizeRequestFromJSON(json: any): TagUpsizeRequest {
    return TagUpsizeRequestFromJSONTyped(json, false);
}
export function TagUpsizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagUpsizeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    /* causes circular depdendencies
*/
    return {
        'upsizeTagName': json['upsizeTagName'],
        'upsizeTagCreator': TagCreatorFromJSON(json['upsizeTagCreator']),
        'runId': !exists(json, 'runId') ? undefined : json['runId'],
    };
}
export function TagUpsizeRequestToJSON(value?: TagUpsizeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'upsizeTagName': value.upsizeTagName,
        'upsizeTagCreator': TagCreatorToJSON(value.upsizeTagCreator),
        'runId': value.runId,
    };
}
